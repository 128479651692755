import { FormGroup, FormControl, ValidationErrors } from '@angular/forms';

export function emailValidator(control: FormControl): {[key: string]: any} {
    var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;    
    if (control.value && !emailRegexp.test(control.value)) {
        return {invalidEmail: true};
    }
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
        let password= group.controls[passwordKey];
        let passwordConfirmation= group.controls[passwordConfirmationKey];
        if (password.value !== passwordConfirmation.value) {
            return passwordConfirmation.setErrors({mismatchedPasswords: true})
        }
    }
}


export function samePasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
        let password= group.controls[passwordKey];
        let passwordConfirmation= group.controls[passwordConfirmationKey];
        if (password.value === passwordConfirmation.value) {
            return passwordConfirmation.setErrors({samePassword: true})
        }
    }
}



export function CarteValidation() {
    return (group: FormGroup) => {
        let paiementMethod= group.controls['paiementMethod'];
        let codesecretcarte= group.controls['codesecretcarte'];

        // this.logger.trace('Paiement, ', paiementMethod.value);

        if (paiementMethod.value != null) {
            this.logger.trace('Paiement, ', paiementMethod.value);
            if (paiementMethod.value.paymentmodename === 'carte abonnement') {
                this.logger.trace('Carte abonnement choisi');
                //codesecretcarte.setErrors({ carteValidation: true });
    
            } else {
                this.logger.trace('Autre moyen de paiement');
                codesecretcarte.setErrors(null);

            }
        }
        

    }

}


export function AbnCarteValidation() {
    return (group: FormGroup) => {
        let modepaiement= group.controls['modepaiement'];
        let codesecretcarte= group.controls['codesecretcarte'];

        // this.logger.trace('Paiement, ', paiementMethod.value);

        if (modepaiement.value != null) {
            //this.logger.trace('Paiement, ', modepaiement.value);
            if (modepaiement.value === 'carteabonnement') {
                //this.logger.trace('Carte abonnement choisi');
                //codesecretcarte.setErrors({ carteValidation: true });
    
            } else {
                //this.logger.trace('Autre moyen de paiement');
                codesecretcarte.setErrors(null);

            }
        }
        

    }

}



    export function startWith(value: string) {
        return (group: FormGroup) => {
            let tel = group.controls[value];
            let telValue = tel.value;
            this.logger.trace('TEL' + telValue);
            this.logger.trace('TEL2' + telValue.startsWith('225'));

            if (!telValue.startsWith('225')) {
                return tel.setErrors({startWith: true})
            }
        }
    }
