<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>

<section class="story-area pt-100 pb-70">
    <div class="story-shape"><img src="assets/img/about/story3.png" alt="Shape"></div>

    <div class="container">
        <div class="story-head">
            <h2>About Our Story</h2>
            <p>A restaurant sometimes known as a diner is a place where cooked food is sold to the public, and where people sit down to eat it. It is also a place where people go to enjoy the time and to eat a meal. Some restaurants are a chain, meaning that there are restaurants which have the same name and serve is also a place where people go to enjoy the time and to eat a meal the same food.</p>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-6">
                <div class="story-item">
                    <img src="assets/img/about/story1.jpg" alt="Story">
                    <h3>Restaurant Service</h3>
                </div>
            </div>

            <div class="col-sm-6 col-lg-6">
                <div class="story-item">
                    <img src="assets/img/about/story2.jpg" alt="Story">
                    <h3>Home Delivery Service</h3>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>What Spiz Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="services-slider owl-theme owl-carousel">
            <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/services1.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Fresh Healthy Food</h3>
                    <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                </a>
            </div>

            <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/services2.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Free Fast Home Delivery</h3>
                    <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                </a>
            </div>

            <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/services3.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Discount Voucher</h3>
                    <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                </a>
            </div>

            <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/services3.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Discount Voucher</h3>
                    <p>Fresh food is food which has not been preserved and has not spoiled yet. Fo vegetables and fruits, this means.</p>
                </a>
            </div>
        </div>
    </div>
</section>

<section class="download-area-two pt-100 pb-70">
    <div class="download-shape">
        <img src="assets/img/home-one/reservation-shape.png" alt="Download">
        <img src="assets/img/about/download2.png" alt="Download">
    </div>

    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="download-item">
                    <h2>Download Our Mobile App That Make You More Easy to Order</h2>
                    <ul>
                        <li><a href="#"><img src="assets/img/home-two/google-store.png" alt="Google"></a></li>
                        <li><a href="#"><img src="assets/img/about/app.png" alt="App"></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="download-img">
                    <img src="assets/img/home-one/subscribe-main.png" alt="Download">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="chef-area chef-area-two pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Special Chefs</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="chef-item">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/1.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>John Doe</h3>
                            <span>Head of Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="chef-item">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/6.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>John Smith</h3>
                            <span>Assistant Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="chef-item active">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/3.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>Evanaa</h3>
                            <span>Intern Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="chef-item">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/7.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>Knot Doe</h3>
                            <span>Asst. Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>