import { Component, Injector } from "@angular/core";
import {
  Validators,
  FormBuilder,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NotifDialogComponent } from "../notif-dialog/notif-dialog.component";
import { ParameterService } from "../parameter.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent {
  form = this.fb.group(
    {
      password: ["", [Validators.required, Validators.minLength(6)]],
      confirmPassword: ["", [Validators.required, Validators.minLength(6)]],
    },
    { validators: this.passwordsMatch() }
  );

  isSubmitting = false;

  constructor(
    private parameterService: ParameterService,
    private modalService: NgbModal,
    private router: Router,
    private fb: FormBuilder
  ) {}

  handleSubmit() {
    this.isSubmitting = true;

    this.parameterService.resetPassword(this.form.value.password).subscribe(
      (data) => {
        switch (data.status) {
          case 201: {
            this.closeModale();
            this.openNotifModal(
              "Votre mot de passe a été réinitialiser avec succès"
            );
            this.router.navigateByUrl("/session");
            break;
          }
          case 204: {
            this.isSubmitting = false;
            this.closeModale();
            this.openNotifModal("Lien d'activation expiré");
            break;
          }
          default: {
            this.isSubmitting = false;
            this.closeModale();
            this.openNotifModal(
              "Une erreur s'est produite, veuillez reessayer"
            );
            break;
          }
        }
      },
      (error) => {
        this.isSubmitting = false;
        this.openNotifModal("Une erreur s'est produite, veuillez reessayer");
      }
    );
  }

  async openNotifModal(message) {
    this.modalService.open(NotifDialogComponent, {
      size: "xs",
      ariaLabelledBy: "Cart-Modal",
      centered: true,
      windowClass: "theme-modal cart-modal CartModal",
      injector: Injector.create([
        {
          provide: String,
          useValue: message,
        },
      ]),
    });
  }

  passwordsMatch(): ValidatorFn {
    return (control: AbstractControl) => {
      return control.get("password")?.value !=
        control.get("confirmPassword")?.value
        ? { noMatch: true }
        : null;
    };
  }

  closeModale() {
    this.modalService.dismissAll();
  }
}
