import { Component, Injector } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from '../../parameter.service';
import { Client, ClientAuth, Formule } from '../../models/models';
import { NotifDialogComponent } from '../../notif-dialog/notif-dialog.component';

@Component({
  selector: 'app-typ-abn',
  templateUrl: './typ-abn.component.html',
  styleUrls: ['./typ-abn.component.scss']
})
export class TypAbnComponent {


  client: Client;

  currentUser: ClientAuth;
  displayFormule: boolean = false;

  categoriecorpos: any[];
  categorieSelected: any;

  formules: Formule[];
  formulesprecompte: Formule[] = [];
  formulesnonprecompte: Formule[] = [];
  clientPrecompte: Formule[] = [];

  constructor(public formBuilder: FormBuilder, private parameterService: ParameterService,
    private modalService: NgbModal, private route: Router, private fb: FormBuilder
  ) {
    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      this.currentUser = JSON.parse(localStorage.getItem('YolinFrontCurentuser'));
    } else {
      this.currentUser = JSON.parse(sessionStorage.getItem('YolinFrontCurentuser'));
    }
    // Verifier un client est en ligne
    if (!this.currentUser) {
      this.route.navigate(['/']);  //Rediriger l'utilisateur à l'accueil
    }
    this.client = this.currentUser.client;
  }

  ngOnInit() {
    this.getCategorieCorpo();
  }



  getCategorieCorpo() {

    this.parameterService.getAllWithKey("categoriecorporate/findcategoriecorpo/" + this.client.clienttype.clienttypeid).subscribe(data => {

      switch (data.status) {
        case 200:
          this.categoriecorpos = data.body;
          console.log(this.categoriecorpos);
          break;
        case 204:
          this.openModal('Aucune categorie corpo disponible');

          break;
        default:

          break;
      }
    },
      error => {


      });
  }

 

  
  getFormuleByCategoriecorpo(categoriecorpoid: string) {
    this.parameterService.getAllWithKey("formule/getlistformule/" + categoriecorpoid).subscribe(data => {

      switch (data.status) {
        case 200:
          console.log('DATA', data);
          this.formules = data.body;
          console.log(this.formules);
          this.formulesprecompte = this.formules.filter(function (person) {
            return person.formuleisprecompte;
          });
          this.formulesnonprecompte = this.formules.filter(function (person) {
            return !person.formuleisprecompte;
          });

          

          // Initialiser l'objet clientPrecompte
          this.clientPrecompte = this.formulesprecompte;

          break;
        case 204:
          this.openModal('Aucune categorie corpo disponible');

          break;
        default:

          break;
      }
    },
      error => {
      });
  }
  

  selectedCategorie(value) {
    this.displayFormule = true;
     this.getFormuleByCategoriecorpo(value.categoriecorpoid);
  }


  async openModal(product) {
    this.modalService.open(NotifDialogComponent,

      {
        size: 'lg',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: product
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  public closeResult: string;



}
