import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Historiq, clientCommandFood } from '../models/models';

@Component({
  selector: 'app-food-show',
  templateUrl: './food-show.component.html',
  styleUrls: ['./food-show.component.scss']
})
export class FoodShowComponent {

  item: Historiq;

  constructor(private food: Historiq, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.item = this.food;
  }

  ngAfterViewInit(): void {
  }


  verifyFalse(value: any) {
    if (value === true) {
      return 'oui';
    } else if (value === false) {
      return 'non';
    }
  }

}
