import { Component, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from '../../parameter.service';
import { Address, AddressDto, Client, ClientAuth } from '../../models/models';
import { NotifDialogComponent } from '../../notif-dialog/notif-dialog.component';

@Component({
  selector: 'app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss']
})
export class AddressDialogComponent {


  form: FormGroup;
  pointlivraisons: any[];
  pointlivraisonquartiers: any[];


  currentUser: ClientAuth;
  client: Client;
  public closeResult: string;

  constructor(public formBuilder: FormBuilder, private parameterService: ParameterService,
    private address: Address,
    private modalService: NgbModal, private route: Router, private fbRecharge: FormBuilder
  ) {

  }


  ngOnInit() {

    let address: any = this.address;

    if (address) {
      this.getPointlivraisonByZone(address.addresszone);
    }

    console.log(address);

    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      this.currentUser = JSON.parse(localStorage.getItem('YolinFrontCurentuser'));

    } else {
      this.currentUser = JSON.parse(sessionStorage.getItem('YolinFrontCurentuser'));

    }
    // Verifier un client est en ligne
    if (!this.currentUser) {
      this.route.navigate(['/']);  //Rediriger l'utilisateur à l'accueil
    }

    this.client = this.currentUser.client;

    this.getPointlivraisons();
    this.buildItemForm(address);
  }

  buildItemForm(item) {

    this.form = this.formBuilder.group({
      addressid: [item.addressid || ''],
      //addresslocation: [item.addresslocation || '',  [Validators.required] ],
      addresszone: [item.addresszone || '', [Validators.required]],
      addressquartier: [item.addressquartier || '', [Validators.required]],
      addressdescriptif1: [item.addressdescriptif1],
      addressdescriptif2: [item.addressdescriptif2],
      //addressisdefault: [item.addressisdefault || '',  [Validators.required] ]
    });
  }

  checkPointlivraisonquartier(value) {
    console.log('Obj recu', value);
    this.getPointlivraisonByZone(this.form.get('addresszone').value);
  }

  getPointlivraisons() {
    this.parameterService.getAllWithKey('parameter/pointlivraison/getall').subscribe(data => {
      switch (data.status) {
        case 200:
          this.pointlivraisons = data.body;
          break;
        case 204:
          this.pointlivraisons = [];
          break;
        default:
          break;
      }
    },
      error => {

      });
  }


  submit() {
    // Verifier si l'id est null (ajout)
    let addressid = this.form.value.addressid;

    let res: AddressDto = this.form.value;
    res.clientid = this.client.clientid;

    if (!addressid || addressid == '') {

      this.parameterService.postWithKey("adress", res).subscribe(data => {
        let statuscode = data.status;

        switch (data.status) {

          case 201: {
            // this.getItems();
            this.modalService.dismissAll(true);
            this.openNotifModal('Adresse ajouté avec succes')
            break;
          }

          default: {
            this.closeModale();
            this.openNotifModal('Une erreur est survenue')
            break;
          }

        }
      },
        error => {

          let statuscode = error.status;
          switch (statuscode) {
            case 401: {
              this.closeModale();
              this.openNotifModal('Client non trouvé')
              break;
            }

            case 406: {
              this.closeModale();
              this.openNotifModal('Nombre max d\'adresse autorisé atteinte')
              break;
            }

            default: {
              this.closeModale();
              this.openNotifModal('Une erreur est survenue!')
              break;
            }
          }
        });

    } else {
      this.parameterService.updateWithKey("adress", res).subscribe(data => {
        let statuscode = data.status;

        switch (data.status) {

          case 201: {
            // this.getItems();
            this.modalService.dismissAll(true);
            this.openNotifModal('Adresse mis à jour avec succes')
            break;
          }

          default: {
            this.closeModale();
            this.openNotifModal('Une erreur est survenue')
            break;
          }

        }
      },
        error => {

          let statuscode = error.status;
          switch (statuscode) {
            case 401: {
              this.closeModale();
              this.openNotifModal('Client non trouvé')
              break;
            }

            case 500: {
              this.closeModale();
              this.openNotifModal('Internal server error!')
              break;
            }

            default: {
              this.closeModale();
              this.openNotifModal('Une erreur est survenue!')
              break;
            }
          }
        });
    }


  }



  async openNotifModal(message) {
    this.modalService.open(NotifDialogComponent,
      {
        size: 'xs',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: message
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  closeModale() {
    this.modalService.dismissAll();
  }

  getPointlivraisonByZone(zone: string) {
    console.log("--------------" + zone);
    this.parameterService.getAllWithKey('parameter/pointlivraisonquartier/getbyzone/' + zone).subscribe(data => {
      switch (data.status) {
        case 200:
          this.pointlivraisonquartiers = data.body;
          console.log(this.pointlivraisonquartiers);
          break;
        case 204:
          this.pointlivraisonquartiers = [];
          console.log(data);

          break;
        default:
          console.log(data);

          break;
      }
    },
      error => {
        console.log(error);

      });
  }
}
