<div class="contact-location-area" style="background-color: white;">
    <br>
    <div class="container">

        <div class="row">
            <div class="col-lg-4">
                <h5> Mes adresses de livraison
                </h5>
            </div>
            <div class="col-lg-4">

            </div>
            <div class="col-lg-4">
                <div class="banner-btn-wrap">
                    <a (click)="openModal()" class="cmn-btn"
                        style="color: white; font-weight: bold; cursor: pointer;">CREATION D'ADRESSE</a>
                </div>
            </div>
        </div>
        <br>
        <div *ngIf="adress.length>0" class="row">
            <div *ngFor="let item of adress" class="col-lg-4">
                <div class="location-item" style="height: 210px;">
                    <i class='bx bxs-home-circle'></i>
                    <ul>
                        <li>{{ item.addresszone }} </li>
                        <li>{{ item.addressquartier }}</li>
                    </ul>
                </div>

                <div class="fonction-check">
                    <ul>


                        <li>
                            <a  style="color: white; font-weight: bold; background-color: #F07409;" *ngIf="item.addressisdefault"><i class='bx bx-pin'></i></a>
                            <a style=" cursor: pointer; " *ngIf="!item.addressisdefault" (click)="openSelectedAdresseModal(item)"><i class='bx bx-pin'></i></a>
                        </li>
                        <li><a (click)="openUpdateModal(item)"><i class='bx bx-edit'></i></a></li>
                        <li><a (click)="openDeleteAdresseModal(item)" target="_blank"><i class='bx bx-trash'></i></a>
                        </li>
                    </ul>
                </div>

            </div>
        </div>

        <div *ngIf="adress.length==0" class="row">
            <div class="col-lg-4">

            </div>
            <div class="col-lg-4">
                <div>
                    <h5>
                        Aucune adresse disponible
                    </h5>
                </div>
            </div>
            <div class="col-lg-4">

            </div>
        </div>

    </div>

</div>