import { Component } from '@angular/core';

@Component({
  selector: 'app-menu-faq',
  templateUrl: './menu-faq.component.html',
  styleUrls: ['./menu-faq.component.scss']
})
export class MenuFaqComponent {

}
