<div style="padding: 30px;">

    <div class="row">
        <div class="row align-items-center" style="margin-top: 50px;">
            <div class="col-lg-6">
                <div class="reservation-item">

                    <b>selection de l'adresse</b>
                    <select class="form-select" [formControl]="form.controls['addresszone']"
                        (change)="checkPointlivraisonquartier($event.target.value)" aria-label="Default select example">
                        <option *ngFor="let pointlivraison of pointlivraisons"
                            [ngValue]="pointlivraison.pointlivraisonzone">
                            {{pointlivraison.pointlivraisonzone}}
                        </option>

                    </select>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="reservation-item">
                    <b>selection du quartier</b>
                    <select class="form-select" [formControl]="form.controls['addressquartier']"
                        aria-label="Default select example">
                        <option *ngFor="let pointlivraison of pointlivraisonquartiers"
                            [ngValue]="pointlivraison.pointlivraisonquartiernom">
                            {{pointlivraison.pointlivraisonquartiernom}}
                        </option>

                    </select>
                </div>
            </div>

        </div>
    </div>


    <div class="row">
        <div class="row align-items-center" style="margin-top: 50px;">
            <div class="col-lg-6">
                <div class="reservation-item">

                    <input type="text" class="form-control" [formControl]="form.controls['addressdescriptif1']"
                        placeholder="Descriptif 1">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="reservation-item">

                    <input type="text" class="form-control" [formControl]="form.controls['addressdescriptif2']"
                        placeholder="Descriptif 2">
                </div>
            </div>

        </div>
    </div>




    <div class="row">
        <div class="row align-items-center" style="margin-top: 50px;">
            <div class="col-lg-3">

                <ng-container *ngIf="form.invalid">
                    <div class="banner-btn-wrap">
                        <a class="cmn-btn"
                            style="color: white; font-weight: bold; background-color: #efefef;">ENREGISTRER</a>
                    </div>
                </ng-container>
                <ng-container *ngIf="!form.invalid">
                    <div class="banner-btn-wrap">
                        <a class="cmn-btn" (click)="submit()"
                            style="color: white; font-weight: bold; cursor: pointer; background-color: #F07409;">ENREGISTRER</a>
                    </div>
                </ng-container>

            </div>
            <div class="col-lg-6">

            </div>
            <div class="col-lg-3">
                <div class="banner-btn-wrap">
                    <a class="cmn-btn" (click)="closeModale()" 
                        style="color: white; font-weight: bold; cursor: pointer; background-color: transparent; color:#F07409 ;">FERMER</a>
                </div>
            </div>

        </div>
    </div>
</div>