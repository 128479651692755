
import { Pipe, PipeTransform } from '@angular/core';
import { Formule } from '../../models/models';

@Pipe({
  name: 'filterFormule'
})
export class FilterFormulePipe implements PipeTransform {
  constructor() { 
    }
  transform(value: string, formule: Formule[]): string {
  
    for (const iterator of formule) {
      if(iterator.formuleid == value){
        return iterator.formulenom;
      }
    }
  }
}