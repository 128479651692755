<section class="services-area-carte">
    <div class="container">

        <div class="row">
            <div class="col-lg-6">
            </div>
            <div class="col-lg-6">

                <div style="text-align: right;">
                    <b>
                        MON SOLDE : <span style="color: #f07409;">{{solde}} &nbsp; F CFA</span>
                        &nbsp;
                        <span>
                            <a style="font-size: 22px; color: black;">

                                <svg style="cursor: pointer;" (click)="soldeCarte(client?.clientid)"
                                    xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                                    <path fill="currentColor"
                                        d="M10 11H7.101l.001-.009a4.956 4.956 0 0 1 .752-1.787a5.054 5.054 0 0 1 2.2-1.811c.302-.128.617-.226.938-.291a5.078 5.078 0 0 1 2.018 0a4.978 4.978 0 0 1 2.525 1.361l1.416-1.412a7.036 7.036 0 0 0-2.224-1.501a6.921 6.921 0 0 0-1.315-.408a7.079 7.079 0 0 0-2.819 0a6.94 6.94 0 0 0-1.316.409a7.04 7.04 0 0 0-3.08 2.534a6.978 6.978 0 0 0-1.054 2.505c-.028.135-.043.273-.063.41H2l4 4l4-4zm4 2h2.899l-.001.008a4.976 4.976 0 0 1-2.103 3.138a4.943 4.943 0 0 1-1.787.752a5.073 5.073 0 0 1-2.017 0a4.956 4.956 0 0 1-1.787-.752a5.072 5.072 0 0 1-.74-.61L7.05 16.95a7.032 7.032 0 0 0 2.225 1.5c.424.18.867.317 1.315.408a7.07 7.07 0 0 0 2.818 0a7.031 7.031 0 0 0 4.395-2.945a6.974 6.974 0 0 0 1.053-2.503c.027-.135.043-.273.063-.41H22l-4-4l-4 4z" />
                                </svg>
                            </a>
                        </span>
                    </b>
                </div>

            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-lg-3">
                <div class="services-item" (click)="selectedItems(1)"
                    [ngStyle]="itemSelected==1?{'background-color': '#F07409'} : {'background-color': 'black'}">
                    <a>
                        <a class="nav-cart"><i class='bx bxs-credit-card'></i></a>
                        <p>GESTION DE CARTE</p>
                    </a>
                </div>
            </div>

            <div class="col-lg-3" (click)="selectedItems(2)">
                <div class="services-item"
                    [ngStyle]="itemSelected==2?{'background-color': '#F07409'} : {'background-color': 'black'}">
                    <a>
                        <a class="nav-cart"><i class='bx bxs-key'></i></a>
                        <p>MODIFICATION DU CODE SECRET</p>
                    </a>
                </div>
            </div>

            <div class="col-lg-3" (click)="selectedItems(3)">
                <div class="services-item"
                    [ngStyle]="itemSelected==3?{'background-color': '#F07409'} : {'background-color': 'black'}">
                    <a>
                        <a class="nav-cart"><i class='bx bxs-key'></i></a>
                        <p>CODE SECRET OUBLIE</p>
                    </a>
                </div>
            </div>

            <div class="col-lg-3" (click)="selectedItems(4)">
                <div class="services-item"
                    [ngStyle]="itemSelected==4?{'background-color': '#F07409'} : {'background-color': 'black'}">
                    <a class="nav-cart"><i class='bx bxs-credit-card'></i></a>
                    <p>RECHARGER</p>

                </div>
            </div>

        </div>


        <ng-container *ngIf="itemSelected==1">
            <app-carte [client]="client" [carteabonnement]="carteabonnement" *ngIf="!isLocked"></app-carte>
            <app-cartelocked [client]="client" *ngIf="isLocked"></app-cartelocked>
        </ng-container>

        <ng-container *ngIf="itemSelected==2  ">
            <app-secretcode [client]="client"></app-secretcode>
        </ng-container>
        <ng-container *ngIf="itemSelected==3">
            <app-code [client]="client"></app-code>
        </ng-container>
        <ng-container *ngIf="itemSelected==4">
            <app-credit  [client]="client"></app-credit>
        </ng-container>
<!--
    && !isLocked
-->

    </div>
</section>