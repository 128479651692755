import { Component } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "app-space-user",
  templateUrl: "./space-user.component.html",
  styleUrls: ["./space-user.component.scss"],
})
export class SpaceUserComponent {
  index: number = 1;
  id: string;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.activatedRoute.queryParams.forEach((params: Params) => {
      if (params["key"]) {
        this.choiceItems(params["key"]);
      }
    });
  }

  public links = [
    // { name: 'Account Dashboard', href: 'dashboard', icon: 'dashboard' },
    { name: "Mon profil", href: "information", icon: "person", index: 1 },
    { name: "Ma carte", href: "Ma carte", icon: "info", index: 2 },
    { name: "Abonnement", href: "abonnement", icon: "info", index: 3 },
    {
      name: "Mes adresses",
      href: "adresses",
      icon: "add_shopping_cart",
      index: 4,
    },
    {
      name: "Mes commandes",
      href: "commandes",
      icon: "add_shopping_cart",
      index: 5,
    },
    {
      name: "Mes notifications",
      href: "notification",
      icon: "notifications",
      index: 6,
    },
    {
      name: "Supprimer mon compte",
      href: "delete-account",
      icon: "account",
      index: 7,
    },
  ];

  choiceItems(value: any) {
    this.index = value;
  }
}
