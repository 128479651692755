<div class="contact-location-area" style="background-color: white;">
    <div class="container">
        <div class="row">



            <div class="col-lg-12">
                <h5> <b style="text-align: left;">
                        VOS INFORMATIONS PERSONNELLES
                    </b>
                </h5>
                <br>
                <hr style="color: black; font-weight: bold;">

                <br>

                <ng-template style="text-align: center;">
                </ng-template>


                <form [formGroup]="infoForm">


                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="text" name="clientfirstname" style=" width: 100%;"
                                        [formControl]="infoForm.controls['clientfirstname']" placeholder="Nom"
                                        class="form-control-insc">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="text" name="clientlastname" style=" width: 100%;"
                                        [formControl]="infoForm.controls['clientlastname']" placeholder="Prenom"
                                        class="form-control-insc">
                                </div>
                            </div>

                        </div>
                        <br>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="email" name="clientemail" style=" width: 100%;"
                                        [formControl]="infoForm.controls['clientemail']" placeholder="Email"
                                        class="form-control-insc">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="text" name="clienttelephone" style=" width: 100%;" maxlength="10"
                                        [formControl]="infoForm.controls['clienttelephone']" placeholder="Telephone"
                                        class="form-control-insc">
                                </div>
                            </div>

                        </div>

                    </div>






                </form>

                <br>
                <div style="padding-left: 10px;">


                    <ng-container *ngIf="infoFormDisabled; else elseTemplate">
                        <button (click)="setIsEnable('infoForm')" class="btn cmn-btn-insc"
                            style="color: white;">MODIFIER
                        </button>

                    </ng-container>
                    <ng-template #elseTemplate>

                        <button (click)="onInfoFormSubmit(infoForm.value)" class="btn cmn-btn-insc"
                            style="color: white;">Enregistrer les modifications
                        </button>

                    </ng-template>

                </div>
            </div>


            <app-updatepasswd></app-updatepasswd>


            <div class="col-lg-12">
                <hr style="color: black; font-weight: bold;">
                <br>
                <h5> <b style="text-align: left;">
                        Newsletter
                    </b>
                </h5>
            </div>

            <div class="col-lg-12">
                <input type="checkbox" [formControl]="newsletterForm.controls['clientnewsletter']"
                    id="flexCheckDefault">

                <b>
                    Je souhaite recevoir par e-mail l'actualité et les offres de mes restaurants fréquentés
                </b>
            </div>


        </div>
    </div>
</div>