import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientAuth } from '../../models/models';
import { ParameterService } from '../../parameter.service';

@Component({
  selector: 'app-notifselected',
  templateUrl: './notifselected.component.html',
  styleUrls: ['./notifselected.component.scss']
})
export class NotifselectedComponent {

  dialogMessage: String;
  public closeResult: string;
  currentUser: ClientAuth;

  constructor(public formBuilder: FormBuilder, private parameterService: ParameterService,
    private message: String,
    private modalService: NgbModal, private route: Router, private fbRecharge: FormBuilder
  ) {

  }


  ngOnInit() {
    this.dialogMessage = this.message;
    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      this.currentUser = JSON.parse(localStorage.getItem('YolinFrontCurentuser'));

    } else {
      this.currentUser = JSON.parse(sessionStorage.getItem('YolinFrontCurentuser'));
    }

  }

  closeModale() {
    this.modalService.dismissAll();
  }

  submit() {
    this.modalService.dismissAll(true);
  }

}
