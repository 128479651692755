<div class="row">
    <div class="col-lg-12">
        <div class="stepper-card"
            [ngStyle]="stepLevel==0?{ 'border': '2px solid #f07409' } : {'border': '2px solid #efefef'}"
            style="width: 100%;">
            <div><span class="label-num">01</span> CATEGORIE
                <div [ngStyle]="stepLevel==0?{ 'display': 'block' } : {'display': 'none'}">


                    <section class="cart-area">
                        <div class="container" style="padding-left: 0px; margin-top: 5px;">
                            <div class="cart-wrap-order">

                                <h5 style="text-align: left; margin-left: 10px;">
                                    Choix de votre categorie
                                </h5>

                                <br>
                                <div class="sorting-menu-categorie" *ngIf="categoriecorpos.length>0">
                                    <ul style="text-align: center">
                                        <li [ngClass]="{'li-selected': categoriecorpo.categoriecorpoid === categorieSelected}"
                                            (click)="selectedCategorie(categoriecorpo)"
                                            *ngFor="let categoriecorpo of categoriecorpos">
                                            {{categoriecorpo.categoriecorpolibelle}}
                                        </li>
                                    </ul>
                                </div>

                                <p style="text-align: center; font-weight: bold;" *ngIf="categoriecorpos.length===0">
                                    Aucune categorie disponible
                                </p>


                                <div (click)="checkCategoriecorpoAndNext()" class="banner-btn-wrap"
                                    style="text-align: right;">
                                    <a class="cmn-btn" style="color: white; font-weight: bold;">SUIVANT</a>
                                </div>

                            </div>
                        </div>
                    </section>


                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-12">
        <div class="stepper-card"
            [ngStyle]="stepLevel==1?{ 'border': '2px solid #f07409' } : {'border': '2px solid #efefef'}"
            style="width: 100%;">
            <div><span class="label-num">02</span> TYPE ABONNEMENT
                <div [ngStyle]="stepLevel==1?{ 'display': 'block' } : {'display': 'none'}">

                    <div class="row">

                        <div class="col-lg-4">

                        </div>

                        <div class="col-lg-4">

                            <input type="radio" (change)="radioTypeAbnChange('precompte')"
                                [formControl]="typeAbnForm.controls['typeAbnForm']" value="precompte">
                            &nbsp;
                            <b style="color: black;">Precompte</b>
                        </div>


                        <div class="col-lg-4">
                            <input type="radio" (change)="radioTypeAbnChange('nonprecompte')"
                                [formControl]="typeAbnForm.controls['typeAbnForm']" value="nonprecompte"> &nbsp;
                            <b style="color: black;">Sans Precompte</b>
                        </div>


                    </div>

                    <br>

                    <div class="row">

                        <div class="col-lg-6">

                        </div>
                        <div class="col-lg-3">
                            <div class="banner-btn-wrap">
                                <a class="cmn-btn" (click)="goToBack()"
                                    style="color: white; font-weight: bold;">RETOUR</a>
                            </div>
                        </div>

                        <div class="col-lg-3">
                            <div class="banner-btn-wrap">
                                <a class="cmn-btn" (click)="checkPrecompteAndNext()"
                                    style="color: white; font-weight: bold;">SUIVANT</a>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>


    <div class="col-lg-12">
        <div class="stepper-card"
            [ngStyle]="stepLevel==2?{ 'border': '2px solid #f07409' } : {'border': '2px solid #efefef'}"
            style="width: 100%;">
            <div><span class="label-num">03</span> CHOIX DES FORMULES
                <div [ngStyle]="stepLevel==2?{ 'display': 'block' } : {'display': 'none'}">

                    <p *ngIf="clientPrecompte.length===0" style="text-align: center; font-weight: bold;">
                        Aucune formule disponible
                    </p>


                    <div class="row">
                        <div class="col-lg-2">

                        </div>
                        <ng-container *ngFor="let formule of clientPrecompte">
                            <div class="col-lg-5">
                                <input type="radio" [value]="formule" [formControl]="offreForm.controls['offreForm']">
                                &nbsp;
                                <b style="color: black;">{{formule.formulenom}}</b>
                            </div>
                        </ng-container>
                    </div>
                    <br>
                    <div class="row">

                        <div class="col-lg-6">

                        </div>
                        <div class="col-lg-3">
                            <div class="banner-btn-wrap">
                                <a class="cmn-btn" (click)="goToBack()"
                                    style="color: white; font-weight: bold;">RETOUR</a>
                            </div>
                        </div>

                        <div class="col-lg-3">
                            <div class="banner-btn-wrap">
                                <a (click)="checkFormuleAndNext()" class="cmn-btn"
                                    style="color: white; font-weight: bold;">SOUCRIRE</a>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>


    <div class="col-lg-12">
        <div class="stepper-card"
            [ngStyle]="stepLevel==3?{ 'border': '2px solid #f07409' } : {'border': '2px solid #efefef'}"
            style="width: 100%;">
            <div><span class="label-num">04</span> PARAMETRAGE FORMULE
                <div [ngStyle]="stepLevel==3?{ 'display': 'block' } : {'display': 'none'}">


                    <br>

                    <div class="formulaire-item" style="padding: 0px;">
                        <div class="checkForm" style="padding: 0px;">

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="date" name="dateactivation"
                                            [formControl]="abnForm.controls['dateactivation']" class="form-control"
                                            required>
                                    </div>
                                </div>


                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="number" name="autremontant"
                                            [formControl]="abnForm.controls['autremontant']" class="form-control"
                                            required placeholder="Montant">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    DATE D'ACTIVATION
                                </div>


                                <div class="col-lg-6">

                                    <ng-container
                                        *ngIf="this.typeAbnForm.get('typeAbnForm').value == 'precompte'; else elseHintTemplate">
                                        MONTANT MINIMUM 75000 FRS
                                    </ng-container>
                                    <ng-template #elseHintTemplate>
                                        MONTANT MINIMUM 35000 FRS
                                    </ng-template>
                                </div>
                            </div>


                        </div>
                    </div>


                    <br>


                    <div class="row">

                        <div class="col-lg-6">

                        </div>
                        <div class="col-lg-3">
                            <div class="banner-btn-wrap">
                                <a class="cmn-btn" (click)="goToBack()"
                                    style="color: white; font-weight: bold;">RETOUR</a>
                            </div>
                        </div>

                        <div class="col-lg-3">
                            <ng-container
                                *ngIf="this.typeAbnForm.get('typeAbnForm').value == 'precompte'; else elseTemplate">
                                <div class="banner-btn-wrap">
                                    <a (click)="checkParametrageAndNext()" class="cmn-btn"
                                        style="color: white; font-weight: bold;">CONFIRMER</a>
                                </div>
                            </ng-container>
                            <ng-template #elseTemplate>
                                <div class="banner-btn-wrap">
                                    <a (click)="checkParametrageAndNext()" class="cmn-btn"
                                        style="color: white; font-weight: bold;">SUIVANT</a>
                                </div>
                            </ng-template>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-12" *ngIf="this.typeAbnForm.get('typeAbnForm').value == 'nonprecompte'">
        <div class="stepper-card"
            [ngStyle]="stepLevel==4?{ 'border': '2px solid #f07409' } : {'border': '2px solid #efefef'}"
            style="width: 100%;">
            <div><span class="label-num">05</span> MOYEN DE PAIEMENT
                <div [ngStyle]="stepLevel==4?{ 'display': 'block' } : {'display': 'none'}">
                    <div style="margin-left: 35px;">
                        <b>
                            Formule:
                            {{this.offreForm.get('offreForm').value.formulenom}} <br>
                            Durée:
                            {{this.offreForm.get('offreForm').value.formuleduree}} jours <br>
                        </b>
                        <p style="font-weight: bold;">Choisir le moyen de paiement</p>
                        <input type="radio" [formControl]="modepaiementForm.controls['modepaiement']"
                            value="mobilemoney">
                        &nbsp;
                        <b style="color: black;">Mobile money</b>
                        &nbsp;
                        <input type="radio" [formControl]="modepaiementForm.controls['modepaiement']"
                            value="carteabonnement">
                        &nbsp;
                        <b style="color: black;">Compte RestauTresor</b>
                        <br>

                        <div class="checkForm">

                            <div class="row" *ngIf="displayPass">


                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <input type="password" name="passcarte"
                                            [formControl]="passcarteForm.controls['passcarte']" class="form-control"
                                            required placeholder="Code carte" maxlength="4">
                                        <div *ngIf="passcarteForm.controls.passcarte.errors?.number">
                                            <b style="color: red;">Chiffres uniquement</b>
                                        </div>
                                        <b *ngIf="!passcarteForm.controls.passcarte.errors?.number">Obligatoire</b>
                                    </div>
                                </div>

                                <div class="col-lg-8">

                                </div>

                            </div>
                            <br>

                            <div class="row">
                                <div class="col-lg-4">
                                    <input type="checkbox" [formControl]="cguForm.controls['cgu']"
                                        id="flexCheckDefault">
                                    &nbsp;
                                    Accepter les
                                    <a style="text-decoration: underline; color:blue; cursor: pointer"
                                        (click)="generationpdf('termesconditions')">CGU</a>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="banner-btn-wrap">
                                        <a class="cmn-btn" (click)="goToBack()"
                                            style="color: white; font-weight: bold; cursor: pointer; background-color: red;">RETOUR</a>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="banner-btn-wrap">
                                        <a class="cmn-btn" (click)="checkMoyenpaiementAndNext()"
                                            style="color: white; font-weight: bold; cursor: pointer;">CONFIRMER</a>
                                    </div>
                                </div>

                                <div class="col-lg-4">

                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>


</div>