import { Component, Injector, Input } from '@angular/core';
import { AuthResponse, Client, ClientCreditDto, Foods, PaymentMonetique } from '../../models/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from '../../parameter.service';
import { CustomValidators } from 'ng2-validation';
import { NotifDialogComponent } from '../../notif-dialog/notif-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-credit',
  templateUrl: './credit.component.html',
  styleUrls: ['./credit.component.scss']
})
export class CreditComponent {


  @Input() client: Client;

  rechargerForm: FormGroup;

  public closeResult: string;

  tokenFormGroup: FormGroup;



  constructor(public formBuilder: FormBuilder, private parameterService: ParameterService,
    private modalService: NgbModal, private route: Router, private fbRecharge: FormBuilder
  ) { }

  ngOnInit() {
    // Form rechargement
    this.rechargerForm = this.fbRecharge.group({
      montantrechargement: ['', [Validators.required, Validators.min(1), CustomValidators.number]],
    });
  }


  rechargerCarte(value) {
    let clientCreditDto: ClientCreditDto = new ClientCreditDto();
    clientCreditDto.solde = value.montantrechargement;
    //carteCreditDto.carteabonnementcodereference = this.client.codecartevirtuelle;
    clientCreditDto.clientreference = this.client.clientid;
    clientCreditDto.historiqueclientprovenance = 'Web-frontOffice';

    console.log(clientCreditDto);

    this.parameterService.postWithKey("carte/recharge", clientCreditDto).subscribe(data => {
      switch (data.status) {
        case 201: {
          this.openModal('Votre demande de rechargement est en cours. Vous serez redirigé vers l\'interface de paiement afin de la finaliser.');

          let response = data.body;
          let referencetransaction = data.body.historiqueclientreftransaction;
          // Redirection sur la gateway
          this.redirectGateway(referencetransaction, clientCreditDto.solde);
          break;
        }
        case 204: {
          this.openModal('Carte n\'existe pas ou la carte est bloqué');

          break;
        }
        default: {

          break;
        }
      }
    },
      error => {


        switch (error.status) {


          case 401: {
            this.openModal('Erreur d\'enregistrement');

            /* this.snackbar.open('Erreur d\'enregistrement', 'OK', {
              duration: 5000
            }); */
            break;
          }


          default: {
            this.openModal('Une erreur est survenue');

            /* this.snackbar.open('Une erreur est survenue ', 'OK', {
              duration: 5000
            }); */
          }
        }
      });
  }



  redirectGateway(refTrans: string, solde: number) {
    // Creation de l'objet de paiement
    let paymonetq: PaymentMonetique = new PaymentMonetique();
    paymonetq.commandreference = refTrans;
    paymonetq.clientreference = this.client.clientid;
    paymonetq.totalachat = solde;
    let plats: Foods[] = [];
    paymonetq.plats = plats;



    // this.accountservice.SendPayMonetique(paymonetq).subscribe(data => {

    this.parameterService.postWithKey("gatewaymonetique/token", paymonetq).subscribe(data => {


      switch (data.status) {

        default: {

          let cl: AuthResponse = new AuthResponse();

          cl = data.body;



          this.tokenFormGroup = this.formBuilder.group({
            'currency': ['xof'],
            'operation_token': [environment.operationToken],
            'order': [paymonetq.commandreference],
            'transaction_amount': [paymonetq.totalachat],
            'jwt': [cl.auth_token],
          });

           this.post(this.tokenFormGroup.value, environment.urlGateway);

          break;

        }
      }
    },
      error => {


        switch (error.status) {
          case 406: {

            this.openModal('Erreur de token gateway');

            /* this.snackbar.open('Erreur de token gateway', '×',
            { panelClass: 'success', verticalPosition: 'top', duration: 5000 }); */
            break;
          }
          default: {

            this.openModal('Une erreur est survenue, reessayer SVP ou contacter l\'administrateur');

            /* this.snackbar.open('Erreur de token gateway', '×',
            { panelClass: 'success', verticalPosition: 'top', duration: 5000 }); */
            break;
          }
        }
      });
  }

  post(obj, url) {
    var mapForm = document.createElement("form");
    mapForm.target = "_self";
    mapForm.method = "POST"; // or "post" if appropriate
    mapForm.action = url;
    Object.keys(obj).forEach(function (param) {
      var mapInput = document.createElement("input");
      mapInput.type = "hidden";
      mapInput.name = param;
      mapInput.setAttribute("value", obj[param]);
      mapForm.appendChild(mapInput);
    });
    document.body.appendChild(mapForm);
    mapForm.submit();
  }

  async openModal(product) {
    this.modalService.open(NotifDialogComponent,

      {
        size: 'lg',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: product
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
