import { Component, Injector } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Router, RouterModule } from "@angular/router";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import {
  Client,
  ClientAuth,
  Foods,
} from "src/app/components/pages/restau-tresor/models/models";
import { NotifDialogComponent } from "src/app/components/pages/restau-tresor/notif-dialog/notif-dialog.component";

@Component({
  selector: "app-navbar",
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: "./navbar.component.html",
})
export class NavbarComponent {
  public closeResult: string;

  constructor(private modalService: NgbModal, private router: Router) {}

  ngOnInit(): void {
    localStorage.clear();
  }

  checkCart() {
    if (this.VerifyConnection()) {
      this.router.navigateByUrl("/panier");
    } else {
      this.openNotifModal(
        "Veuillez vous connecter afin de finaliser votre achat"
      );
    }
  }

  sedeconnecter() {
    // Supprimer les données user et panier
    //Check RememberMe
    if (
      localStorage.getItem("YolinRememberMe") &&
      JSON.parse(localStorage.getItem("YolinRememberMe"))
    ) {
      localStorage.removeItem("YolinFrontCurentuser");
    } else {
      sessionStorage.removeItem("YolinFrontCurentuser");
    }

    sessionStorage.removeItem("allfood");
    this.router.navigate(["/"]);
  }

  goToProfil() {
    if (this.VerifyConnection()) {
      this.router.navigate(["/moncompte"]);
    }
  }

  async openNotifModal(message) {
    this.modalService
      .open(NotifDialogComponent, {
        size: "xs",
        ariaLabelledBy: "Cart-Modal",
        centered: true,
        windowClass: "theme-modal cart-modal CartModal",
        injector: Injector.create([
          {
            provide: String,
            useValue: message,
          },
        ]),
      })
      .result.then(
        (result) => {
          `Result ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  VerifyConnection() {
    let u = new Client();
    //Check RememberMe
    if (
      localStorage.getItem("YolinRememberMe") &&
      JSON.parse(localStorage.getItem("YolinRememberMe"))
    ) {
      u = JSON.parse(localStorage.getItem("YolinFrontCurentuser"));
    } else {
      u = JSON.parse(sessionStorage.getItem("YolinFrontCurentuser"));
    }
    if (u != null) {
      return true;
    } else {
      return false;
    }
  }

  GetUserName() {
    let v = new ClientAuth();
    //Check RememberMe
    if (
      localStorage.getItem("YolinRememberMe") &&
      JSON.parse(localStorage.getItem("YolinRememberMe"))
    ) {
      v = JSON.parse(localStorage.getItem("YolinFrontCurentuser"));
    } else {
      v = JSON.parse(sessionStorage.getItem("YolinFrontCurentuser"));
    }

    let u: Client = new Client();
    u = v.client;

    if (v != null) {
      return u.clientfirstname;
    } else {
      return "";
    }
  }

  GetTotalFoodInShopping() {
    let res: any = sessionStorage.getItem("allfood");
    let allfood: Foods[] = JSON.parse(res);

    let i: number = 0;
    if (allfood != null) {
      for (let f of allfood) {
        i = i + f.foodqte;
      }
    }

    return i;
  }
}
