<div class="about-content">
    <div class="section-title">
        <br>
        <h5 style="font-weight: bold;">Confirmation
        </h5>
        <br>
        <p style="color : black"> Voulez vous definir cette adresse comme adresse par defaut?

        </p>
        <br>

    </div>
</div>

<div class="row align-items-center" style="padding: 5px;">
    <div class="col-lg-3">

        <div class="banner-btn-wrap">
            <a class="cmn-btn" (click)="submit()"
                style="color: white; font-weight: bold; cursor: pointer; background-color: #F07409;">ENREGISTRER</a>
        </div>
    </div>
    <div class="col-lg-6">

    </div>
    <div class="col-lg-3">
        <div class="banner-btn-wrap">
            <a class="cmn-btn" (click)="closeModale()"
                style="color: white; font-weight: bold; cursor: pointer; background-color: transparent; color:#F07409 ;">FERMER</a>
        </div>
    </div>

</div>