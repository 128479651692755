import { Component, Injector, Input, SimpleChanges } from '@angular/core';
import { Client, Carteabonnement, DemandeabnDto, SearchDto } from '../../models/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from '../../parameter.service';
import { NotifDialogComponent } from '../../notif-dialog/notif-dialog.component';

@Component({
  selector: 'app-carte',
  templateUrl: './carte.component.html',
  styleUrls: ['./carte.component.scss']
})
export class CarteComponent {

  @Input() client: Client;
  @Input() carteabonnement: Carteabonnement;
  codesecretForm: FormGroup;
  validotpForm: FormGroup;
  checkRecaptcha: boolean = false;
  public dataQrCode: string = 'Carte non trouvé';

  itemForm: FormGroup = this.formBuilder.group({
    datedebut: ['', [Validators.required]],
    datefin: ['', [Validators.required]]
  });;

  itemSelected: any = 1;
  public closeResult: string;

  items: any;
  public itemsize: number = 0;
  public itemsfront: any[] = [];
  public itemsizefront: number = 0;
  itemsrange: number[] = [];
  indexPage: number = 1;


  page = 1;
  pageSize = 10;

  checkcard: boolean = false;
  statutblocage: boolean = false;

  blocageForm: FormGroup = this.formBuilder.group({
    motif: ['', [Validators.required]],
    clientcodesecret: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]]
  });;

  constructor(public formBuilder: FormBuilder, private parameterService: ParameterService,
    private modalService: NgbModal, private route: Router, private fbRecharge: FormBuilder
  ) {
    this.dataQrCode = 'Carte non trouvé';

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    this.gethistoriqueclientbyclientid();
  }

  ngOnInit() {

    this.codesecretForm = this.formBuilder.group({
      email: { value: this.client.clientemail, disabled: true },
      //numCarte: {value: '' || this.carteabonnement.carteabonnementcodereference, disabled: true},
      recaptchaReactive: ['', Validators.required],
      checkRecaptcha: [this.checkRecaptcha, [Validators.required, Validators.pattern('true')]]
    });
    //Validation code secret
    this.validotpForm = this.formBuilder.group({
      codeotp: ['', [Validators.required, Validators.min(1), Validators.maxLength(6), CustomValidators.number]],
    });

    this.dataQrCode = this.carteabonnement.carteabonnementcodereference;


    /*
    this.blocageForm = this.formBuilder.group({
      motif: ['', [Validators.required]],
      clientcodesecret: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]]
    });
    */
  }


  selectedItems(value) {
    this.itemSelected = value;
    this.checkcard = this.renouvellementDisable();

    if (this.carteabonnement) {
      this.gethistoriqueclientbyclientid();
    }
  }

  afficherFormStatut() {
    this.statutblocage = true; // Afficher formulaire
  }

  renouvellementDisable() {
    if (this.carteabonnement) {
      if (this.carteabonnement.carteabonnementstatut == "EXPIRATION" || this.carteabonnement.carteabonnementstatut == "BLOQUER" || this.carteabonnement.carteabonnementstatut.toLowerCase() == "attente") {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }



  changeTypeCarte() {
    let demandeabn: DemandeabnDto = new DemandeabnDto();
    demandeabn.demandeabnidclient = this.client.clientid;
    demandeabn.demandeabncodesecret = this.blocageForm.get('clientcodesecret').value;
    demandeabn.demandeabnmotif = this.blocageForm.get('motif').value;
    demandeabn.demandeabnetat = 4 //demande de blocage //BLOQUER
    demandeabn.demandeabnetat = this.carteabonnement.carteabonnementstatut != 'BLOQUER' ? 4 : 5; //demande de blocage / deblocage

    this.parameterService.postWithKey("parameter/drh/add", demandeabn).subscribe(data => {

      switch (data.status) {
        case 200: {

          this.statutblocage = false; // Cacher formulaire de blocage/deblocage

          this.openModal('Votre demande a été envoyé. Nous vous notifierons par mail de la prise en compte de la demande');
          this.verifCarte(); // Rafraichir les infos de la carte

          this.blocageForm.reset();


          break;
        }

        default: {
          break;
        }

      }
    },
      error => {

        switch (error.status) {

          case 409: {
            this.openModal('Demande deja soumise');

            /* this.snackbar.open('Client non trouvé ', 'OK', {
              duration: 5000
            }); */
            break;
          }

          case 412: {
            this.openModal('Code secret incorrect');

            /* this.snackbar.open('Code secret de la carte incorrect', '×',
            { panelClass: 'success', verticalPosition: 'top', duration: 8000 }); */
            break;
          }
          case 423: {
            this.openModal('Demande deja envoyé');

            /* this.snackbar.open('Demande deja envoyé / Carte non trouvé', '×',
            { panelClass: 'success', verticalPosition: 'top', duration: 8000 }); */
            break;
          }

          default: {
            this.openModal('Une erreur est survenue');

            /* this.snackbar.open('Une erreur est survenue ', 'OK', {
              duration: 5000
            }); */
          }
        }
      });

  }

  verifCarte() {
    // Chargement des infos de la carte (Si existante)
    //    this.accountservice.findCarteByclientid(this.client.clientid).subscribe(data => {

    this.parameterService.getAllWithKey('carte/checkclientcard/' + this.client.clientid).subscribe(data => {

      switch (data.status) {
        case 200:


          this.carteabonnement = data.body;
          this.dataQrCode = data.body.carteabonnementcodereference;



          /*this.snackbar.open('Chargement terminé ', 'OK', {
            duration: 5000
          });*/


          // this.buildCodeSecretForm();           

          break;
        case 204:

          this.openModal('Aucune carte trouvé');

          /* this.snackbar.open('Aucune carte trouvé ', 'OK', {
            duration: 5000
          }); */
          break;
        default:

          break;
      }
    },
      error => {


      });
  }



  gethistoriqueclientbyclientid() {
    this.parameterService.getAllWithKey("historiqueclient/getbyclientid/" + this.client.clientid).subscribe(data => {
      switch (data.status) {
        case 200: {

          let responseany: any = data.body;

          if (responseany != null) {
            this.items = responseany;



            this.itemsize = this.items.length;
            if (this.itemsize > 10) {
              let remainder: number = this.itemsize % 10;
              let res: number = (this.itemsize - remainder) / 10;

              let i: number = 0;

              if (remainder > 0) {
                i = 1;
              }

              this.itemsizefront = i + res;
              this.createRange();
              this.defineObjectByItems(1);

            } else {
              this.itemsfront = this.items;
            }
          }

          console.log(this.items);

          /* this.snackbar.open('Historique OK', '×',
          { panelClass: 'success', verticalPosition: 'top', duration: 8000 }); */
          break;
        }
        case 204: {
          //this.openNotification('Notification', 'Désolé la carte n\'existe pas');
          /* this.snackbar.open('Désolé la carte n\'existe pas', '×',
          { panelClass: 'success', verticalPosition: 'top', duration: 5000 }); */

          console.log("----------------");
          console.log(this.items);
          console.log("----------------");

          break;
        }
        default: {
          break;
        }

      }
    },
      error => {

        switch (error.status) {

          default: {
            this.openModal('Une erreur est survenue');

            /* this.snackbar.open('Une erreur est survenue ', 'OK', {
              duration: 5000
            }); */
          }
        }
      });
  }

  historiquecartebyclientTribyDate() {
    let searchDto: SearchDto = new SearchDto();
    searchDto.clientid = this.client.clientid;
    searchDto.debut = this.parameterService.convertDateToString(this.itemForm.get('datedebut').value); // Convertir la date en string
    searchDto.fin = this.parameterService.convertDateToString(this.itemForm.get('datefin').value); // COnvertir la date en string

    this.parameterService.postWithKey("historiqueclient/gethistbyclientdate", searchDto).subscribe(data => {

      switch (data.status) {
        case 200: {

          this.items = data.body;

          break;
        }

        case 204: {
          this.items = [];
          this.openModal('Aucun historique pour l\'instant');

          /* this.snackbar.open('Désolé la carte n\'existe pas', '×',
          { panelClass: 'success', verticalPosition: 'top', duration: 5000 }); */
          break;
        }

        default: {
          break;
        }

      }
    },
      error => {

        switch (error.status) {

          case 409: {
            this.openModal('Demande deja soumise');

            /* this.snackbar.open('Client non trouvé ', 'OK', {
              duration: 5000
            }); */
            break;
          }

          case 412: {
            this.openModal('Code secret incorrect');

            /* this.snackbar.open('Code secret de la carte incorrect', '×',
            { panelClass: 'success', verticalPosition: 'top', duration: 8000 }); */
            break;
          }
          case 423: {
            this.openModal('Demande deja envoyé');

            /* this.snackbar.open('Demande deja envoyé / Carte non trouvé', '×',
            { panelClass: 'success', verticalPosition: 'top', duration: 8000 }); */
            break;
          }

          default: {
            this.openModal('Une erreur est survenue');

            /* this.snackbar.open('Une erreur est survenue ', 'OK', {
              duration: 5000
            }); */
          }
        }
      });

  }

  createRange() {
    this.itemsrange = [];
    for (var i = this.indexPage; i <= this.indexPage + 4; i++) {
      if (i <= this.itemsizefront) {
        this.itemsrange.push(i);
      }
    }
  }


  defineObjectByItems(value: number) {
    let fin: number = value * 10;
    let debut: number = (value * 10) - 9;

    this.itemsfront = [];
    let itemscsv: any[] = [];

    let i: number = 0;
    this.items.forEach(function (element) {
      i++;

      if ((i >= debut) && (i <= fin)) {
        itemscsv.push(element);
      }
    })

    this.itemsfront = itemscsv;



  }


  async openModal(product) {
    this.modalService.open(NotifDialogComponent,

      {
        size: 'lg',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: product
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
