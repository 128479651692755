import { Component } from '@angular/core';

@Component({
  selector: 'app-menu-propos',
  templateUrl: './menu-propos.component.html',
  styleUrls: ['./menu-propos.component.scss']
})
export class MenuProposComponent {

}
