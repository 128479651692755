<div class="faq-container">
  <app-navbar-style-one></app-navbar-style-one>

  <section class="page-title-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-3"></div>

        <div class="col-lg-6">
          <div class="page-title-item" style="text-align: center">
            <h2>FAQS</h2>
          </div>
        </div>

        <div class="col-lg-3"></div>
      </div>
    </div>
  </section>

  <section class="container faq-section-1-container">
    <h3 class="text-uppercase">
      Recherchez votre question par type d'émission
    </h3>
    <div class="faq-section-1-content-container">
      <div class="faq-section-1-item">
        <div class="faq-section-1-icon">Icon</div>
        <div class="faq-section-1-label">FAQ</div>
      </div>
      <div class="faq-section-1-item">
        <div class="faq-section-1-icon">Icon</div>
        <div class="faq-section-1-label">FAQ</div>
      </div>
      <div class="faq-section-1-item">
        <div class="faq-section-1-icon">Icon</div>
        <div class="faq-section-1-label">FAQ</div>
      </div>
      <div class="faq-section-1-item">
        <div class="faq-section-1-icon">Icon</div>
        <div class="faq-section-1-label">FAQ</div>
      </div>
      <div class="faq-section-1-item">
        <div class="faq-section-1-icon">Icon</div>
        <div class="faq-section-1-label">FAQ</div>
      </div>
      <div class="faq-section-1-item">
        <div class="faq-section-1-icon">Icon</div>
        <div class="faq-section-1-label">FAQ</div>
      </div>
    </div>
  </section>

  <section class="faq-section-2-container">
    <div class="container">
      <div class="row faq-section-2-wrap">
        <div class="col-lg-12">
          <div class="faq-head">
            <h3 class="text-uppercase">Questions populaires</h3>
          </div>

          <div>
            <ul class="accordion">
              <li>
                <a>How we serve food?</a>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi
                  quis modi ullam amet debitis libero veritatis enim repellat
                  optio natus eum delectus deserunt, odit expedita eos molestiae
                  ipsa totam quidem? Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Vel, quos mollitia voluptatum aliquam
                  repellendus similique iure fuga aspernatur amet odit! At vitae
                  dicta excepturi quasi? Veritatis, pariatur excepturi! Illum,
                  ut?
                </p>
              </li>
              <li>
                <a>How is our food quality?</a>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi
                  quis modi ullam amet debitis libero veritatis enim repellat
                  optio natus eum delectus deserunt, odit expedita eos molestiae
                  ipsa totam quidem? Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Vel, quos mollitia voluptatum aliquam
                  repellendus similique iure fuga aspernatur amet odit! At vitae
                  dicta excepturi quasi? Veritatis, pariatur excepturi! Illum,
                  ut?
                </p>
              </li>
              <li>
                <a>How do we give home delivery?</a>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi
                  quis modi ullam amet debitis libero veritatis enim repellat
                  optio natus eum delectus deserunt, odit expedita eos molestiae
                  ipsa totam quidem? Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Vel, quos mollitia voluptatum aliquam
                  repellendus similique iure fuga aspernatur amet odit! At vitae
                  dicta excepturi quasi? Veritatis, pariatur excepturi! Illum,
                  ut?
                </p>
              </li>
              <li>
                <a>How can we get in touch with you?</a>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi
                  quis modi ullam amet debitis libero veritatis enim repellat
                  optio natus eum delectus deserunt, odit expedita eos molestiae
                  ipsa totam quidem? Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Vel, quos mollitia voluptatum aliquam
                  repellendus similique iure fuga aspernatur amet odit! At vitae
                  dicta excepturi quasi? Veritatis, pariatur excepturi! Illum,
                  ut?
                </p>
              </li>
              <li>
                <a>Is this restaurant 24 hours open?</a>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi
                  quis modi ullam amet debitis libero veritatis enim repellat
                  optio natus eum delectus deserunt, odit expedita eos molestiae
                  ipsa totam quidem? Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Vel, quos mollitia voluptatum aliquam
                  repellendus similique iure fuga aspernatur amet odit! At vitae
                  dicta excepturi quasi? Veritatis, pariatur excepturi! Illum,
                  ut?
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer-style-two></app-footer-style-two>
</div>
