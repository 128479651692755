import { Component, Injector } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { Address, Client, Clientlogin, Recaptchav2Dto } from "../models/models";
import { NotifDialogComponent } from "../notif-dialog/notif-dialog.component";
import { ParameterService } from "../parameter.service";
import { Router } from "@angular/router";
import { emailValidator, matchingPasswords } from "../models/app-validators";
import { UUID } from "angular2-uuid";
import { ForgotPasswordComponent } from "../forgot-password/forgot-password.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-session",
  templateUrl: "./session.component.html",
  styleUrls: ["./session.component.scss"],
})
export class SessionComponent {
  code: number = 0;
  loginForm: FormGroup;
  registerForm: FormGroup;

  public captchaKey = environment.captchaKey;

  checkRecaptcha: boolean = false;

  public apiCaptcha;
  // public apiCaptcha = this.configService.get('apiCaptcha');

  public savedEmail: string;

  //regex = "/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-+!*$@%_])([-+!*$@%_\w]{8,15})$;
  regex = "((?=.*\\d)(?=.*[A-Z]).{6,20})";

  recaptchaSub: Subscription;

  responseStatus: boolean = false;
  responseValue: string = "";

  public closeResult: string;

  responseCreateStatus: boolean = false;
  responseCreateValue: string = "";

  typepassword: string = "password";
  sessionId: string;

  constructor(
    public formBuilder: FormBuilder,
    private parameterService: ParameterService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit() {
    // document.body.classList.add('bg-auth');

    // this.getallCategories("cdc35b80-b4e7-4a67-a6ad-d30481f9cfde");

    // Recuperer l'email du precedent user online
    this.savedEmail = localStorage.getItem("YolinFrontUserEmail");

    //this.generateUUID();

    this.loginForm = this.formBuilder.group({
      // 'username': [this.savedEmail || '', Validators.compose([Validators.required])],
      username: ["", Validators.compose([Validators.required])],
      password: [
        "",
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
      rememberMe: [false, []],
    });

    this.registerForm = this.formBuilder.group(
      {
        clientnewsletter: [true, Validators.required],
        clientfirstname: ["", Validators.compose([Validators.required])],
        condition: [false, [Validators.required, Validators.pattern("true")]],
        clienttelephone: [
          "",
          Validators.compose([
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
          ]),
        ],
        clientlastname: ["", Validators.compose([Validators.required])],
        clientemail: [
          "",
          Validators.compose([Validators.required, emailValidator]),
        ],
        clientpassword: [
          "",
          [
            Validators.required,
            Validators.minLength(6),
            Validators.pattern(this.regex),
          ],
        ],
        confirmPassword: ["", Validators.required],
        clientcivilite: ["", Validators.required],
        //'captcha': ['', Validators.required],
        recaptchaReactive: ["", Validators.required],
        checkRecaptcha: [
          true,
          [Validators.required, Validators.pattern("true")],
        ],
      },
      { validator: matchingPasswords("clientpassword", "confirmPassword") }
    );
  }

  resolved(captchaResponse: string) {
    console.log(captchaResponse);

    //this.registerForm.setErrors({ 'invalid': true }); // Empecher de passer l'etape de captcha
    // Prepare obj to send
    let recaptchav2Dto: Recaptchav2Dto = new Recaptchav2Dto();
    recaptchav2Dto.token = captchaResponse;
    // Send token to server
    //  this.homeService.validRecaptchav2(recaptchav2Dto)

    this.parameterService.postCapctha("v2", recaptchav2Dto).subscribe(
      (data) => {
        console.log(data);

        switch (data.status) {
          case 200: {
            if (data.body.success) {
              this.checkRecaptcha = true;
              // Valid form
              this.registerForm.patchValue({
                checkRecaptcha: true,
              });
            }

            break;
          }

          case 204: {
            this.checkRecaptcha = false;
            // Invalid form
            this.registerForm.patchValue({
              checkRecaptcha: false,
            });
            grecaptcha.reset(); // Rafraichir le captcha
            this.openNotifModal("Validation Recaptcha echoué");

            break;
          }

          default: {
            break;
          }
        }
      },
      (error) => {
        // Invalid form
        this.registerForm.patchValue({
          checkRecaptcha: false,
        });
        grecaptcha.reset(); // Rafraichir le captcha
        this.openModal("Erreur recaptcha, reessayer SVP");
      }
    );
  }

  async openNotifModal(message) {
    this.modalService
      .open(NotifDialogComponent, {
        size: "xs",
        ariaLabelledBy: "Cart-Modal",
        centered: true,
        windowClass: "theme-modal cart-modal CartModal",
        injector: Injector.create([
          {
            provide: String,
            useValue: message,
          },
        ]),
      })
      .result.then(
        (result) => {
          `Result ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  generationpdf(type: string) {
    this.parameterService.downloadpdf(type).subscribe(
      (data) => {
        switch (data.status) {
          case 200: {
            this.downloadFile(data.body);

            break;
          }

          default: {
            break;
          }
        }
      },
      (error) => {
        switch (error.status) {
          case 404: {
            this.openModal(
              "Cela n'empêche pas de continuer le processus et créer son compte"
            );

            break;
          }
          default: {
            this.openModal(
              "Cela n'empêche pas de continuer le processus et créer son compte"
            );

            break;
          }
        }
      }
    );
  }

  changePassword() {
    if (this.typepassword === "password") {
      this.typepassword = "text";
    } else if (this.typepassword === "text") {
      this.typepassword = "password";
    }
  }

  downloadFile(data) {
    const blob = new Blob([data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  async openModal(product) {
    this.modalService
      .open(
        NotifDialogComponent,

        {
          size: "lg",
          ariaLabelledBy: "Cart-Modal",
          centered: true,
          windowClass: "theme-modal cart-modal CartModal",
          injector: Injector.create([
            {
              provide: String,
              useValue: product,
            },
          ]),
        }
      )
      .result.then(
        (result) => {
          `Result ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  onLoginFormSubmit() {
    console.log(this.registerForm);
    //commentForm.get('comment').value

    console.log(this.loginForm);
    console.log(this.loginForm.value);
    console.log(this.loginForm.valid);

    if (this.loginForm.valid) {
      this.responseStatus = false;
      this.responseValue = "";

      //   this.loader.open('Chargement en cours...');

      let value: Clientlogin = this.loginForm.value;
      // this.logger.trace(value);

      console.log(value);

      var n = value.username.includes("@");

      if (!n) {
        value.username = "225" + this.loginForm.get("username").value;
      }

      // this.loginForm.disabled;

      this.parameterService.LoginClient(value).subscribe(
        (data) => {
          let responseany: any = data;

          switch (data.status) {
            case 200: {
              let response: any = data;

              let client: Client = new Client();

              client = response.body;

              // this.logger.trace(" login response body ");

              //this.logger.trace(response.body);

              // Enregistrer en fonction de la variable 'rememberMe'
              if (value.rememberMe) {
                localStorage.setItem(
                  "YolinFrontCurentuser",
                  JSON.stringify(client)
                );
              } else {
                sessionStorage.setItem(
                  "YolinFrontCurentuser",
                  JSON.stringify(client)
                );
              }

              // Sauvegarder rememberMe
              localStorage.setItem(
                "YolinRememberMe",
                JSON.stringify(value.rememberMe)
              );

              // Sauvegarder email de l'utilisateur online
              localStorage.setItem(
                "YolinFrontUserEmail",
                data.body.client.clientemail
              );

              this.openNotifModal("Vous etes connecté");

              this.router.navigateByUrl("/");

              // this.snackbar.open("Vous etes connecté                          ", 'x', {
              //   duration: 20000,
              //   panelClass: ['success'],
              // });

              // this.snackbar.open('Vous etes connecté', '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });

              //   this.dialogRef.close(this.loginForm.value);
              //  this.dialogRef.close(this.registerForm.value);

              // this.loader.close();

              //  this.loader.close();

              break;
            }
            case 204: {
              this.responseStatus = true;
              this.responseValue = "Le login est incorrect";

              //this.loader.close();
            }
          }
        },
        (error) => {
          /*
            this.logger.trace("error");
            this.logger.trace(error);
            */

          switch (error.status) {
            case 401: {
              this.responseStatus = true;
              this.responseValue = "Le mot de passe est incorrect";

              // this.loader.close();

              break;
            }

            default: {
              this.responseStatus = true;
              this.responseValue = "Une erreur est survenue";

              // this.loader.close();

              break;
            }
          }
        }
      );
    } else {
      console.log("erreur");

      this.responseStatus = true;
      this.responseValue = "Veuillez renseigner votre email et mot de passe";
    }
  }

  generateUUID() {
    this.sessionId = UUID.UUID();
    return this.sessionId;
  }

  onSubscriptionFormSubmit() {
    this.responseCreateValue = "";
    this.responseCreateStatus = false;

    if (this.registerForm.valid && this.registerForm.get("condition").value) {
      let value: Client = this.registerForm.value;
      //value.clienttelephone = '225' + value.clienttelephone;
      value.clienttelephone =
        "225" + this.registerForm.get("clienttelephone").value;
      value.clientTypeDto = "default";
      value.clientprovenance = "Web-frontOffice";
      //value.captcha = value.captcha;
      value.sessionid = this.sessionId;

      // create objet to connect client after created
      let user: Clientlogin = new Clientlogin();
      user.username = value.clienttelephone;
      user.password = value.clientpassword;

      // this.bannerservice.AddClient(value).subscribe(data => {
      this.parameterService.postWithoutKey("parameter/client", value).subscribe(
        (data) => {
          let dataneutre: any = data.body;

          switch (data.status) {
            case 201: {
              this.openNotifModal(
                "Félicitation, votre compte à été créé avec succès! Un email de confirmation et un email  avec les paramètres de votre carte vous ont été transmis. Smile, c'est l'heure de bien manger !"
              );
              //this.snackbar.open('Le compte est crée avec succès', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });

              this.parameterService
                .postWithoutKey("yolin/session/login/client", user)
                .subscribe((result) => {
                  switch (result.status) {
                    case 200: {
                      let clientOnline: Client = new Client();
                      clientOnline = result.body;

                      //Check RememberMe
                      if (
                        localStorage.getItem("YolinRememberMe") &&
                        JSON.parse(localStorage.getItem("YolinRememberMe"))
                      ) {
                        localStorage.setItem(
                          "YolinFrontCurentuser",
                          JSON.stringify(clientOnline)
                        );
                      } else {
                        sessionStorage.setItem(
                          "YolinFrontCurentuser",
                          JSON.stringify(clientOnline)
                        );
                      }
                      this.router.navigateByUrl("/");
                    }
                  }
                });

              break;
            }
          }
        },
        (error) => {
          switch (error.status) {
            /*case 400: {
              this.openNotification('Notification', 'Echec !! Verification Captcha échoué');

              // Reload recaptcha
              //this.reloadRecaptcha();
              this.regresponseValue = 'Echec !! Verification Captcha échoué';
              this.regresponseStatus = true;

              this.loader.close();


              break;

            }*/
            case 401: {
              this.openNotifModal(
                "Le mot de passe doit comprendre minimun 6 caractères, au moins 1 Majuscule et au moins 1 chiffre"
              );
              break;
            }

            case 409: {
              //this.getallFood();
              /*
              this.snackbar.open('Ce utilisateur existe deja ', 'OK', {
                duration: 10000
              });
              */
              this.responseCreateValue =
                "Choisissez une autre adresse e-mail ou réinitialisez votre mot de passe si vous vous êtes déjà inscrit(e)";
              this.responseCreateStatus = true;
              /*
               this.dialogRef.close(this.loginForm.value);
               this.dialogRef.close(this.registerForm.value);
               */

              break;
            }

            default: {
              /*this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });*/

              this.openNotifModal("Echec !! Une erreur est survenue");

              /* this.snackbar.open('Echec !! Une erreur est survenue', '×', 
              { panelClass: 'success', verticalPosition: 'top', duration: 4000 }); */

              break;
            }
          }
        }
      );
    } else {
      this.openNotifModal("Vous devez accepter les conditions d'utilisations");

      //this.snackbar.open('Vous devez accepter les conditions d\'utilisations', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
    }

    /*
    this.dialogRef.close(this.loginForm.value);
    this.dialogRef.close(this.registerForm.value);
    */
  }

  goToCheck(i: any) {
    this.code = i;
  }

  // Add by Christian
  async openForgotPasswordModal() {
    this.modalService
      .open(ForgotPasswordComponent, {
        size: "lg",
        ariaLabelledBy: "Cart-Modal",
        centered: true,
        windowClass: "theme-modal cart-modal CartModal",
        injector: Injector.create([
          {
            provide: Address,
            useValue: {},
          },
        ]),
      })
      .result.then(
        (result) => {
          `Result ${result}`;
        },
        (reason) => {
          this.closeResult = `${this.getDismissReason(reason)}`;
          if (this.closeResult) {
            // this.getAdressByClientId(this.client.clientid);
          }
        }
      );
  }
}
