<div class="row">

    <div class="col-lg-3">

    </div>


    <div class="col-lg-2">
        <div class="services-item" (click)="selectedItems(1)"
            [ngStyle]="itemSelected==1?{'background-color': '#F07409'} : {'background-color': 'black'}">
            <a>
                <a class="nav-cart"><i class='bx bxs-add-to-queue'></i></a>
                <p>DEMANDER UNE CARTE</p>
            </a>
        </div>
    </div>

    <div class="col-lg-2">
        <div class="services-item">
            <a>
                <a class="nav-cart"><i class='bx bxs-info-circle'></i></a>
                <p>INFOS CARTE</p>
            </a>
        </div>
    </div>

    <div class="col-lg-2">
        <div class="services-item">
            <a>
                <a class="nav-cart"><i class='bx bxs-chevron-down-circle'></i></a>
                <p>RENOUVELER CARTE</p>
            </a>
        </div>
    </div>

    <div class="col-lg-3">

    </div>


</div>


<div class="row">

    <div class="col-lg-12">
        <h4 style="text-align: center; margin: 0 auto;">ATTRIBUTION DE CARTE</h4>
    </div>

    <div class="col-lg-12">
        <div *ngIf="checkTypecarteDto?.typecard == 'classic'" class="stepper-card"
            [ngStyle]="stepLevel==0?{ 'border': '2px solid #f07409' } : {'border': '2px solid #efefef'}"
            style="width: 100%;">
            <div><span class="label-num">01</span> TYPE DE CARTE
                <div [ngStyle]="stepLevel==0?{ 'display': 'block' } : {'display': 'none'}">
                    <br>
                    <br>
                    <div style="padding-left: 30px;">
                        <p>Choisir le type de carte</p>

                        <span style="font-weight: bold">Vous êtes éligible à la carte Classique</span>
                        <div>
                            - <a style="text-decoration: underline; color:blue; cursor: pointer"> conditions
                                d'attribution</a>
                        </div>
                    </div>

                    <div style="padding-left: 30px; padding-top: 10px;">
                        <div class="row">
                            <div class="col-lg-2">
                                <div class="banner-btn-wrap">
                                    <a class="cmn-btn" (click)="checkTypecarteAndNext('classic')"
                                        style="color: white; font-weight: bold; cursor: pointer;">CHOISIR</a>
                                </div>
                            </div>

                            <div class="col-lg-7">
                            </div>

                            <div class="col-lg-3">
                                <div class="banner-btn-wrap">
                                    <a class="cmn-btn" (click)="checkTypecarteAndNext('classic_migrate_gold')"
                                        style="color: white; font-weight: bold;  cursor: pointer;">CARTE
                                        GOLD</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="checkTypecarteDto?.typecard == 'all card'"
            [ngStyle]="stepLevel==0?{ 'border': '2px solid #f07409' } : {'border': '2px solid #efefef'}"
            class="stepper-card" style="width: 100%;">
            <div><span class="label-num">01</span> TYPE DE CARTE
                <div [ngStyle]="stepLevel==0?{ 'display': 'block' } : {'display': 'none'}">
                    <br>
                    <br>
                    <div style="padding-left: 30px;">
                        <p>Choisir le type de carte</p>

                        <span style="font-weight: bold">Vous êtes éligible à la carte gold et à la carte
                            classique</span>

                    </div>

                    <div style="padding-left: 30px; padding-top: 25px;">
                        <div class="row">
                            <div class="col-lg-5">

                                <p>
                                    Carte Classique
                                </p>
                                <div>
                                    - <a style="text-decoration: underline; color:blue; cursor: pointer"> conditions
                                        d'attribution</a>
                                </div>
                                <div class="banner-btn-wrap">
                                    <a class="cmn-btn" (click)="checkTypecarteAndNext('classic')"
                                        style="color: white; font-weight: bold; cursor: pointer;">CHOISIR</a>
                                </div>
                            </div>

                            <div class="col-lg-2">
                            </div>

                            <div class="col-lg-5">
                                <p>
                                    Carte gold
                                </p>
                                <div>
                                    - <a style="text-decoration: underline; color:blue; cursor: pointer"> conditions
                                        d'attribution</a>
                                </div>
                                <div class="banner-btn-wrap">
                                    <a class="cmn-btn" (click)="checkTypecarteAndNext('gold')"
                                        style="color: white; font-weight: bold; cursor: pointer;">CHOISIR</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>

    <div class="col-lg-12">

        <div class="stepper-card"
            [ngStyle]="stepLevel==1?{ 'border': '2px solid #f07409' } : {'border': '2px solid #efefef'}"
            style="width: 100%;">

            <div><span class="label-num">02</span> CHOIX DES MOYENS DE PAIEMENT
                <div [ngStyle]="stepLevel==1?{ 'display': 'block' } : {'display': 'none'}">

                    <div *ngIf="checkTypecarteDto?.typecard == 'classic' || checkTypecarteDto?.typecard == 'all card'">
                        <br>
                        <br>
                        <div style="padding-left: 30px;">
                            <p style="text-decoration: underline;">Conditions d'achat:</p>
                            <span [ngSwitch]="typeCarteSelected">
                                <p *ngSwitchCase="'classic'">
                                    * Mobile money : {{checkTypecarteDto?.rechargement_classic}} FCFA de rechargement
                                    <br>
                                    * Compte RestauTresor: {{checkTypecarteDto?.frais_classic}} FCFA si solde compte
                                    restautresor est supérieur à {{checkTypecarteDto.soldemin_classic}} FCFA <br>
                                </p>

                                <p *ngSwitchCase="'gold'">
                                    * Mobile money : {{checkTypecarteDto?.rechargement_gold}} FCFA de rechargement <br>
                                    * Compte RestauTresor: {{checkTypecarteDto?.frais_gold}} FCFA si solde supérieur ou
                                    égale à {{checkTypecarteDto.soldemin_gold}} FCFA <br>
                                </p>
                                <p *ngSwitchCase="'classic_migrate_gold'">
                                    * Mobile money : Montant minimum de rechargement de
                                    {{checkTypecarteDto?.rechargement_gold_classic}} FCFA <br>
                                    * Compte RestauTresor: {{checkTypecarteDto?.rechargement_gold_classic}} FCFA si
                                    solde supérieur ou égale à {{checkTypecarteDto?.rechargement_gold_classic}} FCFA
                                    <br>
                                </p>
                                <p *ngSwitchDefault>

                                </p>
                            </span>
                            <div></div>
                            <p style="text-decoration: underline;">Choisir un moyen de paiement:</p>

                            <input type="radio" [formControl]="payAttrCarteForm.controls['moyenPaiement']"
                                value="mobilemoney">
                            &nbsp;
                            <b style="color: black;">Mobile money</b>
                            &nbsp;
                            <input type="radio" [formControl]="payAttrCarteForm.controls['moyenPaiement']"
                                value="carteabonnement">
                            &nbsp;
                            <b style="color: black;">Compte RestauTresor</b>

                            <br>

                            <div class="checkForm">

                                <div class="row" *ngIf="displayPassAttribution">


                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <input type="password" name="codesecret"
                                                [formControl]="payAttrCarteForm.controls['codesecret']"
                                                class="form-control" required placeholder="Code carte" maxlength="4">
                                            <div *ngIf="payAttrCarteForm.controls.codesecret.errors?.number">
                                                <b style="color: red;">Chiffres uniquement</b>
                                            </div>

                                            <b
                                                *ngIf="!payAttrCarteForm.controls.codesecret.errors?.number">Obligatoire</b>


                                        </div>
                                    </div>

                                    <div class="col-lg-8">

                                    </div>

                                </div>
                                <br>

                                <div class="row">
                                    <div class="col-lg-4">
                                        <input type="checkbox" [formControl]="payAttrCarteForm.controls['cgu']"
                                            id="flexCheckDefault">
                                        &nbsp;
                                        Accepter les
                                        <a style="text-decoration: underline; color:blue; cursor: pointer"
                                            (click)="generationpdf('termesconditions')">CGU</a>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="banner-btn-wrap">
                                            <a class="cmn-btn" (click)="checkCguAndValid()"
                                                style="color: white; font-weight: bold; cursor: pointer;">CONFIRMER</a>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="banner-btn-wrap">
                                            <a class="cmn-btn" (click)="goToBack()"
                                                style="color: white; font-weight: bold; cursor: pointer; background-color: red;">RETOUR</a>
                                        </div>
                                    </div>


                                    <div class="col-lg-4">

                                    </div>

                                </div>
                            </div>


                        </div>


                    </div>

                </div>
            </div>

        </div>
    </div>


</div>