import { Component, Injector } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Address, AddressDefaultDto, ClientAuth } from '../../models/models';
import { NotifDialogComponent } from '../../notif-dialog/notif-dialog.component';
import { ParameterService } from '../../parameter.service';

@Component({
  selector: 'app-address-selected-dialog',
  templateUrl: './address-selected-dialog.component.html',
  styleUrls: ['./address-selected-dialog.component.scss']
})
export class AddressSelectedDialogComponent {

  addressSelected: any;
  public closeResult: string;
  currentUser: ClientAuth;

  constructor(public formBuilder: FormBuilder, private parameterService: ParameterService,
    private address: Address,
    private modalService: NgbModal, private route: Router, private fbRecharge: FormBuilder
  ) {

  }


  ngOnInit() {
    this.addressSelected = this.address;

    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      this.currentUser = JSON.parse(localStorage.getItem('YolinFrontCurentuser'));

    } else {
      this.currentUser = JSON.parse(sessionStorage.getItem('YolinFrontCurentuser'));
    }

  }

  closeModale() {
    this.modalService.dismissAll();
  }

  submit() {

    let addressDefaultDto: AddressDefaultDto = new AddressDefaultDto();
    addressDefaultDto.clientid = this.currentUser.client.clientid;
    addressDefaultDto.addressid = this.addressSelected.addressid;


    this.parameterService.postWithKey("adress/setdefault", addressDefaultDto).subscribe(data => {
      let statuscode = data.status;

      switch (data.status) {

        case 201: {
          // this.getItems();
          this.modalService.dismissAll(true);
          this.openNotifModal('Mise à jour reussi');

          break;
        }

        default: {
          this.closeModale();
          this.openNotifModal('Une erreur est survenue')
          break;
        }

      }
    },
      error => {

        let statuscode = error.status;
        switch (error.status) {
          case 401:
            this.openNotifModal('Adresse introuvable en base');

            break;
          case 409:
            this.openNotifModal('Impossible de supprimer l\'adresse par defaut');

            break;

          default:
            this.openNotifModal('Echec !! Une erreur est survenue');
            console.log(error);
            break;
        }
      });
  }


  async openNotifModal(message) {
    this.modalService.open(NotifDialogComponent,
      {
        size: 'xs',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: message
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
