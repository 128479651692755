import { Component, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientAuth, Client, AbonnementCreditDto, AuthResponse, Foods, PaymentMonetique } from '../../models/models';
import { ParameterService } from '../../parameter.service';
import { CustomValidators } from 'ng2-validation';
import { NotifDialogComponent } from '../../notif-dialog/notif-dialog.component';
import { NotifselectedComponent } from '../../card-relaod/notifselected/notifselected.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-load-abn',
  templateUrl: './load-abn.component.html',
  styleUrls: ['./load-abn.component.scss']
})
export class LoadAbnComponent {

  rechargerForm: FormGroup;
  passcarteRechargerForm: FormGroup;
  displayPassRechargement: boolean = false;

  currentUser: ClientAuth;
  client: Client;
  items: any;
  tokenFormGroup: FormGroup;
  decouvertForm: FormGroup;

  decouverts: any[];

  constructor(public formBuilder: FormBuilder, private parameterService: ParameterService,
    private modalService: NgbModal, private route: Router
  ) { }


  ngOnInit() {

    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      this.currentUser = JSON.parse(localStorage.getItem('YolinFrontCurentuser'));

    } else {
      this.currentUser = JSON.parse(sessionStorage.getItem('YolinFrontCurentuser'));

    }
    // Verifier un client est en ligne
    if (!this.currentUser) {
      this.route.navigate(['/']);  //Rediriger l'utilisateur à l'accueil
    }

    this.client = this.currentUser.client;
    this.getallhistoriqueabnbyclient();
    this.getDecouvert();
    this.buildForm();


  }


  buildForm() {
    this.rechargerForm = this.formBuilder.group({
      montantrechargement: ['', [Validators.required, Validators.min(35000), CustomValidators.number]],
      typePay: ['mobilemoney', [Validators.required]]
    });

    this.passcarteRechargerForm = this.formBuilder.group({
      passcarte: ['', [CustomValidators.number, Validators.required, CustomValidators.rangeLength([4, 4])]]
    });

    this.decouvertForm = this.formBuilder.group({
      decouvert: ['', Validators.required]
    });
  }


  radioTypeAbnChange(value) {
    switch (value) {
      case 'mobilemoney':
        this.displayPassRechargement = false;
        //this.decouvert = true;
        break;
      case 'carteabonnement':
        this.displayPassRechargement = true;
        //this.decouvert = false;
        break;
      default:
        break;
    }
  }

  findlastbyrefclient() {
    // Verifier les montants 
    if (this.items[0].abonnementprecompte && this.rechargerForm.get('montantrechargement').value < 75000) {
      this.openModal('Le montant minimum est de 75.000 fcfa');
    } else {
      this.openSelectedNotifModal('Vous etes sur le point de faire un rechargement de ' + this.rechargerForm.get('montantrechargement').value + ' FCFA, Vous etes sûr?' , 1);
    }
  }


  findlastbyrefclientDecouvert() {
    this.openSelectedNotifModal('Vous etes sur le point de prendre un decouvert de ' + this.decouvertForm.get('decouvert').value + ' FCFA, Vous etes sûr?' , 2);
  }

  getallhistoriqueabnbyclient() {
    this.parameterService.getAllWithKey('abonnement/findbyrefclient/' + this.client.clientid).subscribe(data => {
      switch (data.status) {
        case 200: {
          this.items = data.body;
          break;
        }
        case 204: {


          break;
        }
        default: {
          break;
        }

      }
    },
      error => {


        switch (error.status) {

          default: {


          }
        }
      });
  }


  savePayment() {
    this.parameterService.getAllWithKey('abonnement/findlastbyrefclient/' + this.client.clientid).subscribe(data => {
      switch (data.status) {
        case 200: {
          // Faire le rechargement
          if (this.rechargerForm.get('typePay').value == 'mobilemoney') {
            this.rechargerAbnMobileMoney(data.body.abonnementid);
          } else {
            this.rechargerAbnCompteRestau(data.body.abonnementid);
          }
          break;
        }
        case 204: {

          this.openModal('abonnement introuvable');

          break;
        }
        default: {
          break;
        }

      }
    },
      error => {


        switch (error.status) {

          default: {


          }
        }
      });
  }

  saveDecouvert() {
    this.parameterService.getAllWithKey('abonnement/findlastbyrefclient/' + this.client.clientid).subscribe(data => {
      switch (data.status) {
        case 200: {
          this.rechargerDecouvert(data.body.abonnementid);
          break;
        }
        case 204: {

          this.openModal('abonnement introuvable');

          break;
        }
        default: {
          break;
        }

      }
    },
      error => {


        switch (error.status) {

          default: {


          }
        }
      });
  }

  rechargerDecouvert(abonnementid: string) {

    let abonnementCreditDto: AbonnementCreditDto = new AbonnementCreditDto();
    abonnementCreditDto.abonnementid = abonnementid;
    abonnementCreditDto.provenance = 'frontoffice';
    abonnementCreditDto.solde = this.decouvertForm.get('decouvert').value;
    //abonnementCreditDto.codesecretcarte = this.passcarteRechargerForm.get('passcarte').value;
    this.parameterService.postWithKey("abonnement/rechargebydecouvert", abonnementCreditDto).subscribe(data => {

      switch (data.status) {
        case 201: {
          this.openModal( 'Votre rechargement est effectué avec succes.');
          this.route.navigate(['/']);  //Rediriger l'utilisateur à l'accueil

          break;
        }
        case 204: {
          this.openModal( 'abonnement introuvable');


          break;
        }
        default: {

          break;
        }

      }
    },
      error => {
       

        switch (error.status) {


          case 401: {
            this.openModal( 'Erreur d\'enregistrement');

            break;
          }

          case 406: {
            this.openModal( 'Cumul max de decouvert atteint');

            break;
          }

          case 409: {
            this.openModal( 'Vous n\'avez pas les habilitations pour souscrire à un decouvert');

            break;
          }


          default: {
            this.openModal( 'Une erreur est survenue');

          }
        }
      });
  }

  rechargerAbnMobileMoney(abonnementid: string) {
    let abonnementCreditDto: AbonnementCreditDto = new AbonnementCreditDto();
    abonnementCreditDto.abonnementid = abonnementid;
    abonnementCreditDto.solde = this.rechargerForm.get('montantrechargement').value;
    this.parameterService.postWithKey("abonnement/recharge", abonnementCreditDto).subscribe(data => {


      switch (data.status) {
        case 201: {
          this.openModal('Votre demande de rechargement est en cours. Vous serez redirigé vers l\'interface de paiement afin de la finaliser.');

          // Recuperation des infos necessaire pour la gateway
          let response = data.body;
          let referencetransaction = data.body.abonnementtransid;
          // Redirection sur la gateway
          this.redirectGateway(referencetransaction, abonnementCreditDto.solde);
          break;
        }
        case 204: {
          this.openModal('abonnement introuvable');


          break;
        }
        default: {

          break;
        }

      }
    },
      error => {


        switch (error.status) {


          case 401: {
            this.openModal('Erreur d\'enregistrement');


            break;
          }


          default: {
            this.openModal('Une erreur est survenue');

          }
        }
      });
  }


  redirectGateway(refTrans: string, solde: number) {
    // Creation de l'objet de paiement
    let paymonetq: PaymentMonetique = new PaymentMonetique();
    paymonetq.commandreference = refTrans;
    paymonetq.clientreference = this.client.clientid;
    paymonetq.totalachat = solde;
    let plats: Foods[] = [];
    paymonetq.plats = plats;
    // Get token 
    this.parameterService.postWithKey('gatewaymonetique/token', paymonetq).subscribe(data => {

      switch (data.status) {

        default: {


          let cl: AuthResponse = new AuthResponse();

          cl = data.body;


          this.tokenFormGroup = this.formBuilder.group({
            'currency': ['xof'],
            'operation_token': [environment.operationToken],
            'order': [paymonetq.commandreference],
            'transaction_amount': [paymonetq.totalachat],
            'jwt': [cl.auth_token],
          });

          this.post(this.tokenFormGroup.value, environment.urlGateway);

          break;

        }
      }
    },
      error => {




        switch (error.status) {
          case 406: {

            this.openModal('Erreur de token gateway');

            /* this.snackbar.open('Erreur de token gateway', '×',
            { panelClass: 'success', verticalPosition: 'top', duration: 5000 }); */
            break;
          }
          default: {

            this.openModal('Une erreur est survenue, reessayer SVP ou contacter l\'administrateur');

            /* this.snackbar.open('Erreur de token gateway', '×',
            { panelClass: 'success', verticalPosition: 'top', duration: 5000 }); */
            break;
          }
        }
      });
  }


  post(obj, url) {
    var mapForm = document.createElement("form");
    mapForm.target = "_self";
    mapForm.method = "POST"; // or "post" if appropriate
    mapForm.action = url;
    Object.keys(obj).forEach(function (param) {
      var mapInput = document.createElement("input");
      mapInput.type = "hidden";
      mapInput.name = param;
      mapInput.setAttribute("value", obj[param]);
      mapForm.appendChild(mapInput);
    });
    document.body.appendChild(mapForm);
    mapForm.submit();
  }

  rechargerAbnCompteRestau(abonnementid: string) {

    let abonnementCreditDto: AbonnementCreditDto = new AbonnementCreditDto();
    abonnementCreditDto.abonnementid = abonnementid;
    abonnementCreditDto.provenance = 'frontoffice';
    abonnementCreditDto.solde = this.rechargerForm.get('montantrechargement').value;
    abonnementCreditDto.codesecretcarte = this.passcarteRechargerForm.get('passcarte').value;

    this.parameterService.postWithKey("abonnement/rechargebycompterestau", abonnementCreditDto).subscribe(data => {

      switch (data.status) {
        case 200: {

          this.openModal('Votre rechargement est effectué avec succes');
          this.route.navigate(['/']);
          break;
        }
        case 204: {
          this.openModal('abonnement introuvable');
          break;
        }
        //abonnement introuvable

        default: {
          break;
        }

      }
    },
      error => {

        switch (error.status) {

          case 401: {
            this.openModal('Erreur d\'enregistrement');

            break;
          }

          case 406: {
            this.openModal('Credit insuffisant');

            break;
          }

          case 409: {
            this.openModal('Code secret incorrect');

            break;
          }


          default: {
            this.openModal('Une erreur est survenue');

          }


        }
      });

  }

  getDecouvert() {
    //this.loader.open('Chargement des decouverts en cours');

    this.parameterService.getAllWithKey('parameter/decouvert/getalldecouvert').subscribe(data => {

      //this.loader.close();
      switch (data.status) {
        case 200: {
          this.decouverts = data.body;
          break;
        }
        default: {

          break;
        }

      }
    },
      error => {


        switch (error.status) {


          case 401: {
            this.openModal('Erreur survenue');

            break;
          }

          default: {
            this.openModal('Une erreur est survenue');

          }
        }
      });
  }

  async openSelectedNotifModal(message , i ) {
    this.modalService.open(NotifselectedComponent
      ,
      {
        size: 'xs',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: message
        }])
      }).result.then((result) => {

        `Result ${result}`

      }, (reason) => {
        this.closeResult = `${this.getDismissReason(reason)}`;
        if (this.closeResult && i==1) {
          this.savePayment();
        }
        if (this.closeResult && i==2) {
          this.saveDecouvert();
        }
        
      });
  }

  async openModal(product) {
    this.modalService.open(NotifDialogComponent,

      {
        size: 'lg',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: product
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  public closeResult: string;


}
