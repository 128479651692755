import { Routes } from "@angular/router";
import { AboutComponent } from "./components/pages/about/about.component";
import { BlogDetailsComponent } from "./components/pages/blog-details/blog-details.component";
import { BlogComponent } from "./components/pages/blog/blog.component";
import { BookTableComponent } from "./components/pages/book-table/book-table.component";
import { CartComponent } from "./components/pages/cart/cart.component";
import { CategoriesComponent } from "./components/pages/categories/categories.component";
import { CheckoutComponent } from "./components/pages/checkout/checkout.component";
import { ChefsComponent } from "./components/pages/chefs/chefs.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { FoodCollectionComponent } from "./components/pages/food-collection/food-collection.component";
import { HomeThreeComponent } from "./components/pages/home-three/home-three.component";
import { HomeTwoComponent } from "./components/pages/home-two/home-two.component";
import { OnlineOrderComponent } from "./components/pages/online-order/online-order.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { AccueilComponent } from "./components/pages/restau-tresor/accueil/accueil.component";
import { MenuCartComponent } from "./components/pages/restau-tresor/menu-cart/menu-cart.component";
import { MenuCarteComponent } from "./components/pages/restau-tresor/menu-carte/menu-carte.component";
import { MenuContactComponent } from "./components/pages/restau-tresor/menu-contact/menu-contact.component";
import { MenuFaqComponent } from "./components/pages/restau-tresor/menu-faq/menu-faq.component";
import { MenuProposComponent } from "./components/pages/restau-tresor/menu-propos/menu-propos.component";
import { MenuServiceComponent } from "./components/pages/restau-tresor/menu-service/menu-service.component";
import { ResetPasswordComponent } from "./components/pages/restau-tresor/reset-password/reset-password.component";
import { SessionComponent } from "./components/pages/restau-tresor/session/session.component";
import { SpaceUserComponent } from "./components/pages/restau-tresor/space-user/space-user.component";
import { ServicesDetailsComponent } from "./components/pages/services-details/services-details.component";
import { ServicesComponent } from "./components/pages/services/services.component";
import { TermsConditionsComponent } from "./components/pages/terms-conditions/terms-conditions.component";

export const APP_ROUTES: Routes = [
  { path: "", component: AccueilComponent },
  { path: "service", component: MenuServiceComponent },
  { path: "carte", component: MenuCarteComponent },
  { path: "contact", component: MenuContactComponent },
  { path: "faq", component: MenuFaqComponent },
  { path: "propos", component: MenuProposComponent },
  { path: "panier", component: MenuCartComponent },
  { path: "session", component: SessionComponent },
  { path: "reset-mot-de-passe-oublie", component: ResetPasswordComponent },
  { path: "moncompte", component: SpaceUserComponent },

  { path: "home-two", component: HomeTwoComponent },
  { path: "home-three", component: HomeThreeComponent },
  { path: "about", component: AboutComponent },
  { path: "categories", component: CategoriesComponent },
  { path: "services", component: ServicesComponent },
  { path: "services-details", component: ServicesDetailsComponent },
  { path: "blog", component: BlogComponent },
  { path: "blog-details", component: BlogDetailsComponent },
  { path: "food-collection", component: FoodCollectionComponent },
  { path: "online-order", component: OnlineOrderComponent },
  { path: "chefs", component: ChefsComponent },
  { path: "faq", component: FaqComponent },
  { path: "book-table", component: BookTableComponent },
  { path: "cart", component: CartComponent },
  { path: "checkout", component: CheckoutComponent },
  { path: "coming-soon", component: ComingSoonComponent },
  { path: "terms-conditions", component: TermsConditionsComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "error", component: ErrorComponent },
  { path: "contact", component: ContactComponent },
  { path: "**", component: ErrorComponent },
];
