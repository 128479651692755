<section class="cart-area">
    <div class="container">
        <div class="cart-wrap-order">
            <table class="table">
                <thead class="thead">
                    <tr>
                        <th class="table-head" scope="col">Reference</th>
                        <th class="table-head" scope="col">Date</th>
                        <th class="table-head" scope="col">Prix Net</th>
                        <th class="table-head" scope="col">Statut</th>
                        <th class="table-head" scope="col">Details</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let row of items | slice: (page-1) * pageSize : page * pageSize">
                        <td>{{ row?.commandreference }}</td>
                        <td>
                            {{ row?.historiqueventedate }}
                        </td>
                        <td>{{ row?.commandtotalpricepayer }}</td>
                        <td>
                            {{ (row?.annulationStatus) == true ? 'ANNULER' : (row?.livraisonStatus) == true ? 'Livré' :
                            'Non livré' }}
                        </td>
                        <td><a (click)="openOrderModal(row)" style="cursor: pointer;"><i
                                    class='bx bx-search-alt'></i></a></td>
                    </tr>

                </tbody>
            </table>
            <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="items.length"></ngb-pagination>
            <!--
                <app-custom-pagination (pageChange)="page = $event" id="1"
            maxSize="5"
            directionLinks="true"
            autoHide="true">   </app-custom-pagination>

            -->
        </div>
    </div>
</section>