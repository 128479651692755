<div class="download-content">
  <app-navbar-style-one></app-navbar-style-one>

  <div class="page-title-area">
    <div class="container">
      <div class="row">

        <div class="col-lg-3">

        </div>


        <div class="col-lg-6">
          <div class="page-title-item" style="text-align: center;">
            <h2>COMMANDER</h2>
          </div>
        </div>

        <div class="col-lg-3">

        </div>
      </div>
    </div>
  </div>

  <section class="story-area pt-100 pb-70">

    <ng-container *ngIf="GetTotalFoodInShopping()>0; then checkOut ; else checkIn ">
    </ng-container>

    <ng-template #checkIn>

      <div style="background-color: white; min-height: 650px;">
        <a class="navbar-brand" routerLink="/"><img src="assets/img/buy.png" alt="Logo" height="350px"
            width="380px"></a>
        <div class="shop-back">
          <a routerLink="/" style="color: #f07409;">votre panier est vide</a>
        </div>
      </div>
    </ng-template>
    <ng-template #checkOut>

      <div style="background-color: white; height: 100%; padding-top: 30px;">
        <div class="stepper" style="margin-top: 50px;">
          <div><span>01</span> CHOIX DU PLAT</div>
          <p style=" display: inline-block ; width: 150px; background-color: #f07409; height: 10px;"></p>
          <div><span>02</span> LIVRAISON</div>
          <p style=" display: inline-block ; width: 150px; background-color: #f07409; height: 10px;"></p>
          <div><span>03</span> PAIEMENT</div>
          <p style=" display: inline-block ; width: 150px; background-color: #f07409; height: 10px;"></p>

          <div><span>04</span> CONFIRMATION </div>

        </div>



        <div *ngIf="level==0" class="cart-area">
          <div class="container">
            <div class="cart-wrap">
              <table class="table">

                <tbody>

                  <tr *ngFor="let product of foods">
                    <td class="table-item" style="width:120px" scope="row">
                      <img [src]="product.fooddownloadimageurl" height="100px" alt="Menu">
                    </td>
                    <td style="width:320px">
                      {{ product.foodname | titlecase }}
                    </td>
                    <td style="width:100px">
                      {{product.foodprice }} FRS
                    </td>
                    <td style="width:150px">

                      <div class="collection-bottom-cart">
                        <ul>
                          <li>
                            <div class="number">
                              <span (click)="decrement(product.foodid)" class="minus">-</span>
                              <span class="plus"> {{getFoodInShoppingQte(product.foodid)}} </span>
                              <span (click)="increment(product)" class="plus">+</span>
                            </div>
                          </li>
                        </ul>

                        <br>
                        <div (click)="DeleteFoodInShopping(product.foodid)" class="shop-back" style="color: red;">
                          <i class='bx bx-x'></i> retirer
                        </div>

                      </div>

                    </td>
                  </tr>

                </tbody>
              </table>

              <div class="shop-back">
                <a routerLink="/">retour</a>
              </div>

              <div class="total-shopping">
                <h3>Sous-total: <span>{{GetTotal()}} FRS</span></h3>
                <a (click)="goToNext()">Passer Commande</a>
              </div>
            </div>
          </div>
        </div>

        <section *ngIf="level==1" class="menu-area">
          <div class="container">

            <div class="row">
              <div class="col-lg-3">

              </div>

              <div class="col-lg-6">


                <div class="menu-item">
                  <h3>Mode de livraison
                  </h3>
                  <br>
                  <div class="container">

                    <div class="row">


                      <ng-container *ngFor="let method of deliveryMethods">
                        <div class="col-lg-4">

                          <input type="radio" [value]="method" [formControl]="deliveryForm.controls['deliveryMethod']"
                            (change)="radioChange(method)"> &nbsp;
                          <b style="color: black;">{{method.livraisonmodename}}</b>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="displayDelivery && adress.length > 0">

                        <div class="contact-location-area">

                          <br>
                          <div class="container">
                            <div class="row">
                              <div *ngFor="let item of adress" class="col-lg-4">
                                <div (click)="onValChange(item)" class="location-item"
                                  [ngClass]="{'location-item-selected': adressSelected && adressSelected.addressid==item.addressid}"
                                  style="height: 210px;">
                                  <i class='bx bxs-home-circle'></i>
                                  <ul>
                                    <li>{{ item.addresszone }} </li>
                                    <li>{{ item.addressquartier }}</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                      </ng-container>

                      <ng-container *ngIf="displayDelivery && adress.length==0">
                        <div class="total-shopping">
                          <a>Ajouter une adresse</a>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="deliveryMethods.length>0">


                        <div class="container">

                          <div class="row">

                            <div class="col-lg-6">
                              <div class="total-shopping-back">
                                <a (click)="returnToPrec()">retour</a>
                              </div>
                            </div>

                            <div class="col-lg-6">
                              <div class="total-shopping" style="display: inline-block;">
                                <a (click)="checkLivraisonAndNext()">suivant</a>
                              </div>

                            </div>

                          </div>

                        </div>




                      </ng-container>




                    </div>

                  </div>


                </div>
              </div>

              <div class="col-lg-3">

              </div>
            </div>
          </div>
        </section>





        <section *ngIf="level==2" class="menu-area">
          <div class="container">

            <div class="row">
              <div class="col-lg-2">

              </div>

              <div class="col-lg-8">

                <div class="menu-item">
                  <h3>Mode de paiement
                  </h3>
                  <br>
                  <div class="container">

                    <div class="row">
                      <ng-container *ngFor="let method of PaiementMethods">
                        <div class="col-lg-4">
                          <input type="radio" name="payment" [value]="method" (change)="paiementRadioChange(method)"
                            [formControl]="paymentForm.controls['paiementMethod']">
                          &nbsp;
                          <b style="color: black;">{{method.paymentmodename}}</b>
                        </div>
                      </ng-container>


                      <div *ngIf="displayPass" class="form-group">
                        <br>

                        <input type="password" name="password" [formControl]="paymentForm.controls['codesecretcarte']"
                          placeholder="code de carte" maxlength="4" class="form-control-insc">
                      </div>

                      <ng-container *ngIf="PaiementMethods.length>0">
                        <div class="container">
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="total-shopping-back">
                                <a (click)="returnToPrec()">retour</a>
                              </div>
                            </div>

                            <div class="col-lg-6">
                              <div class="total-shopping" style="display: inline-block;">
                                <a (click)="checkCartAndFinalise()">suivant</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>


                </div>



              </div>

              <div class="col-lg-8">

              </div>
            </div>
          </div>
        </section>

        <section *ngIf="level==3" class="menu-area">
          <div class="container">


            <div class="menu-item" style="margin-left: 120px; margin-right: 120px;">
              <div class="row">
                <div class="col-lg-6">

                  <div class="card-recap-command">

                    <div *ngIf="allfoods.length > 0">
                      <div style="font-weight: bold; font-size: 16px; color: black;"> Ma commande</div>

                      <div *ngFor="let product of allfoods"
                        style="height: 100% ;  padding: 8px ; font-size: 12px; color: black ">


                        <div class="row">
                          <div class="col-lg-6" style="text-align: left;">
                            {{product.foodname}}
                          </div>
                          <div class="col-lg-6" style="text-align: right;">
                            {{product.foodprice *
                            product.foodqte
                            }} CFA
                          </div>
                        </div>



                      </div>


                      <div *ngIf="(f.clientdelivrery | lowercase) == 'a livrer'" class="row"
                        style="height: 100% ;  padding: 8px ; font-size: 12px; color: #f07409 ">
                        <div class="col-lg-6" style="text-align: left;">
                          Livraison :
                        </div>
                        <div class="col-lg-6" style="text-align: right;">
                          {{quartiers?.pointlivraisonquartierprix}} fcfa
                        </div>
                      </div>


                      <div class="row" style="height: 100% ;  padding: 8px ; font-size: 12px; color: #f07409 ">
                        <div class="col-lg-6" style="text-align: left;">
                          Total :
                        </div>
                        <div class="col-lg-6" style="text-align: right;">
                          {{GetTotal()}} fcfa
                        </div>
                      </div>



                    </div>
                  </div>

                </div>

                <div class="col-lg-6">
                  <div style="padding: 15px" *ngIf="f!==null">

                    <div class="details-cmd-infos" style=" text-align: left; color: black;">

                      <div style="font-size: 18px!important;">

                        {{f.clientlastname}} {{f.clientfirstname}}
                      </div>
                      <div>
                        {{f.clienttelephone}}
                      </div>
                      <div>
                        {{f.clientemail}}
                      </div>
                      <div>
                        {{f.clientdelivrery}}
                      </div>
                      <div>
                        {{f.clientpayment}}
                      </div>
                      <div *ngIf="f.clientlieulivraisonzone">
                        {{f.clientlieulivraisonzone + ' ' + f.clientlieulivraisonquartier}}
                      </div>
                      <div>
                        {{f.clientcommandheure | date:'HH:mm'}}
                      </div>
                    </div>

                    <!-- <button color="primary" (click)="openDialog()" class="mat-elevation-z2" style="width: 50%;height: 25px ; background-color: orangered ; border: none ; color: white; margin: auto">
                        Finaliser Achat
                      </button> -->

                  </div>

                </div>


              </div>

              <div *ngIf="checkOrderSpecial" class="row">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-2">


                    </div>

                    <div class="col-lg-8" style="color: red; font-weight: bold; text-align: center;">

                      Commande speciale
                    </div>

                    <div class="col-lg-2">


                    </div>
                  </div>
                </div>
              </div>




              <div class="row">
                <ng-container>
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="total-shopping-back">
                          <a (click)="returnToPrec()">retour</a>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="total-shopping" style="display: inline-block;">
                          <a (click)="openDialog()">confirmer la commande</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>



            </div>


          </div>
        </section>

      </div>

    </ng-template>




  </section>


  <app-footer-style-two></app-footer-style-two>