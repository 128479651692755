import { Component, Injector, ViewChild } from "@angular/core";
import {
  ModalDismissReasons,
  NgbCarouselConfig,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { OwlOptions } from "ngx-owl-carousel-o";
import { FoodDialogComponent } from "../food-dialog/food-dialog.component";
import { Foods } from "../models/models";
import { ParameterService } from "../parameter.service";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-accueil",
  templateUrl: "./accueil.component.html",
  styleUrls: ["./accueil.component.scss"],
})
export class AccueilComponent {
  code: any = 0;

  categories: any[] = [];
  foods: any[] = [];
  foodSize: any = 0;

  @ViewChild("cartModal") CartModal: FoodDialogComponent;

  dateaujoudhui = new Date(
    this.parameterService.convertDateToString(
      this.parameterService.getCurrentDate()
    )
  );

  categorieSelected: any = "";

  public foodSliderConfig: any = {
    loop: false,
    dots: false,
    navSpeed: 300,
    autoHeight: true,
    responsive: {
      991: {
        items: 4,
      },

      767: {
        items: 3,
      },
      420: {
        items: 2,
      },
      0: {
        items: 1,
      },
    },
  };

  constructor(
    private parameterService: ParameterService,
    private modalService: NgbModal,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getAllCategories();

    const token = this.route.snapshot.queryParamMap.get("token");

    if (token) {
      this.openResetPasswordComponentModal();
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  public closeResult: string;

  checkHour(obj) {
    //this.logger.trace('Obj', obj);
    let result = this.parameterService.checkHourFood(
      obj.heurecommandeheuredebut,
      obj.heurecommandeheurefin
    );
    //this.logger.trace('result', result);
    return result;
  }

  checkHourCommande(product) {
    //this.logger.trace('Obj', obj);
    if (product) {
      let result = this.parameterService.checkHourCommande(
        product.parameterheure,
        product.parametermin
      );
      return result;
    }
    //this.logger.trace('result', result);
  }

  // Verifier si la date du plat est differente de la date du jour pour empecher la commande
  checkDate(product) {
    let result = true;
    if (product.foodtype == "permanente") {
      // Ne rien faire
      //this.logger.trace('Ne rien faire');
    } else {
      let datemenu = product.fooddatemenu;
      //this.logger.trace('datemenu', datemenu);
      let compare = this.parameterService.compareDate(
        datemenu,
        this.dateaujoudhui
      );
      //this.logger.trace('result', result);
      if (compare == 0) {
        result = true;
      } else {
        result = false;
      }
      //this.logger.trace('result comparaison', result);
    }

    return result;
  }

  async openModal(product) {
    console.log(product);

    this.modalService
      .open(FoodDialogComponent, {
        size: "xl",
        ariaLabelledBy: "Cart-Modal",
        centered: true,
        windowClass: "theme-modal cart-modal CartModal",
        injector: Injector.create([
          {
            provide: Foods,
            useValue: product,
          },
        ]),
      })
      .result.then(
        (result) => {
          `Result ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    navText: ["&#8249", "&#8250;"],
    responsive: {
      /*
      0: {
        items: 1
      },
      */
      400: {
        items: 2,
      },

      760: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
    nav: true,
  };

  public getAllCategories() {
    this.parameterService
      .getAllWithout("parameter/categorie")
      .subscribe((data) => {
        this.categories = data.body;
        console.log(this.categories);
        if (this.categories && this.categories.length > 0) {
          this.checkFoodByCategories(this.categories[0].categoriename);
        }
      });
  }

  public checkFoodByCategories(categorie: any) {
    let objToSend: any = {};
    objToSend.categorie = categorie;
    this.categorieSelected = categorie;
    objToSend.datevalidite = this.parameterService.convertDateToString(
      this.parameterService.getCurrentDate()
    );
    //parameter/food/bydateandcat
    this.parameterService
      .postWithoutKey("parameter/food/bydateandcat", objToSend)
      .subscribe((data) => {
        this.foods = data.body;
        console.log(this.foods);
        if (this.foods) {
          this.foodSize = this.foods.length;
        } else {
          this.foods = [];
          this.foodSize = 0;
        }
      });
  }

  add_to_shopping_cart(food: Foods) {
    // Recuperer le plat pour mettre a jour le prix global
    let foodSave: Foods = new Foods();
    foodSave = this.getFoodInShopping(food.foodid);

    // food.foodqte = this.count;
    food.foodqte = 1;
    if (foodSave) {
      food.foodtotal = foodSave.foodtotal + food.foodprice * food.foodqte;
    } else {
      food.foodtotal = food.foodprice * food.foodqte;
    }

    this.AddFoodInShopping(food);
    //this.dialogRef.close();
  }

  getFoodInShopping(val: string) {
    let res: any = sessionStorage.getItem("allfood");
    let allfood: Foods[] = JSON.parse(res);

    if (allfood) {
      for (let f of allfood) {
        if (f.foodid === val) {
          return f;
        }
      }
    }
    return null;
  }

  AddFoodInShopping(val: Foods) {
    // this.DeleteFoodInShopping(val);
    // this.logger.trace('valeur add food', val);
    let res: any = sessionStorage.getItem("allfood");
    let allfood: Foods[] = JSON.parse(res);

    if (allfood && allfood.length > 0) {
      for (let index = 0; index < allfood.length; index++) {
        /* for (let f of allfood) { */

        if (allfood[index].foodid == val.foodid) {
          if (
            allfood[index].foodqte + val.foodqte >
            val.foodstockdisponible - val.foodstockalerte
          ) {
            // verifier la quantité disponible
            //   this.openNotification('Notification', 'La quantité est indisponible');
            // allfood.push(allfood[index]);
          } else {
            allfood[index].foodqte += val.foodqte;
            allfood.push(allfood[index]);
            allfood.splice(index, 1);
            //   this.openNotification('Notification', 'Plat ajouté avec succes');
          }

          break;
        } else {
          if (index == allfood.length - 1) {
            allfood.push(val);
            // this.openNotification('Notification', 'Plat ajouté avec succes');
            break;
          }
        }
      }
    } else {
      allfood = [];
      allfood.push(val);
      //  this.openNotification('Notification', 'Plat ajouté avec succes');
    }

    // this.logger.trace('allfoods fin', allfood);

    sessionStorage.setItem("allfood", JSON.stringify(allfood));
  }

  // Add by Christian
  async openResetPasswordComponentModal() {
    this.modalService
      .open(ResetPasswordComponent, {
        size: "md",
        ariaLabelledBy: "Cart-Modal",
        centered: true,
        windowClass: "theme-modal cart-modal CartModal",
        // injector: Injector.create([
        //   {
        //     provide: Address,
        //     useValue: {},
        //   },
        // ]),
      })
      .result.then(
        (result) => {
          `Result ${result}`;
        },
        (reason) => {
          this.closeResult = `${this.getDismissReason(reason)}`;
          if (this.closeResult) {
            // this.getAdressByClientId(this.client.clientid);
          }
        }
      );
  }
}
