<div class="form-container">
  <form [formGroup]="form">
    <h3>Réinitialisation mot de passe</h3>

    <div class="form-group" style="margin-bottom: 10px">
      <input
        type="password"
        class="form-control"
        placeholder="Nouveau mot de passe"
        formControlName="password"
      />
    </div>

    <div class="form-group">
      <input
        type="password"
        class="form-control"
        placeholder="Confirmation nouveau mot de passe"
        formControlName="confirmPassword"
      />
    </div>

    <div
      style="margin-top: 20px"
      class="alert alert-danger"
      *ngIf="form.errors"
    >
      Les mots de passe ne correspondent pas.
    </div>

    <button
      type="button"
      class="btn cmn-btn-insc"
      (click)="handleSubmit()"
      [disabled]="form.invalid || isSubmitting"
    >
      {{ isSubmitting ? "Veuillez patienter..." : "Valider" }}
    </button>
  </form>
</div>
