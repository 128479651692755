<div class="navbar-area fixed-top">
  <div class="mobile-nav">
    <a routerLink="/" class="logo"
      ><img src="assets/img/smile.png" alt="Logo" height="80px"
    /></a>
  </div>

  <div class="main-nav">
    <div class="container">
      <nav class="navbar navbar-expand-md navbar-light">
        <a class="navbar-brand" routerLink="/"
          ><img src="assets/img/smile.png" alt="Logo" height="80px"
        /></a>

        <div
          class="collapse navbar-collapse mean-menu"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                routerLink="/"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >ACCUEIL</a
              >
            </li>
            <li class="nav-item">
              <a
                routerLink="/carte"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >CARTES</a
              >
            </li>
            <li class="nav-item">
              <a
                routerLink="/service"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >SERVICE</a
              >
            </li>
            <li class="nav-item">
              <a
                routerLink="/contact"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >CONTACTS</a
              >
            </li>
            <li class="nav-item">
              <a
                routerLink="/faq"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >FAQS</a
              >
            </li>
            <li class="nav-item">
              <a
                routerLink="/propos"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >A PROPOS</a
              >
            </li>
          </ul>

          <div class="side-nav">
            <a
              *ngIf="GetTotalFoodInShopping() > 0"
              class="nav-cart"
              (click)="checkCart()"
              ><i class="bx bxs-cart"></i>
              <span>{{ GetTotalFoodInShopping() }}</span></a
            >
            <a
              *ngIf="GetTotalFoodInShopping() == 0"
              class="nav-cart"
              (click)="checkCart()"
              ><i class="bx bxs-cart"></i
            ></a>

            <a
              routerLink="/session"
              *ngIf="!VerifyConnection()"
              class="nav-tel"
              style="cursor: pointer"
            >
              SE CONNECTER</a
            >

            <ul
              *ngIf="VerifyConnection()"
              class="navbar-nav"
              style="padding: 0px; margin: 0px; display: inline-block"
            >
              <li class="nav-item">
                <a href="javascript:void(0)" class="nav-link dropdown-toggle">
                  BONJOUR {{ GetUserName() }}
                </a>

                <ul class="dropdown-menu">
                  <li class="nav-item" style="padding: 0px">
                    <a
                      style="cursor: pointer"
                      (click)="goToProfil()"
                      class="nav-link"
                      >Mon espace personnel</a
                    >
                  </li>

                  <li class="nav-item" style="padding: 0px">
                    <a
                      class="nav-link"
                      style="cursor: pointer"
                      (click)="sedeconnecter()"
                      >se deconnecter</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>
