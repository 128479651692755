<footer class="footer-area-two pt-100 pb-70">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-lg-3">
        <div class="footer-item">
          <div class="footer-services">
            <h3 style="color: white">Plan du site</h3>

            <ul>
              <li><a routerLink="/">Accueil</a></li>
              <li><a routerLink="/"> Carte</a></li>
              <li><a routerLink="/"> Services</a></li>
              <li><a routerLink="/">Contact</a></li>

              <li><a routerLink="/">Faqs</a></li>
              <li><a routerLink="/">À propos</a></li>
              <li><a routerLink="/">Panier</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="footer-item">
          <div class="footer-services">
            <h3 style="color: white">Contacts</h3>

            <ul>
              <li><a routerLink="/">Email : infos@yolintraiteur.com</a></li>
              <li><a routerLink="/"> N° : (+225) 07 579 696 78</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="footer-item">
          <div class="footer-services">
            <h3 style="color: white">Heures d’ouvertures</h3>

            <ul>
              <li><a routerLink="/">Restaurant :</a></li>
              <li>
                <a routerLink="/">
                  Lundi au Vendredi: 6h30-14h sauf les jours fériés</a
                >
              </li>

              <li><a routerLink="/">Lounge Bar :</a></li>
              <li>
                <a routerLink="/"
                  >Lundi au Jeudi de 17h30-21h00 Vendredi de 17h30-00h</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="footer-item">
          <div class="footer-services">
            <h3 style="color: white">Réseaux sociaux</h3>
          </div>
          <div class="footer-logo">
            <a style="display: inline" routerLink="/"
              ><img src="assets/img/rs-1.png" alt="Logo"
            /></a>
            &nbsp;
            <a style="display: inline" routerLink="/"
              ><img src="assets/img/rs-2.png" alt="Logo"
            /></a>
            &nbsp;
            <a style="display: inline" routerLink="/"
              ><img src="assets/img/rs-3.png" alt="Logo"
            /></a>
            &nbsp;
            <a style="display: inline" routerLink="/"
              ><img src="assets/img/rs-4.png" alt="Logo"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

<div class="copyright-area copyright-area-two">
  <div class="container">
    <div class="copyright-item">
      <span>Mentions légales - </span>
      <span>Conditions générales d’utilisation</span>
    </div>
  </div>
</div>
