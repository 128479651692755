<div class="about-content">
    <div class="section-title">
        <br>
        <h5 style="font-weight: bold;">Confirmation</h5>
        <br>
        <p style="color : black"> {{dialogMessage}}</p>
        <br>
        <a class="cmn-btn" (click)="closeNotif('OK')" style="color: white; cursor: pointer;">OUI</a>
        &nbsp; &nbsp;
        <a class="cmn-btn" (click)="closeNotif('NOK')" style="color: white; cursor: pointer;">NON</a>

    </div>
</div>