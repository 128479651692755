import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notif-dialog',
  templateUrl: './notif-dialog.component.html',
  styleUrls: ['./notif-dialog.component.scss']
})
export class NotifDialogComponent {

  dialogMessage : String ;

  constructor(private message: String, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.dialogMessage = this.message;
  }

  closeNotif(){
    this.modalService.dismissAll('OK');
  }

}
