import { Component, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs/internal/Subscription';
import { Address, Client, ClientAuth, CompteRestauLoginDto, Finalizeclient, Foods, Metho, User } from '../models/models';
import { NotifDialogComponent } from '../notif-dialog/notif-dialog.component';
import { ParameterService } from '../parameter.service';
import { CarteValidation } from '../models/app-validators';
import { CustomValidators } from 'ng2-validation';
import { SuccesDialogComponent } from '../succes-dialog/succes-dialog.component';

@Component({
  selector: 'app-menu-cart',
  templateUrl: './menu-cart.component.html',
  styleUrls: ['./menu-cart.component.scss']
})
export class MenuCartComponent {


  //  const button = document.getElementById('btn');

  public cli: any;
  client: Client;
  foods: Foods[] = [];
  allfoods: Foods[] = [];
  f: Finalizeclient = new Finalizeclient();

  nbreOrderSpecial: any = 0;
  checkOrderSpecial: boolean = false;
  nbre: any = 0;

  level: any = 0;

  public getItemSub: Subscription;

  deliveryMethods = [];
  PaiementMethods = [];


  billingForm: FormGroup;
  deliveryForm: FormGroup;
  paymentForm: FormGroup;

  displayDelivery: boolean = false;
  displayHeure: boolean = false;
  displayPass: boolean = false;

  

  adress: Address[] = [];

  adressSelected: Address;
  quartiers: any;

  public closeResult: string;

  constructor(public router: Router, public formBuilder: FormBuilder,

    private parameterService: ParameterService, private modalService: NgbModal) { }

  ngOnInit(): void {

    /*
    const progressBar = document.getElementById("progress-bar");
    const progressNext = document.getElementById("progress-next");
    const progressPrev = document.getElementById("progress-prev");
    const steps = document.querySelectorAll(".step");
    let active = 1;

    progressNext.addEventListener("click", () => {
      active++;
      if (active > steps.length) {
        active = steps.length;
      }
      updateProgress();
    });

    progressPrev.addEventListener("click", () => {
      active--;
      if (active < 1) {
        active = 1;
      }
      updateProgress();
    });

    const updateProgress = () => {
      steps.forEach((step, i) => {
        if (i < active) {
          step.classList.add("active");
        } else {
          step.classList.remove("active");
        }
      });
      progressBar.style.width = ((active - 1) / (steps.length - 1)) * 100 + "%";

    };

*/



    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      this.cli = JSON.parse(localStorage.getItem('YolinFrontCurentuser')); // info client
    } else {
      this.cli = JSON.parse(sessionStorage.getItem('YolinFrontCurentuser')); // info client
    }

    this.client = this.cli.client;

    // this.getAllCategories(); // Liste de toutes les categories

    // this.getPointLivraison(); // Liste de tous les points de livraison


    let v = new User();
    let res: any;

    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      res = localStorage.getItem('YolinFrontCurentuser');
    } else {
      res = sessionStorage.getItem('YolinFrontCurentuser');
    }

    v = JSON.parse(res);

    if (v == null) {
      this.router.navigate(['/']);
    }

    let resfood: any = sessionStorage.getItem("allfood");
    let allfood: Foods[] = JSON.parse(resfood);
    this.foods = allfood;


    if ((allfood == null)) {
      this.router.navigate(['/']);
    }

    if ((allfood.length == 0)) {
      this.router.navigate(['/']);
    }

    this.getallLivraison();
    this.getallPaiement();


    this.deliveryForm = this.formBuilder.group({
      deliveryMethod: [this.deliveryMethods[0], Validators.required]
    });


    this.paymentForm = this.formBuilder.group({
      paiementMethod: [this.PaiementMethods[0], Validators.required],
      //codesecretcarte: ['', [CustomValidators.number, Validators.required, CustomValidators.rangeLength([4, 4])]]
      codesecretcarte: ['', [Validators.required]]

    }

      /*
      , {
        validator: CarteValidation()
      }
      */

    );

  }


  getallLivraison() {
    this.getItemSub = this.parameterService.getAllWithout("parameter/livraison")
      .subscribe(data => {

        let responseany: any = data;



        switch (data.status) {

          default: {
            let response: any = data.body;
            this.deliveryMethods = response;
            break;
          }

        }
      },
        error => {

          switch (error.status) {

            default: {
              //this.loader.close();
              /*
              this.snackbar.open('Erreur suvernue ', 'OK', {
                duration: 10000
              });       
              */

              // return {};
              console.log("----------------ERROR-----------------");
              console.log(error);
              break;
            }
          }
        });
  }

  getallPaiement() {

    this.getItemSub = this.parameterService.getAllWithout("parameter/paymentmode")
      .subscribe(data => {

        let responseany: any = data;

        switch (data.status) {

          default: {


            let response: any = data.body;

            this.PaiementMethods = response;


            break;
          }

        }
      },
        error => {


          switch (error.status) {

            default: {
              //this.loader.close();
              /*
              this.snackbar.open('Erreur suvernue ', 'OK', {
                duration: 10000
              });       
              */
              break;
            }
          }
        });
  }



  goToNext() {
    this.level = this.level + 1;
  }


  returnToPrec() {
    this.level = this.level - 1;
  }


  radioChange(event: any) {

    if (event.livraisonmodename.toLowerCase() == 'a livrer') {
      // Afficher popup
      // this.openNotification('Notification', 'La livraison de votre commande vous sera facturée à 500FCFA si vous êtes dans la commune du plateau et 1000FCFA si vous êtes dans une autre commune.Les frais de livraison sont payables en espèce lors de votre livraison!');

      console.log("A livrer");
      this.displayDelivery = true;
      this.getAdressByClientId(this.client.clientid);
      this.displayHeure = false;

    } else {
      this.displayDelivery = false;
      // this.displayHeure = true;
      this.displayHeure = false; // Temporaire

    }

  }

  paiementRadioChange(event: any) {
    // this.logger.trace('Paiement radio selected');
    // this.logger.trace(event.value);
    if (event.paymentmodename.toLowerCase() == 'carte abonnement') {
      // this.logger.trace('Paiement carte abonnement, Afficher le champ');
      this.displayPass = true;
    } else {
      this.displayPass = false;
    }
  }



  openDialog() {
    this.openModalPayment();
  }


  async openModalPayment() {
    this.modalService.open(SuccesDialogComponent,
      {
        size: 'xs',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: Finalizeclient, useValue: this.f
        }])
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `${this.getDismissReason(reason)}`;
        if (this.closeResult) {
          this.router.navigateByUrl('/moncompte?key='+5);
        }
      });
  }

  onValChange(value) {
    this.adressSelected = value;
    console.log(this.adressSelected);
  }

  checkLivraisonAndNext() {
    let modeLivraison = this.deliveryForm.get('deliveryMethod').value.livraisonmodename;

    switch (modeLivraison.toLowerCase()) {
      case 'sur place':
        this.goToNext();
        break;
      case 'a emporter':
        this.goToNext();
        break;
      case 'a livrer':
        //if(this.formLieulivraison.get('commandlieulivraison').value){
        if (this.adressSelected) { // Verifier si une adresse est disponible .addressid
          this.goToNext();
        } else {
          this.openNotifModal('Saisir l\'adresse de livraison SVP');
        }
        break;

      default:
        // event.next(); // Etape suivante
        break;
    }

  }



  finalize1() {

    let delivry: any = {} = this.deliveryForm.value;
    let paiement: any = {} = this.paymentForm.value;


    let v: any = delivry.deliveryMethod;

    let vd: Metho = v;
    let v1: any = paiement.paiementMethod;

    let vp: Metho = v1;
    let finalise: Finalizeclient = new Finalizeclient();

    let vc = new ClientAuth();
    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      v = JSON.parse(localStorage.getItem("YolinFrontCurentuser"));

    } else {
      v = JSON.parse(sessionStorage.getItem("YolinFrontCurentuser"));

    }
    let u: Client = new Client();
    u = v.client;


    this.f.clientemail = u.clientemail;
    this.f.clienttelephone = u.clienttelephone;
    this.f.clientfirstname = u.clientfirstname;
    this.f.clientlastname = u.clientlastname;

    this.f.clientdelivrery = vd.livraisonmodename;
    this.f.clientpayment = vp.paymentmodename;
    this.f.clientprovenance = "Web-frontOffice";

    //  this.f.clientcommentaire = this.formCommentaire.get('commandecommentaire').value;
    if (vd.livraisonmodename == 'A livrer') {
      this.f.clientlieulivraisonzone = this.adressSelected.addresszone;
      this.f.clientlieulivraisonquartier = this.adressSelected.addressquartier;
    }


    //  this.f.clientcommandheure = this.formLieulivraison.get('commandheure').value;
    this.f.codesecretCarte = this.paymentForm.get('codesecretcarte').value;


    // REcherche du prix de la livraison
    if (vd.livraisonmodename == 'A livrer') {
      this.checkQuartierByName(this.adressSelected.addressquartier);
    }

  }


  checkQuartierByName(name: string) {

    console.log('Recherche du prix de la livraison... ==> ' + name);

    this.getItemSub = this.parameterService.getAllWithKey('parameter/pointlivraisonquartier/getbyname/' + name).subscribe(data => {


      switch (data.status) {
        case 200:
          this.quartiers = data.body;
          break;

        default: {

          break;

        }
      }
    },
      error => {

        switch (error.status) {
          default: {

            break;
          }
        }
      });
  }


  checkCartAndFinalise() {

    // Verifier si le paiement est par carte abonnement
    if (this.paymentForm.get('paiementMethod').value.paymentmodename !== 'carte abonnement') {
      this.finalize1();
      this.goToNext();
    } else {
      let compteRestauLoginDto: CompteRestauLoginDto = new CompteRestauLoginDto();
      compteRestauLoginDto.clientid = this.cli.client.clientid;
      compteRestauLoginDto.carteabonnementcodesecret = this.paymentForm.get('codesecretcarte').value;
      this.loginCartAndFinalise(compteRestauLoginDto);
    }
  }


  loginCartAndFinalise(compteRestauLoginDto: CompteRestauLoginDto) {

    this.parameterService.postWithKey('carte/logincompterestau', compteRestauLoginDto).subscribe(data => {
      switch (data.status) {

        case 200: {
          this.finalize1();
          this.goToNext();
          break;
        }
        case 204: {
          this.openNotifModal('Client introuvable');


          break;
        }
        default: {

          break;
        }
      }

    }, error => {
      switch (error.status) {

        case 401: {
          this.openNotifModal('Mot de passe carte incorrect');


          break;
        }
        case 500: {
          this.openNotifModal('Internal server error');


          break;
        }
        default: {
          this.openNotifModal('Une erreur est survenue');


          break;
        }
      }
    });

  }

  getAdressByClientId(value: string) {


    this.parameterService.getAllWithKey('adress/client/' + value).subscribe(data => {
      switch (data.status) {
        case 200:
          this.adress = data.body;
          break;
        case 204:
          //  this.openNotification('Notification', 'Aucune adresse disponible');

          this.openModal('Aucune adresse disponible');
          this.adress = [];

          break;
        default:

          break;
      }
    },
      error => {
        // this.loader.close();
        // this.logger.error('Erreur', error);

      });
  }


  getFoodInShopping(val: string) {
    let res: any = sessionStorage.getItem("allfood");
    let allfood: Foods[] = JSON.parse(res);
    for (let f of allfood) {
      if (f.foodid === val) {
        return f;
      }
    }
    return null;
  }

  DeleteFoodInShopping(val: String) {
    let res: any = sessionStorage.getItem("allfood");
    let allfood: Foods[] = JSON.parse(res);

    let allfoodshopping: Foods[] = [];
    for (let f of allfood) {
      if (f.foodid != val) {
        allfoodshopping.push(f);
      }
    }

    sessionStorage.setItem('allfood', JSON.stringify(allfoodshopping));
    this.FoodInShopping();
    this.GetTotal();
  }


  FoodInShopping() {
    let res: any = sessionStorage.getItem("allfood");
    let allfood: Foods[] = JSON.parse(res);
  }

  GetTotalFoodInShopping() {



    let res: any = sessionStorage.getItem("allfood");
    let allfood: Foods[] = JSON.parse(res);

    // this.logger.trace("allfood");
    // this.logger.trace(allfood);

    let i: number = 0;
    if (allfood != null) {
      for (let f of allfood) {
        i = i + f.foodqte;
      }
    }



    return i;
  }

  GetTotal() {
    let res: any = sessionStorage.getItem("allfood");
    let allfood: Foods[] = JSON.parse(res);
    this.allfoods = allfood;
    this.foods = this.allfoods;
    let t: number = 0;
    let qte: number = 0;
    for (let f of allfood) {
      // t = t + (f.foodtotal);
      t = t + (f.foodprice * f.foodqte);
      qte = qte + f.foodqte;
    }


    if (this.f.clientdelivrery && this.f.clientdelivrery.toLowerCase() == 'a livrer') {
      t = t + this.quartiers.pointlivraisonquartierprix;
      this.f.delivery = this.quartiers.pointlivraisonquartierprix;
      if (qte >= this.nbreOrderSpecial) {
        this.checkOrderSpecial = true;
      }
    }


    return t;
  }

  getFoodInShoppingQte(val: string) {
    let res: any = sessionStorage.getItem("allfood");
    let allfood: Foods[] = JSON.parse(res);
    //  this.logger.trace("getFoodInShopping");
    //this.logger.trace(allfood);
    for (let f of allfood) {
      if (f.foodid === val) {
        return f.foodqte;
      }
    }
    return null;
  }




  increment(data) {

    let id = data.foodid;

    let f: Foods = this.getFoodInShopping(id);
    // Verifier la quantité est dispo avant increment
    if ((data.foodstockdisponible > f.foodqte) && (data.foodstockdisponible - f.foodqte > data.foodstockalerte)) {

      //if (data.foodstockdisponible > f.foodqte) {


      f.foodqte = f.foodqte + 1;
      f.foodtotal = f.foodprice * f.foodqte;


      let res: any = sessionStorage.getItem("allfood");
      let allfood: Foods[] = JSON.parse(res);

      let allfoodfinal: Foods[] = [];


      for (let fs of allfood) {
        if (fs.foodid === id) {
          allfoodfinal.push(f);
        } else {
          allfoodfinal.push(fs);
        }
      }

      sessionStorage.setItem('allfood', JSON.stringify(allfoodfinal));

      this.FoodInShopping();

    } else {
      /*this.snackBar.open('La quantité est indisponible ', 'OK', {
        duration: 8000
      });*/
      this.openNotifModal('La quantité est indisponible');
    }



    /*
    this.logger.trace(" to increment : "+id);

    let f: Foods = this.getFoodInShopping(id);
    this.logger.trace(" before incrment ");
    this.logger.trace(f);
    f.foodqte = f.foodqte + 1;
    f.foodtotal=f.foodprice*f.foodqte;
    this.logger.trace(" after incrment ");
    this.logger.trace(f);
    this.AddFoodInShopping(f);
    */
  }



  async openNotifModal(message) {
    this.modalService.open(NotifDialogComponent,
      {
        size: 'xs',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: message
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }



  decrement(id: string) {


    let f: Foods = this.getFoodInShopping(id);

    if (f.foodqte > 1) {
      f.foodqte = f.foodqte - 1;
      f.foodtotal = f.foodprice * f.foodqte;


      let res: any = sessionStorage.getItem("allfood");
      let allfood: Foods[] = JSON.parse(res);

      let allfoodfinal: Foods[] = [];


      for (let fs of allfood) {
        if (fs.foodid === id) {
          allfoodfinal.push(f);
        } else {
          allfoodfinal.push(fs);
        }
      }

      sessionStorage.setItem('allfood', JSON.stringify(allfoodfinal));

      this.FoodInShopping();
    }





    /*
    this.logger.trace(" to increment : "+id);

    let f: Foods = this.getFoodInShopping(id);
    this.logger.trace(" before incrment ");
    this.logger.trace(f);
    f.foodqte = f.foodqte + 1;
    f.foodtotal=f.foodprice*f.foodqte;
    this.logger.trace(" after incrment ");
    this.logger.trace(f);
    this.AddFoodInShopping(f);
    */
  }



  async openModal(product) {
    this.modalService.open(NotifDialogComponent,

      {
        size: 'xl',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: product
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }



  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `${reason}`;
    }
  }





}
