<div class="apropos-container">
  <app-navbar-style-one></app-navbar-style-one>

  <section style="margin: 2.5rem auto" class="page-title-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-3"></div>

        <div class="col-lg-6">
          <div class="page-title-item" style="text-align: center">
            <h2>A PROPOS</h2>
          </div>
        </div>

        <div class="col-lg-3"></div>
      </div>
    </div>
  </section>

  <section class="container mp-section-container">
    <h2 class="mp-section-title">PRESENTATION</h2>

    <div class="mp-section-content">
      <div class="mp-section-img-container">
        <img
          src="https://img-3.journaldesfemmes.fr/sPrB2x9s9eN3GXv_7O8C5GPqEc0=/1240x/smart/1b8817a5b37a468c82cb0dd6ded906b4/ccmcms-jdf/39598024.jpg"
          alt=""
        />
      </div>

      <div class="mp-section-text-container">
        <p>
          <em>YOLIN Traiteur</em> est une société de restauration collective
          assurant la fourniture de repas (petit déjeuner, déjeuner)
          l'organisation de séminaires d'entreprises, cocktails d'entreprise,
          l'organisation de tout autre évènement et de gestion de cantine.
        </p>
        <p>
          <em>YOLIN Traiteur</em> est spécialisé dans la confection de mets
          aussi bien africains qu'occidentaux.
        </p>
        <p>
          Nous avons pour objectif de donner une nouvelle vision de la
          restauration collective d'entreprise en Afrique, et une autre
          orientation à l'Entreprise Ivoirienne dans les domaines de l'art
          culinaire. Pour cela,
          <em>YOLIN Traiteur</em> met un point d'honneur à la satisfaction des
          exigences de sa clientèle
        </p>
        <p>
          <em>YOLIN Trateur</em> compte se hisser parmi les meilleures sociétés
          de restauration en Côte d'Ivoire, et en Afrique en travaillant avec
          une équipe significative d'employés permanents (maître d'hôtel,
          serveurs, aides cusiniers etc.) et un personnel technique expérimenté,
          émérité et qualité. Des collaborateurs capables de préparer et servir
          de manière adéquate des repas adaptés aux régimes alimentaires (Halal
          hypocaloriques ect.).
        </p>
      </div>
    </div>
  </section>
  <app-footer-style-two></app-footer-style-two>
</div>
