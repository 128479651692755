<div class="formulaire-item">

    <h3 style="text-align: center;">
        <b>Rechargement libre</b>
    </h3>
    <div class="container">
        <form [formGroup]="rechargerForm">
            <div class="checkForm">

                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <input type="number" name="montantrechargement"
                                [formControl]="rechargerForm.controls['montantrechargement']" class="form-control"
                                required placeholder="Montant">
                        </div>


                        <div class="col-lg-12">
                            <ng-container *ngIf="items[0]?.abonnementprecompte; else elseHintTemplate">
                                MONTANT MINIMUM 75000 FRS
                            </ng-container>
                            <ng-template #elseHintTemplate>
                                MONTANT MINIMUM 35000 FRS
                            </ng-template>
                        </div>





                    </div>





                </div>
                <div class="row">
                    <div class="col-lg-4">

                    </div>

                    <div class="col-lg-4">

                        <input type="radio" (change)="radioTypeAbnChange('mobilemoney')"
                            [formControl]="rechargerForm.controls['typePay']" value="mobilemoney">
                        &nbsp;
                        <b style="color: black;">Mobile money</b>
                    </div>


                    <div class="col-lg-4">
                        <input type="radio" (change)="radioTypeAbnChange('carteabonnement')"
                            [formControl]="rechargerForm.controls['typePay']" value="carteabonnement"> &nbsp;
                        <b style="color: black;">Compte RestauTresor</b>
                    </div>

                </div>



                <div class="row" *ngIf="displayPassRechargement">
                    <div class="col-lg-4">

                    </div>

                    <div class="col-lg-4">

                        <div class="form-group">
                            <input type="password" name="passcarte"
                                [formControl]="passcarteRechargerForm.controls['passcarte']" class="form-control"
                                required placeholder="Code carte" maxlength="4">
                            <div *ngIf="passcarteRechargerForm.controls.passcarte.errors?.number">
                                <b style="color: red;">Chiffres uniquement</b>
                            </div>
                            <b *ngIf="!passcarteRechargerForm.controls.passcarte.errors?.number">Obligatoire</b>
                        </div>

                    </div>


                    <div class="col-lg-4">

                    </div>

                </div>

                <div class="row">
                    <div class="col-lg-4">
                    </div>
                    <div class="col-lg-4">

                        <ng-container *ngIf="rechargerForm.invalid">
                            <div class="banner-btn-wrap">
                                <a class="cmn-btn"
                                    style="color: white; font-weight: bold; background-color: #efefef;">VALIDER</a>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="rechargerForm.valid">
                            <div class="banner-btn-wrap">
                                <a (click)="findlastbyrefclient()" class="cmn-btn"
                                    style="color: white; font-weight: bold; cursor: pointer;">VALIDER</a>
                            </div>
                        </ng-container>


                    </div>
                    <div class="col-lg-4">
                    </div>
                </div>
            </div>
        </form>


    </div>
    <hr>

    <h3 *ngIf="items && client?.clientprecompte" style="text-align: center;">
        <b>Decouvert</b>
    </h3>

    <div class="container" *ngIf="items && client?.clientprecompte">

        <div class="row">
            <ng-container *ngFor="let item of decouverts">
                <div class="col-lg-4">
                    <input type="radio" name="decouvert" [value]="item.decouvertvaleur"
                        [formControl]="decouvertForm.controls['decouvert']">
                    &nbsp;
                    <b style="color: black;">{{item.decouvertvaleur}}</b>
                </div>
            </ng-container>



            <ng-container *ngIf="decouverts.length>0">
                <br>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4">

                        </div>

                        <div class="col-lg-4">
                            <ng-container *ngIf="decouvertForm.invalid">
                                <div class="banner-btn-wrap">
                                    <a class="cmn-btn"
                                        style="color: white; font-weight: bold; background-color: #efefef;">VALIDER</a>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="decouvertForm.valid">
                                <div class="banner-btn-wrap">
                                    <a (click)="findlastbyrefclientDecouvert()" class="cmn-btn"
                                        style="color: white; font-weight: bold; cursor: pointer;">VALIDER</a>
                                </div>
                            </ng-container>
                        </div>

                        <div class="col-lg-4">

                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

</div>