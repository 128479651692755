import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notif-option',
  templateUrl: './notif-option.component.html',
  styleUrls: ['./notif-option.component.scss']
})
export class NotifOptionComponent {

  dialogMessage: String;

  constructor(private message: String, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.dialogMessage = this.message;
  }

  closeNotif(value : any) {
    this.modalService.dismissAll(value);
  }

}
