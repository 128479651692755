<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area">
    <div class="container">
        <div class="row">

            <div class="col-lg-3">

            </div>


            <div class="col-lg-6">
                <div class="page-title-item" style="text-align: center;">
                    <h2>COMMANDER</h2>
                </div>
            </div>

            <div class="col-lg-3">

            </div>
        </div>
    </div>
</div>

<section class="story-area pt-100 pb-70" style="background-color: white; padding-top: 100px;">

    <div class="container">

        <div class="row">
            <div class="col-sm-4 col-lg-4">
                <h3 style="font-weight: bold;">MENU DU RESTAURANT</h3>
            </div>

            <div class="col-sm-8 col-lg-8">
                <div class="sorting-menu-carte">
                    <ul style="text-align: left">
                        <li (click)="goToDay(1)" [ngClass]="{'li-selected': daySelected === 1}">LUNDI</li>
                        <li (click)="goToDay(2)" [ngClass]="{'li-selected': daySelected === 2}">MARDI</li>
                        <li (click)="goToDay(3)" [ngClass]="{'li-selected': daySelected === 3}">MERCREDI</li>
                        <li (click)="goToDay(4)" [ngClass]="{'li-selected': daySelected === 4}">JEUDI</li>
                        <li (click)="goToDay(5)" [ngClass]="{'li-selected': daySelected === 5}">VENDREDI</li>
                    </ul>
                </div>

            </div>


        </div>


        <div class="row">
            <div class="col-sm-2 col-lg-2">
            </div>

            <div class="col-sm-10 col-lg-10">

                <div class="sorting-menu">
                    <ul style="text-align: left">


                        <li [ngClass]="{'li-selected': c.categoriename === categorieSelected}"
                            (click)="onLinkClick( c.categoriename)" *ngFor="let c of cats">
                            {{c.categoriename}}
                        </li>


                    </ul>
                </div>


            </div>
        </div>


    </div>
</section>

<section class="services-area" style="padding-top: 0px; background-color: white; padding-bottom: 100px;">


    <div *ngIf="foodSize==0" class="container">
        <div class="level2" style="color: #f07409; font-size: 18px; font-weight: 900; text-align: center;">
            Aucun produit dans cette catégorie
        </div>
    </div>

    <div *ngIf="foodSize==1" class="container">
        <owl-carousel-o [options]="foodSliderConfig">
            <ng-template carouselSlide>
                <div class="step1">

                    <div class="level1">

                        <img [src]="prods[0].fooddownloadimageurl" alt="">


                        <p>{{prods[0].foodname}}
                            <br>
                            {{prods[0].foodprice}} FRS
                        </p>


                    </div>



                    <ng-container
                        *ngIf="checkHour(prods[0].heurecommande) || (prods[0].foodstockdisponible <= prods[0].foodstockalerte) ; then checkOut ; else checkIn ">
                    </ng-container>

                    <ng-template #checkIn>
                        <div (click)="openModal(prods[0])" class="level2">
                            Ajouter au panier

                        </div>
                    </ng-template>
                    <ng-template #checkOut>
                        <div class="level2">
                            RDV à RestauTresor
                        </div>
                    </ng-template>



                </div>

            </ng-template>

            <ng-template carouselSlide>
                <div class="step1">
                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="step1">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="step1">
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>

    <div *ngIf="foodSize==2" class="container">
        <owl-carousel-o [options]="foodSliderConfig">
            <ng-template carouselSlide>
                <div class="step1">

                    <div class="level1">

                        <img [src]="prods[0].fooddownloadimageurl" alt="">


                        <p>{{prods[0].foodname}}
                            <br>
                            {{prods[0].foodprice}} FRS
                        </p>


                    </div>



                    <ng-container
                        *ngIf="checkHour(prods[0].heurecommande) || (prods[0].foodstockdisponible <= prods[0].foodstockalerte); then checkOut ; else checkIn ">
                    </ng-container>

                    <ng-template #checkIn>
                        <div (click)="openModal(prods[0])" class="level2">
                            Ajouter au panier

                        </div>
                    </ng-template>
                    <ng-template #checkOut>
                        <div class="level2">
                            RDV à RestauTresor
                        </div>
                    </ng-template>



                </div>

            </ng-template>

            <ng-template carouselSlide>
                <div class="step1">

                    <div class="level1">

                        <img [src]="prods[1].fooddownloadimageurl" alt="">


                        <p>{{prods[1].foodname}}
                            <br>
                            {{prods[1].foodprice}} FRS
                        </p>


                    </div>



                    <ng-container
                        *ngIf="checkHour(prods[1].heurecommande) || (prods[1].foodstockdisponible <= prods[1].foodstockalerte) ; then checkOut ; else checkIn ">
                    </ng-container>

                    <ng-template #checkIn>
                        <div (click)="openModal(prods[1])" class="level2">
                            Ajouter au panier

                        </div>
                    </ng-template>
                    <ng-template #checkOut>
                        <div class="level2">
                            RDV à RestauTresor
                        </div>
                    </ng-template>

                </div>

            </ng-template>

            <ng-template carouselSlide>
                <div class="step1">
                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="step1">
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>

    <div *ngIf="foodSize==3" class="container">
        <owl-carousel-o [options]="foodSliderConfig">
            <ng-template carouselSlide>
                <div class="step1">

                    <div class="level1">

                        <img [src]="prods[0].fooddownloadimageurl" alt="">


                        <p>{{prods[0].foodname}}
                            <br>
                            {{prods[0].foodprice}} FRS
                        </p>


                    </div>



                    <ng-container
                        *ngIf="checkHour(prods[0].heurecommande) || (prods[0].foodstockdisponible <= prods[0].foodstockalerte) ; then checkOut ; else checkIn ">
                    </ng-container>

                    <ng-template #checkIn>
                        <div (click)="openModal(prods[0])" class="level2">
                            Ajouter au panier

                        </div>
                    </ng-template>
                    <ng-template #checkOut>
                        <div class="level2">
                            RDV à RestauTresor
                        </div>
                    </ng-template>



                </div>

            </ng-template>

            <ng-template carouselSlide>
                <div class="step1">

                    <div class="level1">

                        <img [src]="prods[1].fooddownloadimageurl" alt="">


                        <p>{{prods[1].foodname}}
                            <br>
                            {{prods[1].foodprice}} FRS
                        </p>


                    </div>



                    <ng-container
                        *ngIf="checkHour(prods[1].heurecommande) || (prods[1].foodstockdisponible <= prods[1].foodstockalerte) ; then checkOut ; else checkIn ">
                    </ng-container>

                    <ng-template #checkIn>
                        <div (click)="openModal(prods[1])" class="level2">
                            Ajouter au panier

                        </div>
                    </ng-template>
                    <ng-template #checkOut>
                        <div class="level2">
                            RDV à RestauTresor
                        </div>
                    </ng-template>

                </div>

            </ng-template>

            <ng-template carouselSlide>
                <div class="step1">

                    <div class="level1">

                        <img [src]="prods[2].fooddownloadimageurl" alt="">


                        <p>{{prods[2].foodname}}
                            <br>
                            {{prods[2].foodprice}} FRS
                        </p>


                    </div>



                    <ng-container
                        *ngIf="checkHour(prods[2].heurecommande) || (prods[2].foodstockdisponible <= prods[2].foodstockalerte); then checkOut ; else checkIn ">
                    </ng-container>

                    <ng-template #checkIn>
                        <div (click)="openModal(prods[2])" class="level2">
                            Ajouter au panier
                        </div>
                    </ng-template>
                    <ng-template #checkOut>
                        <div class="level2">
                            RDV à RestauTresor
                        </div>
                    </ng-template>

                </div>

            </ng-template>

            <ng-template carouselSlide>
                <div class="step1">
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>

    <div *ngIf="foodSize>=4" class="container">

        <owl-carousel-o [options]="foodSliderConfig">
            <ng-container *ngFor="let f of prods ">
                <ng-template carouselSlide>
                    <div class="step1">

                        <div class="level1">

                            <img [src]="f.fooddownloadimageurl" alt="">


                            <p>{{f.foodname}}
                                <br>
                                {{f.foodprice}} FRS
                            </p>


                        </div>



                        <ng-container
                            *ngIf="checkHour(f.heurecommande) || (f.foodstockdisponible <= f.foodstockalerte) ; then checkOut ; else checkIn ">
                        </ng-container>

                        <ng-template #checkIn>
                            <div (click)="openModal(f)" class="level2">
                                Ajouter au panier

                            </div>
                        </ng-template>
                        <ng-template #checkOut>
                            <div class="level2">
                                RDV à RestauTresor
                            </div>
                        </ng-template>



                    </div>

                </ng-template>


            </ng-container>

        </owl-carousel-o>

    </div>
</section>


<div class="feature-area pt-100 pb-70" style="background-color: white; ">

    <div class="container">

        <div class="row">
            <div class="col-sm-4 col-lg-4">
                <h3 style="font-weight: bold;">GALERIE</h3>
            </div>

            <div class="col-sm-8 col-lg-8">

            </div>

        </div>


        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-lg-3">
                    <div class="feature-item">
                        <img src="assets/img/food/1.jpg" alt="Feature">


                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="feature-item">
                        <img src="assets/img/food/2.jpg" alt="Feature">


                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="feature-item">
                        <img src="assets/img/food/3.jpg" alt="Feature">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="feature-item">
                        <img src="assets/img/food/4.jpg" alt="Feature">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="feature-item">
                        <img src="assets/img/food/1.jpg" alt="Feature">


                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="feature-item">
                        <img src="assets/img/food/2.jpg" alt="Feature">


                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="feature-item">
                        <img src="assets/img/food/3.jpg" alt="Feature">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-3">
                    <div class="feature-item">
                        <img src="assets/img/food/4.jpg" alt="Feature">
                    </div>
                </div>


            </div>
        </div>
    </div>
    <app-footer-style-two></app-footer-style-two>