import { Component, Injector } from '@angular/core';
import { ClientAuth, Client, Address } from '../models/models';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from '../parameter.service';
import { NotifDialogComponent } from '../notif-dialog/notif-dialog.component';
import { AddressDialogComponent } from './address-dialog/address-dialog.component';
import { AddressDeleteDialogComponent } from './address-delete-dialog/address-delete-dialog.component';
import { AddressSelectedDialogComponent } from './address-selected-dialog/address-selected-dialog.component';

@Component({
  selector: 'app-adresses',
  templateUrl: './adresses.component.html',
  styleUrls: ['./adresses.component.scss']
})
export class AdressesComponent {

  public closeResult: string;

  countries = [];
  currentUser: ClientAuth;
  client: Client;
  adress: Address[];



  constructor(
    private route: Router, private parameterService: ParameterService, private modalService: NgbModal) { }


  ngOnInit() {

    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      this.currentUser = JSON.parse(localStorage.getItem('YolinFrontCurentuser'));

    } else {
      this.currentUser = JSON.parse(sessionStorage.getItem('YolinFrontCurentuser'));

    }
    // Verifier un client est en ligne
    if (!this.currentUser) {
      this.route.navigate(['/']);  //Rediriger l'utilisateur à l'accueil
    }

    this.client = this.currentUser.client;

    this.getAdressByClientId(this.client.clientid);

  }


  /**
* Obtenir adresse par clientid
*/
  getAdressByClientId(value: string) {
    this.parameterService.getAllWithKey('adress/client/' + value).subscribe(data => {
      switch (data.status) {
        case 200:
          this.adress = data.body;
          break;
        case 204:
          this.openNotifModal('Aucune adresse disponible');
          this.adress = [];
          break;
        default:

          break;
      }
    },
      error => {

      });
  }

  async openDeleteAdresseModal(message) {
    this.modalService.open(AddressDeleteDialogComponent,
      {
        size: 'xs',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: Address, useValue: message
        }])
      }).result.then((result) => {

        `Result ${result}`

      }, (reason) => {
        this.closeResult = `${this.getDismissReason(reason)}`;
        if (this.closeResult) {
          this.getAdressByClientId(this.client.clientid);
        }
      });
  }

  async openSelectedAdresseModal(message) {
    this.modalService.open(AddressSelectedDialogComponent
      ,
      {
        size: 'xs',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: Address, useValue: message
        }])
      }).result.then((result) => {

        `Result ${result}`

      }, (reason) => {
        this.closeResult = `${this.getDismissReason(reason)}`;
        if (this.closeResult) {
          this.getAdressByClientId(this.client.clientid);
        }
      });
  }
  async openNotifModal(message) {
    this.modalService.open(NotifDialogComponent,
      {
        size: 'xs',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: message
        }])
      }).result.then((result) => {

        `Result ${result}`
        console.log(result);

      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }


  async openModal() {
    console.log();

    this.modalService.open(AddressDialogComponent,
      {
        size: 'lg',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: Address, useValue: {}
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        this.closeResult = `${this.getDismissReason(reason)}`;
        if (this.closeResult) {
          this.getAdressByClientId(this.client.clientid);
        }
      });
  }


  async openUpdateModal(address) {
    this.modalService.open(AddressDialogComponent,
      {
        size: 'lg',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: Address, useValue: address
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        this.closeResult = `${this.getDismissReason(reason)}`;
        if (this.closeResult) {
          this.getAdressByClientId(this.client.clientid);
        }
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `${reason}`;
    }
  }


}
