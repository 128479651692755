import { Component, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, Validators } from "@angular/forms";

import { ParameterService } from "../parameter.service";
import { NotifDialogComponent } from "../notif-dialog/notif-dialog.component";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent {
  emailOrPassword = this.fb.control("", {
    validators: [Validators.required, Validators.minLength(3)],
  });

  apiError: string | null = null;

  isSubmitting = false;

  constructor(
    private parameterService: ParameterService,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) {}

  handleSubmit() {
    this.isSubmitting = true;

    this.parameterService
      .postEmailToResetPassword(this.emailOrPassword.value)
      .subscribe(
        (data) => {
          switch (data.status) {
            case 200: {
              this.closeModale();

              this.openNotifModal(
                "Veuillez consulter votre email pour finaliser la réinitialisation de votre mot de passe"
              );

              break;
            }
            default: {
              this.apiError = "Une erreur s'est produite, veuillez reessayer";
              this.isSubmitting = false;
              break;
            }
          }
        },
        (error) => {
          this.apiError = "Une erreur s'est produite, veuillez reessayer";
          this.isSubmitting = false;
        }
      );
  }

  async openNotifModal(message) {
    this.modalService.open(NotifDialogComponent, {
      size: "xs",
      ariaLabelledBy: "Cart-Modal",
      centered: true,
      windowClass: "theme-modal cart-modal CartModal",
      injector: Injector.create([
        {
          provide: String,
          useValue: message,
        },
      ]),
    });
  }

  closeModale() {
    this.modalService.dismissAll();
  }
}
