import { Component, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Client, ClientAuth } from '../../models/models';
import { ParameterService } from '../../parameter.service';
import { CustomValidators } from 'ng2-validation';
import { emailValidator, matchingPasswords, samePasswords } from '../../models/app-validators';
import { NotifDialogComponent } from '../../notif-dialog/notif-dialog.component';

@Component({
  selector: 'app-updatepasswd',
  templateUrl: './updatepasswd.component.html',
  styleUrls: ['./updatepasswd.component.scss']
})
export class UpdatepasswdComponent {
  passwordForm: FormGroup;
  currentUser: ClientAuth;
  passwordFormDisabled: boolean;
  regex = "((?=.*\\d)(?=.*[A-Z]).{6,20})";
  public isSubmitting = false;

  constructor(
    public formBuilder: FormBuilder,
    private parameterService: ParameterService,
    private modalService: NgbModal,
    private route: Router
  ) {}

  ngOnInit() {
    //Check RememberMe
    if (
      localStorage.getItem("YolinRememberMe") &&
      JSON.parse(localStorage.getItem("YolinRememberMe"))
    ) {
      this.currentUser = JSON.parse(
        localStorage.getItem("YolinFrontCurentuser")
      );
    } else {
      this.currentUser = JSON.parse(
        sessionStorage.getItem("YolinFrontCurentuser")
      );
    }
    // Verifier un client est en ligne
    if (!this.currentUser) {
      this.route.navigate(["/"]); //Rediriger l'utilisateur à l'accueil
    }

    // console.log(this.currentUser.client.clienttype.clienttypename.toLowerCase());

    this.passwordFormDisabled = true;

    this.initializeAll();
    // Desactivé les controles à l'initialisation
  }

  setIsEnable(isEnable: boolean) {
    if (isEnable) {
      this.passwordForm.controls["oldpassword"].enable();
      this.passwordForm.controls["newpassword"].enable();
      this.passwordForm.controls["confirmpassword"].enable();
      this.passwordFormDisabled = false;
    } else {
      this.passwordForm.controls["oldpassword"].disable();
      this.passwordForm.controls["newpassword"].disable();
      this.passwordForm.controls["confirmpassword"].disable();
      this.passwordFormDisabled = true;
    }

    console.log(this.passwordFormDisabled);
  }

  initializeAll() {
    let cl: ClientAuth = new ClientAuth();
    //Check RememberMe
    if (
      localStorage.getItem("YolinRememberMe") &&
      JSON.parse(localStorage.getItem("YolinRememberMe"))
    ) {
      cl = JSON.parse(localStorage.getItem("YolinFrontCurentuser"));
    } else {
      cl = JSON.parse(sessionStorage.getItem("YolinFrontCurentuser"));
    }

    if (cl == null) {
      this.route.navigate(["/"]);
    }
    let u: Client = new Client();
    u = cl.client;

    this.passwordForm = this.formBuilder.group(
      {
        oldpassword: [
          { value: "", disabled: true },
          Validators.compose([Validators.required]),
        ],
        newpassword: [
          { value: "", disabled: true },
          [
            Validators.required,
            Validators.minLength(6),
            ,
            Validators.pattern(this.regex),
          ],
        ],
        confirmpassword: [
          { value: "", disabled: true },
          [Validators.required, Validators.minLength(6)],
        ],
      },
      {
        validator: [
          matchingPasswords("newpassword", "confirmpassword"),
          samePasswords("oldpassword", "newpassword"),
        ],
      }
    );

    this.passwordFormDisabled = true;
  }

  onSubmit() {
    this.isSubmitting = true;

    let userUpdate: any = {};
    userUpdate.newPassword = this.passwordForm.get('newpassword').value;
    userUpdate.oldpassword = this.passwordForm.get('oldpassword').value;
    userUpdate.userId = this.currentUser.client.clientid;

    this.parameterService.updatePassword(userUpdate).subscribe(
      (data) => {
        switch (data.status) {
          case 200: {
            this.isSubmitting = false;
            this.passwordFormDisabled = true;

            this.passwordForm = this.formBuilder.group(
              {
                oldpassword: [
                  { value: "", disabled: true },
                  Validators.compose([Validators.required]),
                ],
                newpassword: [
                  { value: "", disabled: true },
                  [
                    Validators.required,
                    Validators.minLength(6),
                    ,
                    Validators.pattern(this.regex),
                  ],
                ],
                confirmpassword: [
                  { value: "", disabled: true },
                  [Validators.required, Validators.minLength(6)],
                ],
              },
              {
                validator: [
                  matchingPasswords("newpassword", "confirmpassword"),
                  samePasswords("oldpassword", "newpassword"),
                ],
              }
            );

            this.openNotifModal(
              "Notification', 'Mise a jour effecuée avec success"
            );
            break;
          }
          default: {
            console.log(data);
            this.isSubmitting = false;
            this.openNotifModal(
              "Une erreur s'est produite, veuillez reessayer"
            );
            break;
          }
        }
      },
      (error) => {
        console.log(error);
        this.isSubmitting = false;
        this.openNotifModal("Notification', 'Ancien mot de passe incorrect");
      }
    );
  }

  async openNotifModal(message) {
    this.modalService.open(NotifDialogComponent, {
      size: "xs",
      ariaLabelledBy: "Cart-Modal",
      centered: true,
      windowClass: "theme-modal cart-modal CartModal",
      injector: Injector.create([
        {
          provide: String,
          useValue: message,
        },
      ]),
    });
  }


}
