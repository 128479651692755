import { Component, Injector, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Client, RenewUserPasswordDto } from '../../models/models';
import { ParameterService } from '../../parameter.service';
import { CustomValidators } from 'ng2-validation';
import { matchingPasswords } from '../../models/app-validators';
import { NotifDialogComponent } from '../../notif-dialog/notif-dialog.component';

@Component({
  selector: 'app-secretcode',
  templateUrl: './secretcode.component.html',
  styleUrls: ['./secretcode.component.scss']
})
export class SecretcodeComponent {

  @Input() client: Client;


  modifpassForm: FormGroup;
  public closeResult: string;
  typepassword: string = 'password';

  constructor(public formBuilder: FormBuilder, private parameterService: ParameterService,
    private modalService: NgbModal, private route: Router, private fbRecharge: FormBuilder
  ) { }

  ngOnInit() {
    // Form rechargement
    // Form modif de mot de passe
    this.modifpassForm = this.formBuilder.group({
      oldpassword: ['', [CustomValidators.number, Validators.required]],
      newpassword: ['', [CustomValidators.number, Validators.required]],
      confirmpassword: ['', [CustomValidators.number, Validators.required]],
    }, { validator: matchingPasswords('newpassword', 'confirmpassword') });

  }


  modificationPassCarte(value) {
    let renewUserPasswordDto: RenewUserPasswordDto = new RenewUserPasswordDto();
    renewUserPasswordDto.oldpassword = value.oldpassword;
    renewUserPasswordDto.newPassword = value.newpassword;
    // renewUserPasswordDto.userId = this.carteabonnement.carteabonnementcodereference;
    renewUserPasswordDto.userId = this.client.clientid;


    // this.accountservice.renewpasswordCarte(renewUserPasswordDto).subscribe(data => {
    this.parameterService.postWithKey("carte/renewpassword", renewUserPasswordDto).subscribe(data => {

      switch (data.status) {
        case 200: {
          this.openModal('Mise à jour reussie');

          /* this.snackbar.open('Mise à jour reussie', '×',
          { panelClass: 'success', verticalPosition: 'top', duration: 8000 }); */
          this.modifpassForm.reset();
          break;
        }
        case 204: {
          this.openModal('Désolé impossible de retrouver le client, reessayez ou contactez l\'administrateur');

          /* this.snackbar.open('Désolé la carte n\'existe pas', '×',
          { panelClass: 'success', verticalPosition: 'top', duration: 5000 }); */
          break;
        }
        default: {
          break;
        }

      }
    },
      error => {

        switch (error.status) {


          case 406: {
            this.openModal('Ancien mot de passe incorrect');

            /* this.snackbar.open('Ancien mot de passe incorrect', 'OK', {
              duration: 5000
            }); */
            break;
          }


          default: {
            this.openModal('Une erreur est survenue');

            /* this.snackbar.open('Une erreur est survenue ', 'OK', {
              duration: 5000
            }); */
          }
        }
      });

  }


  changePassword() {
    if (this.typepassword === 'password') {
      this.typepassword = 'text';
    } else if (this.typepassword === 'text') {
      this.typepassword = 'password';
    }
  }



  async openModal(product) {
    this.modalService.open(NotifDialogComponent,

      {
        size: 'lg',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: product
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
