<ng-container>



    <section class="cart-area">
        <div class="container" style="padding-left: 0px; margin-top: 5px;">
            <div class="cart-wrap-order">

                <h4 style="text-align: center; margin: auto;">
                    Choix de votre categorie
                </h4>

                <br>
                <div class="sorting-menu-categorie">
                    <ul style="text-align: center">

                        <li [ngClass]="{'li-selected': categoriecorpo.categoriecorpoid === categorieSelected}"
                            *ngFor="let categoriecorpo of categoriecorpos" (click)="selectedCategorie(categoriecorpo)">
                            {{categoriecorpo.categoriecorpolibelle}}
                        </li>


                    </ul>
                </div>

            </div>
        </div>
    </section>



    <section class="menu-area" *ngIf="displayFormule">
        <div class="container" style="background-color: white;">
            <div class="section-title">
                <h4>Abonnement precompté</h4>
            </div>

            <div class="row">
                <div class="col-sm-6 col-lg-4" *ngFor="let formuleprecompte of formulesprecompte">
                    <div class="menu-item" style="min-height: 350px; height: auto;">
                        <h3>{{formuleprecompte?.formulenom}}</h3>
                        <hr>
                        <p style="font-size:20px!important">{{formuleprecompte?.formuleduree}} jours</p>
                        <hr>
                        <b style="font-size:20px!important">{{formuleprecompte?.formulemontant}} FCFA</b>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="menu-area" *ngIf="displayFormule">
        <div class="container" style="background-color: white;">
            <div class="section-title">
                <h4>Abonnement non precompté</h4>
            </div>

            <div class="row">
                <div class="col-sm-6 col-lg-4" *ngFor="let formuleprecompte of formulesnonprecompte">
                    <div class="menu-item" style="min-height: 350px; height: auto;">
                        <h3>{{formuleprecompte?.formulenom}}</h3>
                        <hr>
                        <p style="font-size:20px!important">{{formuleprecompte?.formuleduree}} jours</p>
                        <hr>
                        <b style="font-size:20px!important">{{formuleprecompte?.formulemontant}} FCFA</b>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </section>



</ng-container>