import { Component, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Client, ClientAuth, passwordupdate } from '../models/models';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from '../parameter.service';
import { CustomValidators } from 'ng2-validation';
import { emailValidator, matchingPasswords, samePasswords } from '../models/app-validators';
import { NotifDialogComponent } from '../notif-dialog/notif-dialog.component';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent {

  infoForm: FormGroup;
  passwordForm: FormGroup;
  newsletterForm: FormGroup;
  currentUser: ClientAuth;
  infoFormDisabled: boolean;
  passwordFormDisabled: boolean;


  regex = "((?=.*\\d)(?=.*[A-Z]).{6,20})";

  hide = true;
  public closeResult: string;

  constructor(public formBuilder: FormBuilder, private parameterService: ParameterService,
    private modalService: NgbModal, private route: Router
  ) { }

  ngOnInit() {



    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      this.currentUser = JSON.parse(localStorage.getItem('YolinFrontCurentuser'));

    } else {
      this.currentUser = JSON.parse(sessionStorage.getItem('YolinFrontCurentuser'));

    }
    // Verifier un client est en ligne
    if (!this.currentUser) {
      this.route.navigate(['/']);  //Rediriger l'utilisateur à l'accueil
    }

    // console.log(this.currentUser.client.clienttype.clienttypename.toLowerCase());

    this.infoFormDisabled = true;
    this.passwordFormDisabled = true;

    this.initializeAll();
    // Desactivé les controles à l'initialisation

  }




  initializeAll() {
    let cl: ClientAuth = new ClientAuth();
    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      cl = JSON.parse(localStorage.getItem('YolinFrontCurentuser'));

    } else {
      cl = JSON.parse(sessionStorage.getItem('YolinFrontCurentuser'));

    }

    if (cl == null) {
      this.route.navigate(['/']);
    }
    let u: Client = new Client();
    u = cl.client;

    this.infoForm = this.formBuilder.group({
      'clientfirstname': [{ value: u.clientfirstname, disabled: true }, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(64)])],
      'clientlastname': [{ value: u.clientlastname, disabled: true }, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(64)])],
      'clientemail': [{ value: u.clientemail, disabled: true }, Validators.compose([Validators.required, emailValidator])],
      clienttelephone: [{ value: u.clienttelephone.substring(3, 13), disabled: true }, [Validators.required, Validators.minLength(8), CustomValidators.rangeLength([8, 10]), CustomValidators.number]],
      'clientcivilite': [{ value: u.clientcivilite, disabled: true }, Validators.required]
      /* },{validator: [ startWith('clienttelephone') ]*/
    });

    this.passwordForm = this.formBuilder.group({
      'oldpassword': [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      'newpassword': [{ value: '', disabled: true }, [Validators.required, Validators.minLength(6), , Validators.pattern(this.regex)]],
      'confirmpassword': [{ value: '', disabled: true }, [Validators.required, Validators.minLength(6)]]
    }, {
      validator: [matchingPasswords('newpassword', 'confirmpassword'),
      samePasswords('oldpassword', 'newpassword')]
    });

    this.newsletterForm = this.formBuilder.group({
      'clientnewsletter': [u.clientnewsletter]
    });

    this.infoFormDisabled = true;
    this.passwordFormDisabled = true;


  }


  setIsEnable(value) {
    // infoform
    if (value == 'infoForm') {

      this.infoForm.controls['clientfirstname'].enable();
      this.infoForm.controls['clientlastname'].enable();
      this.infoForm.controls['clientcivilite'].enable();
      this.infoForm.controls['clienttelephone'].enable();
      this.infoForm.controls['clientemail'].enable();
      this.infoFormDisabled = false;

    } else {
      this.passwordForm.controls['oldpassword'].enable();
      this.passwordForm.controls['newpassword'].enable();
      this.passwordForm.controls['confirmpassword'].enable();
      this.passwordFormDisabled = false;

    }

  }


  public onInfoFormSubmit(values): void {


    let v = new ClientAuth();
    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      v = JSON.parse(localStorage.getItem('YolinFrontCurentuser'));

    } else {
      v = JSON.parse(sessionStorage.getItem('YolinFrontCurentuser'));

    }

    let u: Client = new Client();
    u = v.client;



    if (this.infoForm.valid && u) {

      let clientuser: Client = new Client();

      clientuser = this.infoForm.value;

      clientuser.clientid = u.clientid;
      //clientuser.clientemail = u.clientemail;
      clientuser.clienttelephone = '225' + this.infoForm.get('clienttelephone').value;
      clientuser.clientpassword = u.clientpassword;
      clientuser.clientcivilite = values.clientcivilite;
      clientuser.clienttype = u.clienttype;
      clientuser.clientTypeDto = 'client';
      clientuser.clientisdeleted = u.clientisdeleted;
      clientuser.addresses = u.addresses;



      this.parameterService.updateWithKey("parameter/client", clientuser).subscribe(data => {

        switch (data.status) {
          case 201: {
            // Reinitialiser le localstorage
            const objToStore: ClientAuth = new ClientAuth();
            objToStore.client = data.body;
            objToStore.token = this.currentUser.token;

            //Check RememberMe
            if (localStorage.getItem('YolinRememberMe')
              && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
              localStorage.removeItem('YolinFrontCurentuser');
              localStorage.setItem('YolinFrontCurentuser', JSON.stringify(objToStore));

            } else {
              sessionStorage.removeItem('YolinFrontCurentuser');
              sessionStorage.setItem('YolinFrontCurentuser', JSON.stringify(objToStore));
            }

            this.passwordForm.reset();
            this.initializeAll();

            this.openNotifModal('Mise a jour effecuée avec success');

            /* this.snackbar.open('Mise a jour effecuée avec success', 'OK',
              { panelClass: 'success', verticalPosition: 'top', duration: 10000 }); */
            break;
          }

        }
      },
        error => {

          switch (error.status) {
            case 409: {
              this.openNotifModal('Email / telephone existe deja');

              /* this.snackbar.open('Email / telephone existe deja', 'OK',
              { panelClass: 'success', verticalPosition: 'top', duration: 6000 }); */
              break;
            }
            default: {
              this.openNotifModal('Une erreur est survenue');

              /* this.snackbar.open('Une erreur est survenue ', 'OK', {
                duration: 10000
              }); */
              break;
            }
          }
        });
    }
  }


  public onPasswordFormSubmit(): void {
    console.log("I GUEST ----------->");
  }

  async openNotifModal(message) {
    this.modalService.open(NotifDialogComponent,
      {
        size: 'xs',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: message
        }])
      }).result.then((result) => {

        `Result ${result}`
        console.log(result);

      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `${reason}`;
    }
  }
}
