import { Component } from '@angular/core';
import { ClientAuth, Client, Formule } from '../../models/models';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from '../../parameter.service';

@Component({
  selector: 'app-gest-abn',
  templateUrl: './gest-abn.component.html',
  styleUrls: ['./gest-abn.component.scss']
})
export class GestAbnComponent {

  itemSelected: any = 0;
  currentUser: ClientAuth;
  client: Client;
  items: any;
  allFormules: any[];
  itemsrange: number[] = [];
  indexPage: number = 1;

  public itemsize: number = 0;
  public itemsfront: any[] = [];
  public itemsizefront: number = 0;


  page = 1;
  pageSize = 10;

  constructor(public formBuilder: FormBuilder, private parameterService: ParameterService,
    private modalService: NgbModal, private route: Router
  ) { }


  ngOnInit() {

    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      this.currentUser = JSON.parse(localStorage.getItem('YolinFrontCurentuser'));

    } else {
      this.currentUser = JSON.parse(sessionStorage.getItem('YolinFrontCurentuser'));

    }
    // Verifier un client est en ligne
    if (!this.currentUser) {
      this.route.navigate(['/']);  //Rediriger l'utilisateur à l'accueil
    }

    this.client = this.currentUser.client;
    this.getallhistoriqueabnbyclient();
    this.getFormules();
  }

  selectedItems(value) {
    this.itemSelected = value;
  }



  getFormules() {
    this.parameterService.getAllWithKey("formule").subscribe(data => {

      switch (data.status) {
        case 200:
          console.log('DATA', data);
          this.allFormules = data.body;

          break;

        default:

          break;
      }
    },
      error => {
      });
  }

  getallhistoriqueabnbyclient() {
    this.parameterService.getAllWithKey('abonnement/findbyrefclient/' + this.client.clientid).subscribe(data => {
      switch (data.status) {
        case 200: {
          this.items = data.body;

          if (this.itemsize > 10) {
            let remainder: number = this.itemsize % 10;
            let res: number = (this.itemsize - remainder) / 10;

            let i: number = 0;

            if (remainder > 0) {
              i = 1;
            }

            this.itemsizefront = i + res;
            this.createRange();
            this.defineObjectByItems(1);

          } else {
            this.itemsfront = this.items;
          }
          break;
        }
        case 204: {
          this.items = [];
          break;
        }
        default: {
          break;
        }

      }
    },
      error => {

        this.items = [];


        switch (error.status) {

          default: {

          }
        }
      });
  }


  createRange() {
    this.itemsrange = [];
    for (var i = this.indexPage; i <= this.indexPage + 4; i++) {
      if (i <= this.itemsizefront) {
        this.itemsrange.push(i);
      }
    }
  }

  defineObjectByItems(value: number) {
    let fin: number = value * 10;
    let debut: number = (value * 10) - 9;

    this.itemsfront = [];
    let itemscsv: any[] = [];

    let i: number = 0;
    this.items.forEach(function (element) {
      i++;

      if ((i >= debut) && (i <= fin)) {
        itemscsv.push(element);
      }
    })

    this.itemsfront = itemscsv;



  }

}
