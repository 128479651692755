import { Component, Injector, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomValidators } from "ng2-validation";
import { Client, Recaptchav2Dto, ResetPassCarte } from "../../models/models";
import { ParameterService } from "../../parameter.service";
import { NotifDialogComponent } from "../../notif-dialog/notif-dialog.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-code",
  templateUrl: "./code.component.html",
  styleUrls: ["./code.component.scss"],
})
export class CodeComponent {
  @Input() client: Client;

  codesecretForm: FormGroup;

  public closeResult: string;

  tokenFormGroup: FormGroup;

  public captchaKey = environment.captchaKey;

  checkRecaptcha: boolean = false;

  codeOTP: boolean = false; //Variable pour afficher le champ de validation OTP

  validotpForm: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    private parameterService: ParameterService,
    private modalService: NgbModal,
    private route: Router,
    private fbRecharge: FormBuilder
  ) {}

  ngOnInit() {
    // Form rechargement
    this.codesecretForm = this.formBuilder.group({
      email: { value: this.client.clientemail, disabled: true },
      //numCarte: {value: '' || this.carteabonnement.carteabonnementcodereference, disabled: true},
      recaptchaReactive: ["", Validators.required],
      checkRecaptcha: [
        this.checkRecaptcha,
        [Validators.required, Validators.pattern("true")],
      ],
    });

    this.validotpForm = this.formBuilder.group({
      codeotp: [
        "",
        [
          Validators.required,
          Validators.min(1),
          Validators.maxLength(6),
          CustomValidators.number,
        ],
      ],
    });
  }

  codeSecretOublieClient() {
    let resetPassCarte: ResetPassCarte = new ResetPassCarte();
    //resetPassCarte.numCarte = this.codesecretForm.get('numCarte').value;
    resetPassCarte.numCarte = this.client.clientid;

    // this.accountservice.sendOTPResetCartePassword(resetPassCarte).subscribe(data => {
    this.parameterService
      .postWithKey("carte/otpresetpassword", resetPassCarte)
      .subscribe(
        (data) => {
          switch (data.status) {
            case 201: {
              this.openModal(
                "Un mail de réinitialisation du code secret a été envoyé. Rendez-vous dans votre boite mail et suivez les instructions."
              );
              this.codeOTP = true;

              break;
            }
            case 204: {
              this.openModal("La carte n'existe");
              this.codeOTP = false;

              break;
            }
            default: {
              break;
            }
          }
        },
        (error) => {
          this.codeOTP = false;

          switch (error.status) {
            case 500: {
              this.openModal("Internal Server error");

              break;
            }

            default: {
              this.openModal("Une erreur est survenue");
            }
          }
        }
      );
  }

  validOTP(value) {
    let resetPassCarte: ResetPassCarte = new ResetPassCarte();
    // resetPassCarte.numCarte = this.carteabonnement.carteabonnementcodereference;
    resetPassCarte.numCarte = this.client.clientid;
    resetPassCarte.password = value.codeotp;

    // this.accountservice.verifOTPAndResetCartePassword(resetPassCarte).subscribe(data => {

    this.parameterService
      .postWithKey("carte/resetpassword", resetPassCarte)
      .subscribe(
        (data) => {
          switch (data.status) {
            case 201: {
              this.openModal("Mise à jour reussie");
              this.validotpForm.reset();

              this.codeOTP = false; // desactiver interface de validation

              /* this.snackbar.open('Mise à jour reussie', '×',
          { panelClass: 'success', verticalPosition: 'top', duration: 8000 }); */
              break;
            }
            case 204: {
              this.openModal("Désolé la carte n'existe pas");
              this.codeOTP = false; // desactiver interface de validation

              /* this.snackbar.open('Désolé la carte n\'existe pas', '×',
          { panelClass: 'success', verticalPosition: 'top', duration: 5000 }); */
              break;
            }
            default: {
              break;
            }
          }
        },
        (error) => {
          switch (error.status) {
            case 401: {
              this.openModal("OTP incorrect");
              this.codeOTP = true; // desactiver interface de validation

              /* this.snackbar.open('Ancien mot de passe incorrect', 'OK', {
              duration: 5000
            }); */
              break;
            }

            case 406: {
              this.openModal("OTP incorrect");
              this.codeOTP = true; // desactiver interface de validation

              /* this.snackbar.open('Ancien mot de passe incorrect', 'OK', {
              duration: 5000
            }); */
              break;
            }

            default: {
              this.openModal("Une erreur est survenue");
              this.codeOTP = true; // desactiver interface de validation

              /* this.snackbar.open('Une erreur est survenue ', 'OK', {
              duration: 5000
            }); */
            }
          }
        }
      );
  }

  resolved(captchaResponse: string) {
    //this.registerForm.setErrors({ 'invalid': true }); // Empecher de passer l'etape de captcha
    // Prepare obj to send
    let recaptchav2Dto: Recaptchav2Dto = new Recaptchav2Dto();
    recaptchav2Dto.token = captchaResponse;
    // Send token to server
    //  this.homeService.validRecaptchav2(recaptchav2Dto)

    this.parameterService.postCapctha("v2", recaptchav2Dto).subscribe(
      (data) => {
        switch (data.status) {
          case 200: {
            if (data.body.success) {
              this.checkRecaptcha = true;
              // Valid form
              this.codesecretForm.patchValue({
                checkRecaptcha: true,
              });
            } else {
              this.checkRecaptcha = false;
              // Invalid form
              this.codesecretForm.patchValue({
                checkRecaptcha: false,
              });
              grecaptcha.reset(); // Rafraichir le captcha
              this.openModal("Validation Recaptcha echoué");
            }

            break;
          }

          case 204: {
            // Invalid form
            this.codesecretForm.patchValue({
              checkRecaptcha: false,
            });
            grecaptcha.reset(); // Rafraichir le captcha
            this.openModal("Erreur Recaptcha, Token null/invalide");

            break;
          }

          default: {
            break;
          }
        }
      },
      (error) => {
        console.log(error);
        // Invalid form
        this.codesecretForm.patchValue({
          checkRecaptcha: false,
        });
        grecaptcha.reset(); // Rafraichir le captcha
        this.openModal("Erreur recaptcha, reessayer SVP");
      }
    );
  }

  validationCodeOTP(value: boolean) {
    if (value) this.codeOTP = true;
    else this.codeOTP = false;
  }

  async openModal(product) {
    this.modalService
      .open(
        NotifDialogComponent,

        {
          size: "lg",
          ariaLabelledBy: "Cart-Modal",
          centered: true,
          windowClass: "theme-modal cart-modal CartModal",
          injector: Injector.create([
            {
              provide: String,
              useValue: product,
            },
          ]),
        }
      )
      .result.then(
        (result) => {
          `Result ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
