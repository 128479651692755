<div class="formulaire-item">
  <div *ngIf="!codeOTP" class="container">
    <form [formGroup]="codesecretForm">
      <div class="checkForm">
        <div class="row">
          <div class="col-lg-8">
            <div class="form-group">
              <input
                type="email"
                name="email"
                [formControl]="codesecretForm.controls['email']"
                class="form-control"
                required
                placeholder="Email"
              />
            </div>
          </div>
          <div class="col-lg-4">
            <re-captcha
              class="input-content"
              [formControl]="codesecretForm.controls['recaptchaReactive']"
              (resolved)="resolved($event)"
              [siteKey]="captchaKey"
            ></re-captcha>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4"></div>
          <div class="col-lg-4">
            <ng-container *ngIf="codesecretForm.invalid">
              <div class="banner-btn-wrap">
                <a
                  class="cmn-btn"
                  style="
                    color: white;
                    font-weight: bold;
                    background-color: #efefef;
                  "
                  >VALIDER</a
                >
              </div>
            </ng-container>
            <ng-container *ngIf="!codesecretForm.invalid">
              <div class="banner-btn-wrap">
                <a
                  (click)="codeSecretOublieClient()"
                  class="cmn-btn"
                  style="color: white; font-weight: bold; cursor: pointer"
                  >VALIDER</a
                >
              </div>
            </ng-container>

            <br />
            <br />
            <div>
              <a
                (click)="validationCodeOTP(true)"
                style="
                  color: white;
                  font-weight: bold;
                  cursor: pointer;
                  color: #f07409;
                "
                >VALIDATION OTP</a
              >
            </div>
          </div>
          <div class="col-lg-4"></div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="codeOTP" class="container">
    <form [formGroup]="validotpForm">
      <div class="checkForm">
        <div class="row">
          <div class="col-lg-3"></div>

          <div class="col-lg-6">
            <div class="form-group">
              <input
                type="text"
                name="codeotp"
                [formControl]="validotpForm.controls['codeotp']"
                class="form-control"
                required
                placeholder="Code de reinitialisation"
              />
              <b>6 chiffres uniquement</b>
              <div *ngIf="validotpForm.controls.codeotp.errors?.number">
                <b style="color: red">Chiffres uniquement</b>
              </div>
            </div>
          </div>
          <div class="col-lg-3"></div>
        </div>

        <div class="row">
          <div class="col-lg-4"></div>
          <div class="col-lg-4">
            <div class="banner-btn-wrap">
              <a
                (click)="validOTP(validotpForm.value)"
                class="cmn-btn"
                style="color: white; font-weight: bold; cursor: pointer"
                >VALIDER</a
              >
            </div>
          </div>
          <div class="col-lg-4"></div>
        </div>
      </div>
    </form>
  </div>
</div>
