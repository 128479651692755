<div class="about-content">
    <div class="section-title">
        <br>
        <h5 style="font-weight: bold;">Notification</h5>
        <br>
        <p style="color : black"> {{dialogMessage}}</p>
        <br>
        <a class="cmn-btn" (click)="closeNotif()" style="color: white; cursor: pointer;">OK</a>

    </div>
</div>