
export class ClientAuth {
    client: Client;
    token: string;
}

export class Clientlogin {
    username: string;
    password: string;
    rememberMe: boolean;
}

export class Client {
    clientemail: string;
    clientid: string;
    clientpassword: string;
    clienttelephone: string;
    clientlastname: string;
    clientfirstname: string;
    clientnewsletter: boolean;
    clientcivilite: string;
    clientmatricule: string;
    codecartevirtuelle: string;
    clientprovenance: string;
    clientsoldeinitial: number;
    clientsoldeavant: number;
    clientsoldeactuel: number;
    clientTypeDto: string = "default";
    clienttype: Clienttype;
}


export interface Address2 {
    addressid: string;
    addressisdeleted: boolean;
    addresslocation: string;
}

export class Formule {

    formuleid: string;
    formulenom: string;
    formulemontant: number;
    formulemontantdecouvert: number;
    formuleduree: number;
    formulelibelle: string;
    formuledescription: string;
    formuleisprecompte: boolean;
    formuleisdeleted: boolean;
    formuledatecreation: any;
}


export interface Client {
    addresses: Address2[];
    clientdatecreation: Date;
    clientdatelastcon: Date;
    clientemail: string;
    clientfirstname: string;
    clientid: string;
    clientisdeleted: boolean;
    clientlastname: string;
    clientpassword: string;
    clienttelephone: string;
    clienttype: Clienttype;
    clientTypeDto: string;
    clientprecompte: boolean;
    captcha: string;
    sessionid: string;
}


export interface Clienttype {
    clienttypedatecreation: Date;
    clienttypeid: string;
    clienttypeisdeleted: boolean;
    clienttypename: string;
}



export class User {
    client: Client;
    token: string;
}

export class CompteRestauLoginDto {
    clientid: string;
    carteabonnementcodesecret: string;
}

export class passwordupdate {
    oldpassword: string;
    newPassword: string;
    userId: string;
}


export class Metho {

    value: string;

    name: string;

    desc: string;

    paymentmodename: string;

    livraisonmodename: string;

}


export class Finalizeclient {
    clientemail: string;
    clientid: string;
    clienttelephone: string;
    clientlastname: string;
    clientfirstname: string;
    clientdelivrery: string;
    clientpayment: string;
    clientrefcmd: string;
    clientcommentaire: string;
    clientlieulivraisonzone: string;
    clientlieulivraisonquartier: string;
    clientprovenance: string;
    clientcommandheure: string;
    montantrecharge: string;
    codesecretCarte: string;
    delivery: any;

}


export class Foods {
    foodid: string;
    foodname: string;
    categorieName: string;
    fooddatecreation: string;
    fooddescription: string;
    foodpicture: string;

    foodprice: number;
    foodqte: number;
    foodtotal: number;
    foodtotalstring: string;
    foodstockdisponible: number;
    categorie: Categories;

    fooddownloadimageurl: string;
    categoriename: string;
    foodstockalerte: number;

    fooddatemenu: any;

    heurecommande: Heurecommande;

    foodprixht: string;
    duree: any;
    foodprixtva: string;

    pointvente: Pointvente;
}


export class Pointvente {
    pointventeid: string;
    pointventedatecreation: any;
    pointventeisdeleted: boolean;
    pointventename: string;
    pointventecode: string;
    pointventestatus: string;
}

export class Heurecommande {
    heurecommandeid: string;
    heurecommandeenable: boolean;
    heurecommandeheuredebut: string;
    heurecommandeheurefin: string;
    heurecommandelibelle: string;
    heurecommandestatus: string;
}

export class Categories {
    categorieid: string;
    categoriename: string;
    categoriedatecreation: string;
    categorieisdeleted: boolean;
}

export class Address {
    addressid: string;
    addressisdeleted: boolean;
    addresslocation: string;
    addresszone: string;
    addressquartier: string;
    addressdescriptif1: boolean;
    addressdescriptif2: string;
    addressisdefault: boolean;
    client: Client;


}

export class AddressDto {
    addressid: string;
    addressisdeleted: boolean;
    addresslocation: string;
    addresszone: string;
    addressquartier: string;
    addressdescriptif1: boolean;
    addressdescriptif2: string;
    addressisdefault: boolean;
    clientid: string;


}

export class AddressDefaultDto {
    clientid: string;
    addressid: string;
}


export class AuthResponse {
    auth_token: string;
}

export class livraison {
    livraisonmodename: string;
}

export class paymentmode {
    paymentmodename: string;
}


export class commandFood {
    commandFoodId: string;
    commandItemId: string;
    commandItemName: string;
    commandItemPrice: number;
    commandItemQuantity: number;
    commandprixht: string;
    commandprixtva: string;
    pointventeid: string;

}

export class Parameter {
    parameterid: number;
    value: number;
    value1: number;
    parametercode: any;
    parameterdate: any;
}



export interface Item {
    itemid: string;
    itemname: string;
    itemprice: number;
    itemquantity: number;
    itemref: string;
}


export class Historiq {
    client: Client;
    commandereference: string;
    commandreference: string;
    historiquetotalprice: number;
    historiqueventedate: Date;
    historiqueventeid: string;
    items: Item[];
    livraisonStatus: boolean;
    annulationStatus: boolean;
    livraisonmode: string;
    paymentStatus: boolean;
    paymentmode: string;
    totalcommandprix: number;
    commandremise: number;
    commandtotalpricepayer: number;
    historiquelieulivraisonzone: string;
    historiquelieulivraisonquartier: string;
}


export class clientCommandFood {
    clientId: string;
    codesecretCarte: string;

    commandDateCreation: any;
    commanddatecreation: any;

    commandId: string;
    commandTotalPrice: number;
    commandreference: string;
    nombreplats: number;

    livraisonStatus: true;
    paymentStatus: true;

    livraison: livraison;
    paymentmode: paymentmode;

    paymentmodename: string;
    typeLivraisonname: string;
    commanditemDtos: commandFood[];
    commanditems: commandFood[] = [];

    foods: Foods[] = [];

    commandcommentaire: string;

    commandlieulivraisonzone: string;
    commandlieulivraisonquartier: string;

    commandprovenance: string;

    commandheure: string;

    commandtotalpricepayer: number;

    commandremise: number;

    annulationStatus: boolean;

    annulationMotif: string;

}


export class clientPayment {

    clientId: string;
    commandId: string;
    paymentModeId: string;

}


export class PaymentMonetique {

    public totalachat: number;
    public plats: Foods[];
    public clientreference: string;
    public commandreference: string;
}


export class PaymentEspece {
    commandRef: string;
    commandprovenance: string;
    paymentModeId: string;
}

export class ClientCreditDto {
    solde: number;
    clientreference: string;
    clientagentid: string;
    historiqueclientprovenance: string;
}


export class Carteabonnement {
    carteabonnementid: string;
    carteabonnementcodereference: string;
    carteabonnementcodesecret: string;
    carteabonnementsoldeinitial: number;
    carteabonnementsoldeavant: number;
    carteabonnementsoldeactuel: number;
    carteabonnementdevise: string;
    carteabonnementmodecreation: string;
    carteabonnementstatut: string;
    carteabonnementenable: boolean;
    carteabonnementagentid: string;
    carteabonnementphonebuyer: string;
    carteabonnementagentemail: string;
    carteabonnementdureevalidite: number;
    carteabonnementdatevalidite: any;
    carteabonnementdatecreation: any;
    carteabonnementdateactivation: any;
    carteabonnementstatutimpression: boolean;
    carteabonnementstatutexploitable: boolean;
    carteabonnementdateexpiration: any;
    client: Client;
    typecarte: Typecarte
}

export class Typecarte {
    typecarteid: string;
    typecartecode: string;
    typecartedate: any;
    typecartedescription: string;
    typecarteenable: boolean;
    typecartelibelle: string;
    typecartestatut: string;
}



export class AbonnementDto {
    abonnementformuleid: string;
    abonnementprecompte: boolean;
    abonnementdecouvert: boolean;
    abonnementsoldeinitial: number;
    abonnementmodecreation: string;
    abonnementagentid: string;
    clientid: string;
    abonnementprovenance: string;
    abonnementmontantsouhaite: number;
    abonnementmodepaiement: string;
    abonnementdateactivation: any;
    codesecretcarte: string;

}



export class Recaptchav2Dto {
    token: string;
}

export class ResetPassCarte {
    numCarte: string;
    password: string;
}

export class RenewUserPasswordDto {
    newPassword: string;
    oldpassword: string;
    userId: string;
}



export class PaycarteDto {
    clientid: string;
    montant: number;
    typecard: number;
    provenance: string;
    codesecret: string;
}


export class CheckTypecarteDto {
    typecard: string;
    solde: number;
    cumulconso: number;
    frais_classic: number;
    frais_gold: number;
    soldemin_classic: number;
    soldemin_gold: number;
    rechargement_classic: number;
    rechargement_gold: number;
    rechargement_gold_classic: number;
}

export class DemandeabnDto {
    demandeabnid: string;
    demandeabnmotif: string;
    demandeabnexp: string;
    demandeabnidclient: string;
    demandeabnagent: string;
    demandeabnprecpte: boolean;
    demandeabndcvrt: boolean;
    demandeabnstatus: number;
    demandeabnetat: number;
    demandeabnmode: string;
    demandeabndateactivation: any;
    demandeabnformule: string;
    demandeabnmontant: string;
    demandeabndatedesactivation: any;
    demandeabncodesecret: string;
    demandeabncorporate: string;

}


export class SearchDto {
    limit: number;
    page: number;
    debut: any;
    fin: any;
    clientid: string;
    statut: string;
    moyenpaiement: string;
}

export class SearchCatDate {
    datevalidite: string;
    categorie: string;
}


export class AbonnementCreditDto {
    solde: number;
    abonnementid: string;
    abonnementagentid: string;
    abonnementtypepaiement: string;
    provenance: string;
    codesecretcarte: string;
}