import { Component, Injector } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ParameterService } from '../parameter.service';
import { Foods, SearchCatDate } from '../models/models';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FoodDialogComponent } from '../food-dialog/food-dialog.component';

@Component({
  selector: 'app-menu-carte',
  templateUrl: './menu-carte.component.html',
  styleUrls: ['./menu-carte.component.scss']
})
export class MenuCarteComponent {

  daySelected: any = 1;

  dateaujoudhui = new Date(this.parameterService.convertDateToString(this.parameterService.getCurrentDate()));

  dateMomentJs = moment();
  jourclicke: string = this.dateMomentJs.format('YYYY-MM-DD');
  public date = new Date();
  public jourSemaine = this.date.getDay();
  public currentTabs: string;
  categorieSelected: any = '';
  public closeResult: string;


  public prods = [];
  public cats = [];
  tabs = [];
  foodSize: any = 0;


  public foodSliderConfig: any = {
    loop: false,
    dots: false,
    navSpeed: 300,
    autoHeight: true,
    responsive: {

      991: {
        items: 4
      },

      767: {
        items: 3
      },
      420: {
        items: 2
      },
      0: {
        items: 1
      }
    }
  };


  constructor(private parameterService: ParameterService, private modalService: NgbModal) {
  }


  ngOnInit(): void {
    this.getAllCategories();
  }


  goToDay(value) {
    this.daySelected = value;
    this.onValChange(value);
  }

  public getAllCategories() {
    this.parameterService.getAllWithKey("parameter/categorie").subscribe(data => {
      this.cats = data.body;
      for (const key in this.cats) {
        if (this.cats.hasOwnProperty(key)) {
          const element = this.cats[key];
          this.tabs.push(element.categoriename);
        }
      }
      let date = this.parameterService.convertDateToString(this.parameterService.getCurrentDate());
      this.categorieSelected = this.tabs[0];
      this.goToDay(1);
      /*
      if(this.categorieSelected){
        this.onLinkClick(this.categorieSelected);
      }
      */
      //  this.getFoodByDay(this.tabs[0], date);
    });
  }


  public onValChange(value) {

    let diff = this.jourSemaine - value;

    // Verifier le jour
    if (diff > 0) {
      this.jourclicke = moment().subtract(Math.abs(diff), 'days').format('YYYY-MM-DD');
    } else if (diff < 0) {
      this.jourclicke = moment().add(Math.abs(diff), 'days').format('YYYY-MM-DD');
    } else {
      this.jourclicke = this.dateMomentJs.format('YYYY-MM-DD');
    }

    this.getFoodByDay(this.categorieSelected, this.jourclicke);
  }


  public onLinkClick(e) {
    //this.getProducts(e.tab.textLabel.toLowerCase());jourclicke
    //this.getFoodByDay(e.tab.textLabel.toLowerCase(), this.autreService.convertDateToString(this.autreService.getCurrentDate()));
    this.currentTabs = e;
    this.categorieSelected = e;
    this.getFoodByDay(e, this.jourclicke);
  }

  public getFoodByDay(categoriename: string, dateToSearch: string) {

    let objToSend: SearchCatDate = new SearchCatDate();
    objToSend.categorie = categoriename;
    objToSend.datevalidite = dateToSearch;

    this.parameterService.postWithKey("parameter/food/bydateandcat", objToSend).subscribe(data => {

      switch (data.status) {

        case 200: {
          this.prods = data.body;
          this.foodSize = this.prods.length;
          break;
        }

        case 204: {
          this.prods = [];
          this.foodSize = 0;

          break;
        }
        default: {
          /*this.logger.trace('resp, ', data);
          this.prods = data.body;
          this.logger.trace('DATA to display, ', this.prods);*/
          this.foodSize = 0;

          break;
        }

      }
    },
      error => {

        switch (error.status) {



          default: {
            this.foodSize = 0;

            /* this.snackbar.open('Une erreur est survenue ', 'OK', {
              duration: 5000
            }); */
          }
        }
      });

  }



  checkHour(obj) {
    //this.logger.trace('Obj', obj);
    let result = this.parameterService.checkHourFood(obj.heurecommandeheuredebut, obj.heurecommandeheurefin);
    //this.logger.trace('result', result);
    return result;
  }

  checkHourCommande(product) {
    //this.logger.trace('Obj', obj);
    if (product) {
      let result = this.parameterService.checkHourCommande(product.parameterheure, product.parametermin);
      return result;

    }
    //this.logger.trace('result', result);
  }


  // Verifier si la date du plat est differente de la date du jour pour empecher la commande
  checkDate(product) {

    let result = true;
    if (product.foodtype == 'permanente') {
      // Ne rien faire
      //this.logger.trace('Ne rien faire');

    } else {
      let datemenu = product.fooddatemenu;
      //this.logger.trace('datemenu', datemenu);
      let compare = this.parameterService.compareDate(datemenu, this.dateaujoudhui);
      //this.logger.trace('result', result);
      if (compare == 0) {
        result = true;
      } else {
        result = false;
      }
      //this.logger.trace('result comparaison', result);
    }

    return result;
  }


  async openModal(product) {
    console.log(product);

    this.modalService.open(FoodDialogComponent,
      {
        size: 'xl',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: Foods, useValue: product
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }



  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 600,
    navText: ['&#8249', '&#8250;'],
    responsive: {
      /*
      0: {
        items: 1
      },
      */
      400: {
        items: 2
      },

      760: {
        items: 3
      },
      1000: {
        items: 4
      }
    },
    nav: true
  }

}
