import { Component } from '@angular/core';

@Component({
  selector: 'app-menu-contact',
  templateUrl: './menu-contact.component.html',
  styleUrls: ['./menu-contact.component.scss']
})
export class MenuContactComponent {

}
