<div class="formulaire-item">
    <div class="container">
        <form [formGroup]="modifpassForm">
            <div class="checkForm">

                <div class="row">
                    <div class="col-lg-12" style="text-align: center;">
                        <input type="checkbox" (click)="changePassword()" id="flexCheckDefault">
                        <b>
                            afficher mot de passe
                        </b>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input [type]="typepassword" name="oldpassword" minlength="4" maxlength="4"
                                [formControl]="modifpassForm.controls['oldpassword']" class="form-control" required
                                placeholder="Ancien code secret">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input [type]="typepassword" name="newpassword" minlength="4" maxlength="4"
                                [formControl]="modifpassForm.controls['newpassword']" class="form-control" required
                                placeholder="Nouveau code secret">
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input [type]="typepassword" name="confirmpassword" minlength="4" maxlength="4"
                                [formControl]="modifpassForm.controls['confirmpassword']" class="form-control" required
                                placeholder="Confirmation code secret">
                        </div>
                    </div>
                    <div class="col-lg-6">

                    </div>
                </div>

                <div class="row"
                    *ngIf="modifpassForm.controls.oldpassword.errors?.number || modifpassForm.controls.newpassword.errors?.number  || modifpassForm.controls.confirmpassword.errors?.number">
                    <div class="col-lg-12" style="text-align: center;">
                        <b style="color: red;">
                            Chiffres uniquement
                        </b>
                    </div>
                    <br>
                </div>



                <div class="row">
                    <div class="col-lg-4">
                    </div>
                    <div class="col-lg-4">

                        <ng-container *ngIf="modifpassForm.invalid">
                            <div class="banner-btn-wrap">
                                <a class="cmn-btn"
                                    style="color: white; font-weight: bold; background-color: #efefef;">VALIDER</a>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!modifpassForm.invalid">
                            <div class="banner-btn-wrap">
                                <a (click)="modificationPassCarte(modifpassForm.value)" class="cmn-btn"
                                    style="color: white; font-weight: bold; cursor: pointer;">VALIDER</a>
                            </div>
                        </ng-container>


                    </div>
                    <div class="col-lg-4">
                    </div>
                </div>
            </div>
        </form>


    </div>
</div>