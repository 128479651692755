export const environment = {
  production: true,
  apiEndpoint: "https://ms-smile.yolin.ci",
  ipMS: "https://ms-smile.yolin.ci/",

  ipGtwMS: "https://crossroad-africa.net/",

  pathYolin: "https://smile.yolin.ci/",

  apiCaptcha: "https://ms-smile.yolin.ci/captcha/",

  keylocalstorage: "YolinFrontCurentuser",

  urlGateway: "https://crossroad-africa.net/order",

  urlAppMobile: "https://smile.yolin.ci/assets/mobileapp/app-Yolin-prod.apk",

  urlCarteRestau: "https://smile.yolin.ci/carte",

  yolinEmail: "infos@yolintraiteur.com",

  operationToken: "zhSbGZ3y-hknB-7RVF-OK7i-GbTBv9XJW7UO",

  prefixeCarte: "CA-",

  prefixeAbn: "ABN-",

  prefixeAttributionCarte: "ATT-",
  
  captchaKey: "6LcTtnsoAAAAAAUrm1LcUKbN-kq-Jyo-fepjThZL"

};
