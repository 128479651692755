import { Component, Inject, Injector, Input, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Foods } from '../models/models';
import { NotifDialogComponent } from '../notif-dialog/notif-dialog.component';

@Component({
  selector: 'app-food-dialog',
  templateUrl: './food-dialog.component.html',
  styleUrls: ['./food-dialog.component.scss']
})
export class FoodDialogComponent {

  count: number = 1;

  product: Foods;

  constructor(private food: Foods, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.product = this.food;
    console.log(this.product);
  }

  ngAfterViewInit(): void {
  }



  increment() {

    if ((this.food.foodstockdisponible > this.count) && (this.food.foodstockdisponible - this.count > this.food.foodstockalerte)) {
      this.count++;

    } else {

      //  this.openNotification('Notification', 'La quantité est indisponible');

    }

  }

  decrement() {
    if (this.count > 1) {
      this.count--;
    }
  }





  add_to_shopping_cart() {

    // Recuperer le plat pour mettre a jour le prix global
    let foodSave: Foods = new Foods();
    foodSave = this.getFoodInShopping(this.product.foodid);

    this.product.foodqte = this.count;
    // this.product.foodqte = 1;
    if (foodSave) {

      this.product.foodtotal = foodSave.foodtotal + (this.product.foodprice * this.product.foodqte);

    } else {
      this.product.foodtotal = this.product.foodprice * this.product.foodqte;
    }


    this.AddFoodInShopping(this.product);
    //this.dialogRef.close();
    this.modalService.dismissAll();
    this.openNotifModal('Plat ajouté avec succès');
  }

  async openNotifModal(message) {
    this.modalService.open(NotifDialogComponent,
      {
        size: 'xs',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: message
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  getFoodInShopping(val: string) {
    let res: any = sessionStorage.getItem("allfood");
    let allfood: Foods[] = JSON.parse(res);

    if (allfood) {
      for (let f of allfood) {
        if (f.foodid === val) {
          return f;
        }
      }
    }
    return null;
  }

  AddFoodInShopping(val: Foods) {
    // this.DeleteFoodInShopping(val);
    // this.logger.trace('valeur add food', val);
    let res: any = sessionStorage.getItem('allfood');
    let allfood: Foods[] = JSON.parse(res);

    if (allfood && allfood.length > 0) {

      for (let index = 0; index < allfood.length; index++) {
        /* for (let f of allfood) { */

        if (allfood[index].foodid == val.foodid) {

          if (allfood[index].foodqte + val.foodqte > (val.foodstockdisponible - val.foodstockalerte)) { // verifier la quantité disponible
            //   this.openNotification('Notification', 'La quantité est indisponible');

            // allfood.push(allfood[index]);

          } else {

            allfood[index].foodqte += val.foodqte;
            allfood.push(allfood[index]);
            allfood.splice(index, 1);
            //   this.openNotification('Notification', 'Plat ajouté avec succes');
          }

          break;

        } else {

          if (index == (allfood.length - 1)) {
            allfood.push(val);
            // this.openNotification('Notification', 'Plat ajouté avec succes');
            break;

          }
        }
      }
    } else {
      allfood = [];
      allfood.push(val);
      //  this.openNotification('Notification', 'Plat ajouté avec succes');

    }

    // this.logger.trace('allfoods fin', allfood);

    sessionStorage.setItem('allfood', JSON.stringify(allfood));

  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }




}
