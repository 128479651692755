<div style="padding: 5%; color: black;">
    <div class="container">

        <div class="row">
            <div class="col-lg-3" style="padding: 0px;">
                ID transaction :
            </div>
            <div class="col-lg-9" style="padding: 0px;">
                {{item.commandreference}}
            </div>

            <div class="col-lg-3" style="padding: 0px;">
                Mode de livraison :
            </div>
            <div class="col-lg-9" style="padding: 0px;">
                {{item.livraisonmode}}
            </div>


            <div *ngIf="item.historiquelieulivraisonzone" class="col-lg-3" style="padding: 0px;">
                Lieu de livraison :
            </div>
            <div *ngIf="item.historiquelieulivraisonzone" class="col-lg-9" style="padding: 0px;">
                {{item.historiquelieulivraisonzone + ' ' + item?.historiquelieulivraisonquartier}}
            </div>


            <div class="col-lg-3" style="padding: 0px;">
                Mode de paiement :
            </div>
            <div class="col-lg-9" style="padding: 0px;">
                {{item.paymentmode}}
            </div>


            <div class="col-lg-3" style="padding: 0px;">
                Montant :
            </div>
            <div class="col-lg-9" style="padding: 0px;">
                {{item.totalcommandprix }} FCFA
            </div>

            <div class="col-lg-3" style="padding: 0px;">
                Remise :
            </div>
            <div class="col-lg-9" style="padding: 0px;">
                {{item.commandremise}} FCFA
            </div>

            <div class="col-lg-3" style="padding: 0px;">
                Montant Net :
            </div>
            <div class="col-lg-9" style="padding: 0px;">
                {{item?.commandtotalpricepayer }} FCFA
            </div>


            <div class="col-lg-3" style="padding: 0px;">
                Date et heure :
            </div>
            <div class="col-lg-9" style="padding: 0px;">
                {{item?.historiqueventedate }}
            </div>


            <div class="col-lg-3" style="padding: 0px;">
                Payé :
            </div>
            <div class="col-lg-9" style="padding: 0px;">
                {{verifyFalse(item?.paymentStatus)}}
            </div>

            <div class="col-lg-3" style="padding: 0px;">
                Livrée :
            </div>
            <div class="col-lg-9" style="padding: 0px;">
                {{verifyFalse(item?.livraisonStatus)}}
            </div>


            <div class="col-lg-3" style="padding: 0px;">
                Statut :
            </div>
            <div class="col-lg-9" style="padding: 0px;">
                {{ (item?.annulationStatus) == true ? 'ANNULER' : (item?.paymentStatus) ==
                true ? 'PAYER' : 'NON PAYER' }}
            </div>
            <ng-container *ngFor="let it of item.items">

                <div class="col-lg-9" style="padding: 0px;">
                    {{it?.itemname}} * {{it?.itemquantity}}
                </div>
                <div class="col-lg-3" style="padding: 0px; text-align: right;">
                    {{ (it?.itemprice * it?.itemquantity) }} FCFA
                </div>

            </ng-container>


        </div>

    </div>
</div>