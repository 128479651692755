
<!--
<div class="row">

    <div class="col-lg-3">

    </div>



    <div class="col-lg-2">
        <div class="services-item" (click)="selectedItems(1)"
            [ngStyle]="itemSelected==1?{'background-color': '#F07409'} : {'background-color': 'black'}">
            <a>
                <a class="nav-cart"><i class='bx bxs-credit-card'></i></a>
                <p>Renouveler mon abonnement</p>
            </a>
        </div>
    </div>

    <div class="col-lg-2" (click)="selectedItems(2)">
        <div class="services-item"
            [ngStyle]="itemSelected==2?{'background-color': '#F07409'} : {'background-color': 'black'}">
            <a>
                <a class="nav-cart"><i class='bx bxs-key'></i></a>
                <p>Je desactive mon precompte</p>
            </a>
        </div>
    </div>

    <div class="col-lg-2" (click)="selectedItems(3)">
        <div class="services-item"
            [ngStyle]="itemSelected==3?{'background-color': '#F07409'} : {'background-color': 'black'}">
            <a>
                <a class="nav-cart"><i class='bx bxs-key'></i></a>
                <p>Demande d'arrêt pour depart</p>
            </a>
        </div>
    </div>


    <div class="col-lg-3">

    </div>

</div>
-->

<section class="cart-area">
    <div class="container">
        <div class="cart-wrap-order">

            <table class="table">
                <thead class="thead">
                    <tr>
                        <th class="table-head" scope="col">Date debut</th>
                        <th *ngIf="client?.clienttype.clienttypename != 'default'" class="table-head" scope="col">Date fin</th>
                        <th class="table-head" scope="col">Formule</th>
                        <th class="table-head" scope="col">Solde</th>

                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let row of items | slice: (page-1) * pageSize : page * pageSize">
                        <td> {{ row?.abonnementdatedebut | date:'dd/MM/yyyy' }}
                        </td>
                        <td *ngIf="client?.clienttype.clienttypename != 'default'">
                            {{ row?.abonnementdatefin | date:'dd/MM/yyyy'}}
                        </td>
                        <td>
                            {{ row?.abonnementformuleid | filterFormule:allFormules }}
                        </td>
                        <td>
                            {{ (row?.abonnementsoldeactuel) != null ? row?.abonnementsoldeactuel : 'Non terminé' }}
                        </td>

                    </tr>

                </tbody>
            </table>
            <ngb-pagination [(page)]="page" [pageSize]="pageSize" [collectionSize]="items.length"></ngb-pagination>



        </div>
    </div>
</section>