<div class="formulaire-item">
    <div class="container">
        <form [formGroup]="rechargerForm">
            <div class="checkForm">

                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <input type="number" name="montantrechargement"
                                [formControl]="rechargerForm.controls['montantrechargement']" class="form-control"
                                required placeholder="Montant">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4">
                    </div>
                    <div class="col-lg-4">
                        <div class="banner-btn-wrap">
                            <a (click)="rechargerCarte(rechargerForm.value)" class="cmn-btn"
                                style="color: white; font-weight: bold;">VALIDER</a>
                        </div>
                    </div>
                    <div class="col-lg-4">
                    </div>
                </div>
            </div>
        </form>


    </div>
</div>