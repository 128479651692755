<div class="row">

    <div class="col-lg-3">

    </div>


    <div class="col-lg-2">
        <div class="services-item" (click)="selectedItems(1)"
            [ngStyle]="itemSelected==1?{'background-color': '#F07409'} : {'background-color': 'black'}">
            <a>
                <a class="nav-cart"><i class='bx bxs-add-to-queue'></i></a>
                <p>DEMANDER UNE CARTE</p>
            </a>
        </div>
    </div>

    <div class="col-lg-2">
        <div class="services-item" (click)="selectedItems(2)"
            [ngStyle]="itemSelected==2?{'background-color': '#F07409'} : {'background-color': 'black'}">
            <a>
                <a class="nav-cart"><i class='bx bxs-info-circle'></i></a>
                <p>INFOS CARTE</p>
            </a>
        </div>
    </div>

    <!--
        checkcard
    -->

    <div class="col-lg-2">
        <div class="services-item" style="background-color: #F07409; cursor: default;">
            <a>
                <a class="nav-cart"><i class='bx bxs-chevron-down-circle'></i></a>
                <p>RENOUVELER CARTE</p>
            </a>
        </div>
    </div>



    <div class="col-lg-3">

    </div>


</div>

<div class="row" *ngIf="itemSelected==1 || itemSelected==2">

    <div class="col-lg-4">
        <qrcode [qrdata]="dataQrCode" [width]="256" [errorCorrectionLevel]="'M'" style="pointer-events: none"></qrcode>
    </div>

    <div class="col-lg-8">

        <div class="row">

            <div class="col-lg-6">
                Numero carte : <b style="color: black;"> {{carteabonnement?.carteabonnementcodereference}} </b>

            </div>
            <div class="col-lg-6">
                Statut :
                <ng-container *ngIf="carteabonnement?.carteabonnementstatut == 'EXPIRATION'; else elseTemplate">
                    <b style="color: black;">Carte expirée</b>
                </ng-container>
                <ng-template #elseTemplate>
                    <ng-container *ngIf="carteabonnement?.carteabonnementstatut == 'BLOQUER'; else elseTemplate">
                        <b style="color: black;">Carte bloquer</b>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <ng-container
                            *ngIf="carteabonnement?.carteabonnementstatut == 'actif' && carteabonnement?.carteabonnementstatutexploitable && carteabonnement?.carteabonnementstatutimpression; else elseTemplate">
                            <b style="color: black;">Carte disponible</b>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <b style="color: black;">En cours de traitement</b>
                        </ng-template>
                    </ng-template>

                </ng-template>
            </div>

            <div class="col-lg-6">
                Date d’activation : <b style="color: black;">
                    {{carteabonnement?.carteabonnementdateactivation.substring(0,16) }} </b>
            </div>
            <div class="col-lg-6">
                Date d'expiration : <b style="color: black;">
                    {{carteabonnement?.carteabonnementdateexpiration.substring(0,16)}} </b>

            </div>

        </div>

        <div class="row">

            <div class="col-lg-12" style="margin-top: 20px;">
                <div class="banner-btn-wrap">
                    <a class="cmn-btn" *ngIf="this.carteabonnement?.carteabonnementstatut != 'EXPIRATION'"
                        (click)="afficherFormStatut()" style="color: white; font-weight: bold; cursor: pointer;">
                        {{ this.carteabonnement.carteabonnementstatut == 'BLOQUER' ? 'Debloquer ma carte' : 'Bloquer ma
                        carte'}}
                    </a>
                </div>
            </div>

            <div class="col-lg-12" *ngIf="statutblocage">

                <div class="formulaire-item" style="padding: 0px; margin-top: 20px;">
                    <form [formGroup]="blocageForm">

                        <div class="checkForm">

                            <div class="row">
                                <div class="col-lg-8">
                                    <div class="form-group">
                                        <input type="text" name="motif" [formControl]="blocageForm.controls['motif']"
                                            class="form-control" required placeholder="Motif de blocage">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-8">
                                    <div class="form-group">
                                        <input type="password" name="clientcodesecret"
                                            [formControl]="blocageForm.controls['clientcodesecret']"
                                            class="form-control" required placeholder="Code secret carte">
                                    </div>
                                </div>
                                <div class="col-lg-4">

                                </div>


                                <div class="col-lg-12">
                                    <ng-container *ngIf="blocageForm.invalid">
                                        <div class="banner-btn-wrap">
                                            <a class="cmn-btn"
                                                style="color: white; font-weight: bold; background-color: #efefef;">VALIDER</a>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!blocageForm.invalid">
                                        <div class="banner-btn-wrap">
                                            <a class="cmn-btn" (click)="changeTypeCarte()"
                                                style="color: white; font-weight: bold; cursor: pointer; background-color: #F07409;">VALIDER</a>
                                        </div>
                                    </ng-container>
                                </div>

                            </div>


                        </div>

                    </form>
                </div>

            </div>
            <div class="col-lg-12" *ngIf="carteabonnement">


                <!--
                <div class="row align-items-center" style="margin-top: 50px;">
                    <div class="col-lg-6">
                        <div class="reservation-item">

                            <ul style="padding-left: 0px;">
                                <li>
                                    <input type="date" class="form-control" name="datedebut"
                                        [formControl]="itemForm.controls['datedebut']">
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="reservation-item">

                            <ul>
                                <li>
                                    <input type="date" class="form-control" name="datefin"
                                        [formControl]="itemForm.controls['datefin']">
                                </li>

                            </ul>

                        </div>
                    </div>


                    <div class="col-lg-12" style="text-align: center; padding-bottom: 10px;">
                        <ng-container *ngIf="itemForm.invalid">
                            <div class="banner-btn-wrap">
                                <a class="cmn-btn"
                                    style="color: white; font-weight: bold; background-color: #efefef;">RECHERCHER</a>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!itemForm.invalid">
                            <div class="banner-btn-wrap">
                                <a class="cmn-btn" (click)="historiquecartebyclientTribyDate()"
                                    style="color: white; font-weight: bold; cursor: pointer; background-color: #F07409;">RECHERCHER</a>
                            </div>
                        </ng-container>
                    </div>
                </div>
            -->

                <section class="cart-area">
                    <div class="container" style="padding-left: 0px; margin-top: 5px;">
                        <div class="cart-wrap-order">
                            <table class="table">
                                <thead class="thead">
                                    <tr>
                                        <th class="table-head" scope="col">DATE</th>
                                        <th class="table-head" scope="col">ACTIONS</th>
                                        <th class="table-head" scope="col">MONTANT</th>
                                        <th class="table-head" scope="col">SOLDE</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let row of items | slice: (page-1) * pageSize : page * pageSize">
                                        <td>{{ row?.historiqueclientdatecreation }}</td>
                                        <td>
                                            {{ row?.historiqueclientaction }}
                                        </td>
                                        <td>{{ row?.historiqueclientmontant }}</td>
                                        <td>
                                            {{ (row?.historiqueclientsoldeapres) != null ?
                                            row?.historiqueclientsoldeapres : 'Non terminé' }}
                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                            <ngb-pagination [(page)]="page" [pageSize]="pageSize"
                                [collectionSize]="items.length"></ngb-pagination>


                        </div>
                    </div>
                </section>

            </div>


        </div>


    </div>

</div>