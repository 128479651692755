<div class="container">
    <div class="row">
      <div class="col-lg-12">
        <hr style="color: black; font-weight: bold" />
        <h5><b style="text-align: left"> MOT DE PASSE </b></h5>
        <br />
  
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <input
                  type="password"
                  name="oldpassword"
                  style="width: 100%"
                  id="oldpassword"
                  [formControl]="passwordForm.controls['oldpassword']"
                  placeholder="Ancien mot de passe"
                  class="form-control-insc"
                />
                <div
                  *ngIf="
                    !passwordFormDisabled &&
                    passwordForm.controls.oldpassword.errors?.required
                  "
                  style="color: red; font-weight: bold"
                >
                  Obligatoire
                </div>
  
                <div
                  *ngIf="
                    !passwordFormDisabled &&
                    passwordForm.controls.oldpassword.errors?.minlength
                  "
                  style="color: red; font-weight: bold"
                >
                  Minimum 6 caractères
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <input
                  type="password"
                  name="newpassword"
                  style="width: 100%"
                  [formControl]="passwordForm.controls['newpassword']"
                  placeholder="Nouveau mot de passe"
                  class="form-control-insc"
                />
  
                <div
                  *ngIf="
                    !passwordFormDisabled &&
                    passwordForm.controls.newpassword.errors?.required
                  "
                  style="color: red; font-weight: bold"
                >
                  Nouveau mot de passe obligatoire
                </div>
  
                <div
                  *ngIf="
                    !passwordFormDisabled &&
                    passwordForm.controls.newpassword.errors?.minlength
                  "
                  style="color: red; font-weight: bold"
                >
                  Minimum 6 caractères
                </div>
  
                <div
                  *ngIf="
                    !passwordFormDisabled &&
                    passwordForm.controls.newpassword.errors?.samePassword
                  "
                  style="color: red; font-weight: bold"
                >
                  Nouveau mot de passe identique à l'ancien
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <input
                  type="password"
                  name="confirmpassword"
                  style="width: 100%"
                  [formControl]="passwordForm.controls['confirmpassword']"
                  placeholder="Confirmation"
                  class="form-control-insc"
                />
  
                <div
                  *ngIf="
                    !passwordFormDisabled &&
                    passwordForm.controls.confirmpassword.errors?.required
                  "
                  style="color: red; font-weight: bold"
                >
                  Confirmation obligatoire
                </div>
  
                <div
                  *ngIf="
                    !passwordFormDisabled &&
                    passwordForm.controls.confirmpassword.errors
                      ?.mismatchedPasswords
                  "
                  style="color: red; font-weight: bold"
                >
                  Mot de passe ne coincide pas
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <br />
        <div style="padding-left: 10px">
          <ng-container *ngIf="passwordFormDisabled">
            <button
              (click)="setIsEnable(true)"
              type="submit"
              class="btn cmn-btn-insc"
              style="color: white"
            >
              MODIFIER
            </button>
          </ng-container>
  
          <ng-container *ngIf="!passwordFormDisabled">
            <button
              class="btn cmn-btn-insc btn-danger"
              style="color: white; background-color: brown; margin-right: 10px"
              (click)="setIsEnable(false)"
              [disabled]="isSubmitting"
            >
              Annuler
            </button>
  
            <button
              class="btn cmn-btn-insc"
              style="color: white; background-color: #f07409"
              [disabled]="passwordForm.invalid || isSubmitting"
              (click)="onSubmit()"
            >
              Valider {{ isSubmitting ? "..." : "" }}
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  