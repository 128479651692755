<div class="contact-container">
  <app-navbar-style-one></app-navbar-style-one>

  <section class="page-title-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-3"></div>

        <div class="col-lg-6">
          <div class="page-title-item" style="text-align: center">
            <h2>CONTACTS</h2>
          </div>
        </div>

        <div class="col-lg-3"></div>
      </div>
    </div>
  </section>

  <section style="margin: 2.5rem auto" class="container mc-section-container">
    <h2 class="mc-section-title">RESTAURANT</h2>

    <div class="mc-section-content">
      <div class="mc-section-infos-container">
        <div>Plateau cité financière</div>
        <div>Abidjan, Cote d'Ivoire</div>
        <div>Tél : +225 07 57 96 96 78</div>
        <div>yolintrateur@gmail.com</div>
        <div>infos@yolintrateur.com</div>
      </div>

      <div class="mc-section-form-container">
        <div
          style="
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 10px;
          "
        >
          <div class="form-group">
            <label for="nom">Nom</label>
            <input class="form-control" type="text" name="" id="nom" />
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <input class="form-control" type="email" name="" id="email" />
          </div>
        </div>

        <div class="form-group">
          <label for="tel">Téléphone</label>
          <input class="form-control" type="tel" name="" id="tel" />
        </div>

        <div class="form-group">
          <label for="message">Message</label>
          <textarea class="form-control" id="message"></textarea>
        </div>
      </div>
    </div>
  </section>

  <section class="container" style="margin-bottom: 2.5rem">
    <div>
      <img
        style="width: 100%; height: 300px"
        src="https://img.freepik.com/vecteurs-libre/carte-du-monde-politique-coloree_23-2148319222.jpg?w=2000"
      />
    </div>
  </section>

  <app-footer-style-two></app-footer-style-two>
</div>
