<div class="row" style="height: 350px;">

    <div class="col-lg-6" style="padding: 0px;">
        <img [src]="product.fooddownloadimageurl" width="580px" height="350px" alt="Blog">
    </div>

    <div class="col-lg-6">

        <div class="about-content" style="overflow:scroll ; height: 350px ">
            <div class="section-title">
                <br>
                <h3>{{product.foodname}}</h3>
                <p style="text-align: left;">Categorie : <span
                        style="font-weight: bold;">{{product.categorie.categoriename}}</span> </p>
                <p style="text-align: left;">Disponibilité :
                    <span style="font-weight: bold;">
                        {{ (product.foodstockdisponible - product.foodstockalerte > 0) ? (product.foodstockdisponible -
                        product.foodstockalerte) + ' plats restants':'En rupture'}}</span>
                </p>

                <p style="text-align: left;"><span style="font-weight: bold;">{{product.duree}}</span> </p>

                <p style="text-align: left;">{{product.fooddescription}} </p>

                <h4 style="text-align: left;">{{product.foodprice}} Frs CFA</h4>

                <div class="collection-item">
                    <div class="collection-bottom" style="padding: 0px;">
                        <ul>


                            <li>
                                <div class="number" style="text-align: left;">
                                    <span (click)="decrement()" class="minus">-</span>
                                    <span class="minus" style="margin-left: 5px; margin-right: 5px;">{{count}}</span>
                                    <span (click)="increment()" class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
            <a class="cmn-btn" (click)="add_to_shopping_cart()" style="color: white; cursor: pointer;">AJOUTER</a>
        </div>

    </div>


</div>