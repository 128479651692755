import { Component, Injector, Input } from '@angular/core';
import { AuthResponse, CheckTypecarteDto, Client, Foods, PaycarteDto, PaymentMonetique } from '../../models/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from '../../parameter.service';
import { NotifDialogComponent } from '../../notif-dialog/notif-dialog.component';
import { CustomValidators } from 'ng2-validation';
import { NotifOptionComponent } from '../../notif-option/notif-option.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cartelocked',
  templateUrl: './cartelocked.component.html',
  styleUrls: ['./cartelocked.component.scss']
})
export class CartelockedComponent {

  itemSelected: any = 1;


  isClassik: boolean = false;
  isGold: boolean = false;
  //eligibilite: boolean = true 

  typeCarteSelected: string;

  paycarteDto: PaycarteDto;
  checkTypecarteDto: CheckTypecarteDto;

  payAttrCarteForm: FormGroup;
  tokenFormGroup: FormGroup;


  stepLevel: any = 0;
  displayPassAttribution: boolean = false;


  constructor(public formBuilder: FormBuilder, private parameterService: ParameterService,
    private modalService: NgbModal, private route: Router, private fbRecharge: FormBuilder
  ) { }


  @Input() client: Client;

  selectedItems(value) {
    this.itemSelected = value;
  }

  ngOnInit() {
    this.verifNiveauConso();
    this.payAttrCarteForm = this.formBuilder.group({
      moyenPaiement: ['mobilemoney', Validators.required],
      cgu: ['', [Validators.required]],
      codesecret: ['', [CustomValidators.number, CustomValidators.rangeLength([4, 4])]]
    });

    this.payAttrCarteForm.get('moyenPaiement').valueChanges.subscribe(data => {
      let event: any = this.payAttrCarteForm.get('moyenPaiement').value;
      if (event == 'carteabonnement') {
        this.displayPassAttribution = true;
      } else {
        this.displayPassAttribution = false;
      }
    })

  }


  verifNiveauConso(): any {

    this.parameterService.getAllWithKey('linkcard/statuscard/' + this.client.clientid).subscribe(data => {


      switch (data.status) {
        case 200: {
          this.checkTypecarteDto = data.body;

          //this.resultConso = 'classic';
          switch (this.checkTypecarteDto.typecard) {
            case 'classic':
              this.openModal('Vous êtes éligible à la carte Classique');
              this.isClassik = true;
              this.isGold = false;

              break;

            case 'all card':
              this.openModal('Vous êtes éligible à la carte gold');
              this.isGold = true;
              this.isClassik = true;

              break;

            default:
              this.openModal('Type de carte non reconnu, reessayer SVP ou contactez l\'administrateur');


              break;
          }

          break;
        }

        default: {
          break;
        }

      }
    },
      error => {

        switch (error.status) {
          case 406: {
            this.openModal('Aucune carte disponible, reessayer plutard ou contactez le support');

            break;
          }

          case 423: {
            this.openModal('Votre compte possede deja une carte');

            break;
          }

          default: {
            this.openModal('Une erreur est survenue');

          }
        }
      });
  }

  attributionRadioChange(event: any) {
    console.log(event);
    if (event == 'carteabonnement') {
      this.displayPassAttribution = true;
    } else {
      this.displayPassAttribution = false;
    }
  }

  goToBack() {
    this.stepLevel = this.stepLevel - 1;
  }

  checkTypecarteAndNext(type: string) {

    switch (type) {
      case 'classic':
        this.typeCarteSelected = 'classic';

        break;

      case 'gold':
        this.typeCarteSelected = 'gold';

        break;

      case 'classic_migrate_gold':
        this.typeCarteSelected = 'classic_migrate_gold';
        this.openModal('Le rechargement minimum de ' + this.checkTypecarteDto.rechargement_gold_classic + ' FCFA est requis pour passer à gold');

        break;

      default:
        break;
    }

    //  event.next();
    this.displayPassAttribution = false;
    this.payAttrCarteForm = this.formBuilder.group({
      moyenPaiement: ['mobilemoney', Validators.required],
      cgu: ['', [Validators.required]],
      codesecret: ['', [CustomValidators.number, CustomValidators.rangeLength([4, 4])]]
    });

    this.payAttrCarteForm.get('moyenPaiement').valueChanges.subscribe(data => {
      let event: any = this.payAttrCarteForm.get('moyenPaiement').value;
      if (event == 'carteabonnement') {
        this.displayPassAttribution = true;
      } else {
        this.displayPassAttribution = false;
      }
    })

    this.stepLevel = this.stepLevel + 1;

  }

  checkCguAndValid() {
    if (!this.payAttrCarteForm.get('cgu').value) {
      this.openModal('Cocher les CGU SVP');
    } else {
      this.openModalFinalise('Vous etes sur le point de valider votre paiement de carte, Vous etes sûr?');
    }

  }

  generationpdf(type: string) {
    this.parameterService.downloadpdf(type)
      .subscribe(data => {
        switch (data.status) {

          case 200: {
            this.downloadFile(data.body);

            break;
          }
          case 204: {
            this.openModal('Impossible d\'obtenir le CGU, cela n\'empêche pas de continuer le processus');

            break;
          }

          default: {


            break;
          }


        }
      },
        error => {

          switch (error.status) {

            case 404: {
              this.openModal('Impossible d\'obtenir le CGU, cela n\'empêche pas de continuer le processus d\'abonnement');

              break;
            }
            default: {
              this.openModal('Impossible d\'obtenir le CGU, cela n\'empêche pas de continuer le processus d\'abonnement');

              break;
            }
          }
        });
  }

  downloadFile(data) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }
  public closeResult: string;


  async openModal(product) {
    const modalRef: any = this.modalService.open(NotifDialogComponent,

      {
        size: 'lg',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: product
        }])
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });



  }

  async openModalFinalise(product) {
    const modalRef: any = this.modalService.open(NotifOptionComponent,

      {
        size: 'lg',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: product
        }])
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

        console.log(reason == 'OK');
        if (reason == 'OK') {
          let paycarteDto: PaycarteDto = new PaycarteDto();
          paycarteDto.clientid = this.client.clientid;
          paycarteDto.codesecret = this.payAttrCarteForm.get('codesecret').value;
          paycarteDto.provenance = 'frontoffice';
          // Verifier la carte demandée
          if (this.typeCarteSelected == 'gold') {
            paycarteDto.typecard = 1;
            paycarteDto.montant = this.checkTypecarteDto.frais_gold;

          } else if (this.typeCarteSelected == 'classic_migrate_gold') {
            paycarteDto.typecard = 1;
            paycarteDto.montant = this.checkTypecarteDto.rechargement_gold_classic;

          } else if (this.typeCarteSelected == 'classic') {
            paycarteDto.typecard = 0;
            paycarteDto.montant = this.checkTypecarteDto.frais_classic;

          } else {

          }

          switch (this.payAttrCarteForm.get('moyenPaiement').value) {
            case 'carteabonnement':
              if (this.payAttrCarteForm.get('codesecret').value) {
                this.checkSoldePaiementCarte(paycarteDto);
              } else {
                this.openModal('Saisir un mot de passe SVP');
              }
              break;
            case 'mobilemoney':
              this.paiementCarteMomo(paycarteDto);
              break;
            default:
              break;
          }
        }
      });
  }





  paiementCarteMomo(paycarteDto: PaycarteDto) {
    // Verifier le solde du client en fonction du choix du type de carte
    switch (this.typeCarteSelected) {
      case 'classic':
        paycarteDto.montant = this.checkTypecarteDto.rechargement_classic;

        break;

      case 'gold':
        paycarteDto.montant = this.checkTypecarteDto.rechargement_gold;

        break;

      case 'classic_migrate_gold':
        paycarteDto.montant = this.checkTypecarteDto.rechargement_gold_classic;

        break;

      default:
        break;
    }

    //enregistrer le paiement et redirection gateway

    this.parameterService.postWithKey('linkcard/paycardbymomoaccount', paycarteDto).subscribe(data => {

      switch (data.status) {
        case 200: {
          this.openModal('Votre demande de paiement de carte est en cours. Vous serez redirigé vers l\'interface de paiement afin de la finaliser.');

          // Redirection sur gateway
          this.redirectGateway(data.body.historiqueclientreftransaction, paycarteDto.montant);
          break;
        }

        default: {
          break;
        }

      }
    },
      error => {

        switch (error.status) {

          case 406: {
            this.openModal( 'Aucune carte disponible, reessayer ou contactez le support');

            break;
          }
          case 412: {
            this.openModal('Impossible de recuperer les données du client, reessayer plutard ou contactez l\'administrateur');

            break;
          }

          case 424: {
            this.openModal('Code secret de la carte incorrect');

            break;
          }

          default: {
            this.openModal( 'Une erreur est survenue');
          }
        }
      });

  }

 redirectGateway(refTrans: string, solde: number) {
    // Creation de l'objet de paiement
    let paymonetq: PaymentMonetique = new PaymentMonetique();
    paymonetq.commandreference = refTrans;
    paymonetq.clientreference = this.client.clientid;
    paymonetq.totalachat = solde;
    let plats: Foods[] = [];
    paymonetq.plats = plats;
    // Get token 
      this.parameterService.postWithKey('gatewaymonetique/token', paymonetq).subscribe(data => {

      switch (data.status) {

        default: {
      

          let cl: AuthResponse = new AuthResponse();

          cl = data.body;


          this.tokenFormGroup = this.formBuilder.group({
            'currency': ['xof'],
            'operation_token': [environment.operationToken],
            'order': [paymonetq.commandreference],
            'transaction_amount': [paymonetq.totalachat],
            'jwt': [cl.auth_token],
          });

        
          this.post(this.tokenFormGroup.value, environment.urlGateway);

          break;

        }
      }
    },
      error => {

     


        switch (error.status) {
          case 406: {
          
            this.openModal( 'Erreur de token gateway');

            /* this.snackbar.open('Erreur de token gateway', '×',
            { panelClass: 'success', verticalPosition: 'top', duration: 5000 }); */
            break;
          }
          default: {
      
            this.openModal( 'Une erreur est survenue, reessayer SVP ou contacter l\'administrateur');

            /* this.snackbar.open('Erreur de token gateway', '×',
            { panelClass: 'success', verticalPosition: 'top', duration: 5000 }); */
            break;
          }
        }
      });
  }

  post(obj, url) {
    var mapForm = document.createElement("form");
    mapForm.target = "_self";
    mapForm.method = "POST"; // or "post" if appropriate
    mapForm.action = url;
    Object.keys(obj).forEach(function (param) {
      var mapInput = document.createElement("input");
      mapInput.type = "hidden";
      mapInput.name = param;
      mapInput.setAttribute("value", obj[param]);
      mapForm.appendChild(mapInput);
    });
    document.body.appendChild(mapForm);
    mapForm.submit();
  }

  checkSoldePaiementCarte(paycarteDto: PaycarteDto) {
    //   this.accountservice.getClientById(this.client.clientid).subscribe(data => {

    this.parameterService.getAllWithKey('parameter/client/findbyid/' + this.client.clientid).subscribe(data => {

      switch (data.status) {
        case 200: {
          this.client = data.body;
          // Verifier le solde du client en fonction du choix du type de carte
          switch (this.typeCarteSelected) {
            case 'classic_migrate_gold':
              if (this.client.clientsoldeactuel >= this.checkTypecarteDto.rechargement_gold_classic) {
                this.paiementCarteCompteRestau(paycarteDto);

              } else {
                this.openModal('Le solde de votre compte est insuffisant pour le paiement de la carte');
              }
              break;

            case 'gold':
              if (this.client.clientsoldeactuel >= this.checkTypecarteDto.soldemin_gold) {
                this.paiementCarteCompteRestau(paycarteDto);

              } else {
                this.openModal('Le solde de votre compte est insuffisant pour le paiement de la carte');
              }
              break;

            case 'classic':
              if (this.client.clientsoldeactuel >= this.checkTypecarteDto.soldemin_classic) {
                this.paiementCarteCompteRestau(paycarteDto);

              } else {
                this.openModal('Le solde de votre compte est insuffisant pour le paiement de la carte');
              }
              break;

            default:
              break;
          }

          break;
        }
        case 204: {
          this.openModal('Impossible de retrouver le client, contactez le support SVP');
          break;
        }

        default: {
          break;
        }

      }
    },
      error => {

        switch (error.status) {

          default: {
            this.openModal('Une erreur est survenue');
            break;
          }
        }
      });
  }


  paiementCarteCompteRestau(paycarteDto: PaycarteDto) {

    this.parameterService.postWithKey('linkcard/paycardbyrestoaccount', paycarteDto).subscribe(data => {

      switch (data.status) {
        case 200: {
          this.openModal('Félicitations, votre demande de carte a été envoyée avec succès. Vous pourrez retirer votre carte à RestauTresor dans un délai de xx jours. Profitez dès à présent des avantages liés à votre carte');
          // Mettre a jour les infos du client (localstorage)
          //this.updateLocaleStorage();
          //redirection 
          this.route.navigate(['/moncompte/information']);

          break;
        }

        default: {
          break;
        }

      }
    },
      error => {
        switch (error.status) {

          case 406: {
            this.openModal('Aucune carte disponible, reessayer ou contactez le support');

            break;
          }
          case 423: {
            this.openModal('Solde compte insuffisant, veuillez recharger votre compte ou utiliser un autre moyen de paiement');

            break;
          }

          case 424: {
            this.openModal('Code secret de la carte incorrect');

            break;
          }

          default: {
            this.openModal('Une erreur est survenue');
          }
        }
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
