import { Component } from '@angular/core';

@Component({
  selector: 'app-my-abn',
  templateUrl: './my-abn.component.html',
  styleUrls: ['./my-abn.component.scss']
})
export class MyAbnComponent {

}
