<ng-container *ngIf="this.client?.clienttype?.clienttypename == 'default'; else elseTemplate">



    <section class="cart-area">
        <div class="container" style="padding-left: 0px; margin-top: 5px;">
            <div class="cart-wrap-order">

                <h4 style="text-align: center; margin: auto;">
                    Etes-vous un client corporate /entreprise ?
                </h4>

                <div class="formulaire-item" *ngIf="demandes?.length == 0">
                    <div class="container">
                        <form [formGroup]="corporateForm">

                            <div class="checkForm">

                                <div class="row">

                                    <div class="col-lg-6">

                                        <div class="reservation-item">

                                            <select class="form-select" style="height: 55px;"
                                                [formControl]="corporateForm.controls['clienttype']"
                                                aria-label="choisir l'entreprise">
                                                <option *ngFor="let c of clienttypes" [ngValue]="c">
                                                    {{c.clienttypename}}
                                                </option>

                                            </select>
                                        </div>
                                    </div>


                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input type="text" name="matricule"
                                                [formControl]="corporateForm.controls['matricule']" class="form-control"
                                                required placeholder="Matricule">
                                        </div>



                                    </div>
                                </div>


                            </div>

                        </form>

                        <div class="col-lg-12">
                            <ng-container *ngIf="corporateForm.invalid">
                                <div class="banner-btn-wrap">
                                    <a class="cmn-btn"
                                        style="color: white; font-weight: bold; background-color: #efefef;">VALIDER</a>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!corporateForm.invalid">
                                <div class="banner-btn-wrap">
                                    <a class="cmn-btn"
                                    (click)="demandeCorporate()"
                                        style="color: white; font-weight: bold; cursor: pointer; background-color: red;">ENREGISTRER</a>
                                </div>
                            </ng-container>
                        </div>


                    </div>
                </div>

                <b>
                    Liste des demandes
                </b>

                <table class="table">
                    <thead class="thead">
                        <tr>
                            <th class="table-head" scope="col">Date de demande</th>
                            <th class="table-head" scope="col">Corporate</th>
                            <th class="table-head" scope="col">Matricule</th>
                            <th class="table-head" scope="col">Statut</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let row of demandes | slice: (page-1) * pageSize : page * pageSize">
                            <td> {{ row?.demandeabndate }}
                            </td>
                            <td>
                                {{ row?.demandeabncorporate }} </td>
                            <td>
                                {{ row?.demandeabnmotif }}
                            </td>
                            <td>

                                {{ row?.demandeabnstatut }}

                            </td>

                        </tr>

                    </tbody>
                </table>

                <ngb-pagination [(page)]="page" [pageSize]="pageSize"
                    [collectionSize]="demandes.length"></ngb-pagination>

            </div>
        </div>
    </section>
</ng-container>
<ng-template #elseTemplate>
    <h4 style="text-align: center; margin: auto;">
        Cher(e) abonné(e) votre corporation est : {{this.client?.clienttype?.clienttypename?.toUpperCase()}}
    </h4>
</ng-template>