<app-navbar-style-one></app-navbar-style-one>

<div class="banner-area-three">

    <div class="container">

        <div class="banner-slider owl-theme owl-carousel">

            <div class="slider-item">

                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>COMMANDER JUSQU'A 10 HEURES</h1>

                            <!--
                                routerLink="/online-order"
                            -->
                            <div class="banner-btn-wrap">
                                <a class="cmn-btn" style="color: white; font-weight: bold;">CREER VOTRE COMPTE</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <img src="assets/img/croissants.jpg" style="height: 525px; width: 100%;" alt="Slider">

                    </div>
                </div>

            </div>

            <div class="slider-item">

                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Café et Restaurant</h1>

                            <!--
                                routerLink="/online-order"
                            -->
                            <div class="banner-btn-wrap">
                                <a class="cmn-btn" style="color: white; font-weight: bold;">TELECHARGER
                                    L'APPLICATION</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <img src="assets/img/soupe.jpg" style="height: 525px; width: 100%;" alt="Slider">

                    </div>
                </div>

            </div>



            <div class="slider-item">

                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Café et Restaurant</h1>

                            <!--
                                routerLink="/online-order"
                            -->
                            <div class="banner-btn-wrap">
                                <a class="cmn-btn" style="color: white; font-weight: bold;">PASSER UNE COMMANDE</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">

                        <img src="assets/img/poulet.jpg" style="height: 525px; width: 100%;" alt="Slider">

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<section class="story-area pt-100 pb-70" style="background-color: white;">

    <div class="container">

        <div class="row">
            <div class="col-sm-4 col-lg-4">
                <h3 style="font-weight: bold;">MENU DU JOUR</h3>
            </div>

            <div class="col-sm-8 col-lg-8">
                <div class="sorting-menu">
                    <ul style="text-align: left">

                        <!--
                             <li class="filter active"  data-filter="all">DESSERT</li>

                        <li *ngFor="let contact of contacts">
                            <contact-card></contact-card>
                          </li>

                          <li class="filter">DESSERT</li>

                                                  <li class="filter" *ngFor="let c of categories"

                        -->


                        <li [ngClass]="{'li-selected': c.categoriename === categorieSelected}" *ngFor="let c of categories" (click)="checkFoodByCategories(c.categoriename)">
                            {{c.categoriename}}
                        </li>


                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="services-area ptb-100" style="padding-top: 0px; background-color: white;">


    <div *ngIf="foodSize==0" class="container">
        <div class="level2" style="color: #f07409; font-size: 18px; font-weight: 900; text-align: center;">
            Aucun produit dans cette catégorie
        </div>
    </div>

    <div *ngIf="foodSize==1" class="container">
        <owl-carousel-o [options]="foodSliderConfig">
            <ng-template carouselSlide>
                <div class="step1">

                    <div class="level1">

                        <img [src]="foods[0].fooddownloadimageurl" alt="">


                        <p>{{foods[0].foodname}}
                            <br>
                            {{foods[0].foodprice}} FRS
                        </p>


                    </div>



                    <ng-container
                        *ngIf="checkHour(foods[0].heurecommande) || (foods[0].foodstockdisponible <= foods[0].foodstockalerte) || !checkDate(foods[0]); then checkOut ; else checkIn ">
                    </ng-container>

                    <ng-template #checkIn>
                        <div (click)="openModal(foods[0])" class="level2">
                            Ajouter au panier

                        </div>
                    </ng-template>
                    <ng-template #checkOut>
                        <div class="level2">
                            RDV à RestauTresor
                        </div>
                    </ng-template>



                </div>

            </ng-template>

            <ng-template carouselSlide>
                <div class="step1">
                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="step1">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="step1">
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>

    <div *ngIf="foodSize==2" class="container">
        <owl-carousel-o [options]="foodSliderConfig">
            <ng-template carouselSlide>
                <div class="step1">

                    <div class="level1">

                        <img [src]="foods[0].fooddownloadimageurl" alt="">


                        <p>{{foods[0].foodname}}
                            <br>
                            {{foods[0].foodprice}} FRS
                        </p>


                    </div>



                    <ng-container
                        *ngIf="checkHour(foods[0].heurecommande) || (foods[0].foodstockdisponible <= foods[0].foodstockalerte) || !checkDate(foods[0]); then checkOut ; else checkIn ">
                    </ng-container>

                    <ng-template #checkIn>
                        <div (click)="openModal(foods[0])" class="level2">
                            Ajouter au panier

                        </div>
                    </ng-template>
                    <ng-template #checkOut>
                        <div class="level2">
                            RDV à RestauTresor
                        </div>
                    </ng-template>



                </div>

            </ng-template>

            <ng-template carouselSlide>
                <div class="step1">

                    <div class="level1">

                        <img [src]="foods[1].fooddownloadimageurl" alt="">


                        <p>{{foods[1].foodname}}
                            <br>
                            {{foods[1].foodprice}} FRS
                        </p>


                    </div>



                    <ng-container
                        *ngIf="checkHour(foods[1].heurecommande) || (foods[1].foodstockdisponible <= foods[1].foodstockalerte) || !checkDate(foods[1]); then checkOut ; else checkIn ">
                    </ng-container>

                    <ng-template #checkIn>
                        <div (click)="openModal(foods[1])" class="level2">
                            Ajouter au panier

                        </div>
                    </ng-template>
                    <ng-template #checkOut>
                        <div class="level2">
                            RDV à RestauTresor
                        </div>
                    </ng-template>

                </div>

            </ng-template>

            <ng-template carouselSlide>
                <div class="step1">
                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="step1">
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>

    <div *ngIf="foodSize==3" class="container">
        <owl-carousel-o [options]="foodSliderConfig">
            <ng-template carouselSlide>
                <div class="step1">

                    <div class="level1">

                        <img [src]="foods[0].fooddownloadimageurl" alt="">


                        <p>{{foods[0].foodname}}
                            <br>
                            {{foods[0].foodprice}} FRS
                        </p>


                    </div>



                    <ng-container
                        *ngIf="checkHour(foods[0].heurecommande) || (foods[0].foodstockdisponible <= foods[0].foodstockalerte) || !checkDate(foods[0]); then checkOut ; else checkIn ">
                    </ng-container>

                    <ng-template #checkIn>
                        <div (click)="openModal(foods[0])" class="level2">
                            Ajouter au panier

                        </div>
                    </ng-template>
                    <ng-template #checkOut>
                        <div class="level2">
                            RDV à RestauTresor
                        </div>
                    </ng-template>



                </div>

            </ng-template>

            <ng-template carouselSlide>
                <div class="step1">

                    <div class="level1">

                        <img [src]="foods[1].fooddownloadimageurl" alt="">


                        <p>{{foods[1].foodname}}
                            <br>
                            {{foods[1].foodprice}} FRS
                        </p>


                    </div>



                    <ng-container
                        *ngIf="checkHour(foods[1].heurecommande) || (foods[1].foodstockdisponible <= foods[1].foodstockalerte) || !checkDate(foods[1]); then checkOut ; else checkIn ">
                    </ng-container>

                    <ng-template #checkIn>
                        <div (click)="openModal(foods[1])" class="level2">
                            Ajouter au panier

                        </div>
                    </ng-template>
                    <ng-template #checkOut>
                        <div class="level2">
                            RDV à RestauTresor
                        </div>
                    </ng-template>

                </div>

            </ng-template>

            <ng-template carouselSlide>
                <div class="step1">

                    <div class="level1">

                        <img [src]="foods[2].fooddownloadimageurl" alt="">


                        <p>{{foods[2].foodname}}
                            <br>
                            {{foods[2].foodprice}} FRS
                        </p>


                    </div>



                    <ng-container
                        *ngIf="checkHour(foods[2].heurecommande) || (foods[2].foodstockdisponible <= foods[2].foodstockalerte) || !checkDate(foods[2]); then checkOut ; else checkIn ">
                    </ng-container>

                    <ng-template #checkIn>
                        <div (click)="openModal(foods[2])" class="level2">
                            Ajouter au panier
                        </div>
                    </ng-template>
                    <ng-template #checkOut>
                        <div class="level2">
                            RDV à RestauTresor
                        </div>
                    </ng-template>

                </div>

            </ng-template>

            <ng-template carouselSlide>
                <div class="step1">
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>

    <div *ngIf="foodSize>=4" class="container">

        <owl-carousel-o [options]="foodSliderConfig">
            <ng-container *ngFor="let f of foods ">
                <ng-template carouselSlide>
                    <div class="step1">

                        <div class="level1">

                            <img [src]="f.fooddownloadimageurl" alt="">


                            <p>{{f.foodname}}
                                <br>
                                {{f.foodprice}} FRS
                            </p>


                        </div>



                        <ng-container
                            *ngIf="checkHour(f.heurecommande) || (f.foodstockdisponible <= f.foodstockalerte) || !checkDate(f); then checkOut ; else checkIn ">
                        </ng-container>

                        <ng-template #checkIn>
                            <div (click)="openModal(f)" class="level2">
                                Ajouter au panier

                            </div>
                        </ng-template>
                        <ng-template #checkOut>
                            <div class="level2">
                                RDV à RestauTresor
                            </div>
                        </ng-template>



                    </div>

                </ng-template>


            </ng-container>

        </owl-carousel-o>

    </div>
</section>



<div class="review-area review-area-two">

    <div class="row align-items-center" style="background-color: #FEF6D7;">
        <div class="col-lg-6">

            <div class="row align-items-center">
                <div class="col-lg-4" style="text-align: center;">
                    <img src="assets/img/cv-1.png" height="100px" width="120px" alt="Review">
                    <p style="color: black">
                        Port du masque
                        <br>
                        Obligatoire
                    </p>
                </div>
                <div class="col-lg-4" style="text-align: center;">
                    <img src="assets/img/cv-2.png" height="100px" width="120px" alt="Review">
                    <p style="color: black">
                        Lavage des mains à l’entrée
                        <br>
                        au gel hydro alcoolique obligatoire
                    </p>
                </div>
                <div class="col-lg-4" style="text-align: center;">
                    <img src="assets/img/cv-3.png" height="100px" width="120px" alt="Review">
                    <p style="color: black">
                        Respect de 1m
                        <br>
                        de distance minimum
                    </p>
                </div>
            </div>
            <br>
            <div class="row align-items-center">
                <div class="col-lg-4">
                </div>
                <div class="col-lg-4" style="text-align: center;">
                    <img src="assets/img/cv-4.png" height="100px" width="120px" alt="Review">
                    <p style="color: black">
                        Nous vous livrons au
                        <br>
                        bureau en toute sécurité
                    </p>
                </div>
                <div class="col-lg-4">
                </div>
            </div>



        </div>

        <div class="col-lg-6" style="background-color: black;">
            <div class="review-item">
                <div class="section-title" style="padding-left: 80px;">
                    <p style="text-align: left;">Professionnalisme et maîtrise du risque covid, de notre cuisine à vos
                        assiettes.</p>
                    <br>
                    <p style="text-align: left;"> Commandez en ligne, faites-vous livrer ou passez récupérer, dans le
                        strict respect des mesures
                        barrières Covid 19</p>
                </div>

                <div class="banner-btn-wrap">
                    <a class="cmn-btn" style="color: white; font-weight: bold;" routerLink="/">Voir la carte</a>
                </div>

            </div>
        </div>
    </div>
</div>

<section class="download-area-two pt-100 pb-70" style="background-color: white;">


    <div class="container">
        <div class="row">


            <div class="col-lg-3">

            </div>

            <div class="col-lg-6">
                <div class="download-item">
                    <h2 style="color: black;">TÉLÉCHARGER L’APP MOBILE</h2>
                    <ul style="padding-left: 150px;">
                        <li><a href="#"><img src="assets/img/google-store.png" alt="Google"></a></li>
                        &nbsp;&nbsp;
                        <li><a href="#"><img src="assets/img/play-store.png" alt="App"></a></li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-3">

            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>