<div class="contact-location-area" style="background-color: white">
  <div class="delete-account-container">
    <h5>DEMANDE DE SUPPRESSION DE COMPTE</h5>

    <hr style="color: black; font-weight: bold" />

    <p style="color: #000">
      Lorsque vous supprimez votre compte, tout votre contenu et toutes vos
      données seront également supprimées.
    </p>

    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <input type="email" class="form-control" formControlName="email" />
      </div>

      <div style="margin-top: 10px" class="form-group">
        <input
          type="password"
          class="form-control"
          placeholder="Mot de passe"
          formControlName="password"
        />

        <div
          *ngIf="erreursForm['password']"
          style="color: red; font-weight: bold"
        >
          {{ erreursForm["password"] }}
        </div>
      </div>

      <div style="margin-top: 10px" class="form-group">
        <textarea
          class="form-control"
          placeholder="Motif de suppression de compte"
          formControlName="motif"
        ></textarea>

        <div *ngIf="erreursForm['motif']" style="color: red; font-weight: bold">
          {{ erreursForm["motif"] }}
        </div>
      </div>

      <div style="margin-top: 10px">
        <button
          type="submit"
          class="btn cmn-btn-insc"
          style="color: white; width: 100%"
          [ngStyle]="{
            backgroundColor: userForm.invalid || isSubmitting ? 'gray' : ''
          }"
          [disabled]="userForm.invalid || isSubmitting"
        >
          Envoyer la demande {{ isSubmitting ? "..." : "" }}
        </button>
      </div>
    </form>
  </div>
</div>
