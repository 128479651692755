import { Component, Injector } from '@angular/core';
import { Carteabonnement, Client, ClientAuth } from '../models/models';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from '../parameter.service';
import { NotifDialogComponent } from '../notif-dialog/notif-dialog.component';

@Component({
  selector: 'app-card-relaod',
  templateUrl: './card-relaod.component.html',
  styleUrls: ['./card-relaod.component.scss']
})
export class CardRelaodComponent {

  itemSelected: any = 1;
  solde: number;
  currentUser: ClientAuth;
  client: Client;
  carteabonnement: Carteabonnement;
  public dataQrCode: string = 'Carte non trouvé';

  isLocked: boolean = false;
  statutblocage: boolean = false;

  demandCarte = false;
  renewCarte = false;
  maCarte: boolean = false;

  menu: boolean = false;
  modifPass: boolean = false;
  passOublie: boolean = false;
  recharger: boolean = false;
  gestionCarte: boolean = true;



  constructor(public formBuilder: FormBuilder, private parameterService: ParameterService,
    private modalService: NgbModal, private route: Router
  ) { }

  ngOnInit() {

    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      this.currentUser = JSON.parse(localStorage.getItem('YolinFrontCurentuser'));

    } else {
      this.currentUser = JSON.parse(sessionStorage.getItem('YolinFrontCurentuser'));

    }
    // Verifier un client est en ligne
    if (!this.currentUser) {
      this.route.navigate(['/']);  //Rediriger l'utilisateur à l'accueil
    }

    this.client = this.currentUser.client;
    this.menu = false;
    this.maCarte = false;
    this.verifCarte();
    this.soldeCarte(this.client.clientid);

  }







  selectedItems(value) {
    this.itemSelected = value;
  }



  verifCarte() {
    // Chargement des infos de la carte (Si existante)
    //    this.accountservice.findCarteByclientid(this.client.clientid).subscribe(data => {

    this.parameterService.getAllWithKey('carte/checkclientcard/' + this.client.clientid).subscribe(data => {

      switch (data.status) {
        case 200:


          this.carteabonnement = data.body;
          this.dataQrCode = data.body.carteabonnementcodereference;
          //this.soldeCarte(this.client.clientid); // Obtenir le solde du compte RestauTresor
          if (this.carteabonnement.carteabonnementstatut.toLowerCase() == 'bloquer') {
            this.isLocked = true;
          }

          if (this.carteabonnement) {

          }


          /*this.snackbar.open('Chargement terminé ', 'OK', {
            duration: 5000
          });*/


          // this.buildCodeSecretForm();           

          break;
        case 204:
          this.isLocked = true;


          this.maCarte = false;
          this.modifPass = false;
          this.passOublie = false;
          this.recharger = false;
          this.menu = false;
          this.openModal('Aucune carte trouvé');

          /* this.snackbar.open('Aucune carte trouvé ', 'OK', {
            duration: 5000
          }); */
          break;
        default:

          break;
      }
    },
      error => {
        this.isLocked = true;

        console.log(error);

      });
  }

  soldeCarte(value: string) {
    // Obtention du solde de la carte

    // this.loader.open('Recherche du solde en cours...');
    this.parameterService.getAllWithKey('parameter/client/solde/' + this.client.clientid).subscribe(data => {

      switch (data.status) {
        case 200:
          this.solde = data.body;

          /* this.snackbar.open('Solde OK ', 'OK', {
            duration: 5000
          }); */
          break;
        case 204:
          this.openModal('Impossible d\'obtenir le solde');

          /* this.snackbar.open('Aucune carte trouvé ', 'OK', {
            duration: 5000
          }); */
          break;
        default:

          break;
      }
    },
      error => {


      });
  }





  async openModal(product) {
    this.modalService.open(NotifDialogComponent,

      {
        size: 'lg',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: product
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  public closeResult: string;


}
