import { Component, Injector, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Client, ClientAuth, DemandeabnDto } from '../../models/models';
import { ParameterService } from '../../parameter.service';
import { NotifDialogComponent } from '../../notif-dialog/notif-dialog.component';

@Component({
  selector: 'app-coporate-abn',
  templateUrl: './coporate-abn.component.html',
  styleUrls: ['./coporate-abn.component.scss']
})
export class CoporateAbnComponent {

  client: Client;

  demandes: any[];
  currentUser: ClientAuth;

  itemsrange: number[] = [];
  indexPage: number = 1;

  public itemsize: number = 0;
  public itemsfront: any[] = [];
  public itemsizefront: number = 0;

  corporateForm: FormGroup;
  clienttypes: any[];

  page = 1;
  pageSize = 10;

  constructor(public formBuilder: FormBuilder, private parameterService: ParameterService,
    private modalService: NgbModal, private route: Router, private fb: FormBuilder
  ) {

    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      this.currentUser = JSON.parse(localStorage.getItem('YolinFrontCurentuser'));

    } else {
      this.currentUser = JSON.parse(sessionStorage.getItem('YolinFrontCurentuser'));

    }
    // Verifier un client est en ligne
    if (!this.currentUser) {
      this.route.navigate(['/']);  //Rediriger l'utilisateur à l'accueil
    }

    this.client = this.currentUser.client;

    this.getDemande();
    this.getCorpo();
  }

  ngOnInit() {
    this.corporateForm = this.fb.group({
      clienttype: ['', [Validators.required]],
      matricule: ['', [Validators.required]],
    });
  }

  getDemande() {

    // this.abonnementService.getDemandeDrh(7, this.client.clientid).subscribe(data => {
    this.parameterService.getAllWithKey('parameter/drh/findlistbyclientid/' + 7 + '/' + this.client.clientid).subscribe(data => {

      switch (data.status) {
        case 200:
          this.demandes = data.body;

          this.itemsize = this.demandes.length;
          if (this.itemsize > 10) {
            let remainder: number = this.itemsize % 10;
            let res: number = (this.itemsize - remainder) / 10;

            let i: number = 0;

            if (remainder > 0) {
              i = 1;
            }

            this.itemsizefront = i + res;
            this.createRange();
            this.defineObjectByItems(1);

          } else {
            this.itemsfront = this.demandes;
          }

          break;

        default:

          break;
      }
    },
      error => {


      });
  }

  getCorpo() {

    this.parameterService.getAllWithKey("parameter/clienttype").subscribe(data => {

      switch (data.status) {
        case 200:
          this.clienttypes = data.body;

          break;
        case 204:
          this.openModal('Aucune infos trouvé');

          break;
        default:

          break;
      }
    },
      error => {


      });
  }



  createRange() {
    this.itemsrange = [];
    for (var i = this.indexPage; i <= this.indexPage + 4; i++) {
      if (i <= this.itemsizefront) {
        this.itemsrange.push(i);
      }
    }
  }


  demandeCorporate() {
    let demandeabnDto: DemandeabnDto = new DemandeabnDto();
    demandeabnDto.demandeabnetat = 7;
    demandeabnDto.demandeabnmotif = this.corporateForm.controls['matricule'].value;
    demandeabnDto.demandeabncorporate = this.corporateForm.controls['clienttype'].value.clienttypeid;
    demandeabnDto.demandeabnidclient = this.client.clientid;
    this.parameterService.postWithKey("parameter/drh/add", demandeabnDto).subscribe(data => {

      switch (data.status) {
        case 200: {

          this.openModal('Demande DRH envoyé avec succès');
          this.corporateForm = this.fb.group({
            clienttype: ['', [Validators.required]],
            matricule: ['', [Validators.required]],
          });
          this.getDemande();
          break;
        }

        default: {
          break;
        }

      }
    },
      error => {

        switch (error.status) {

          case 423: {
            this.openModal('Client non trouvé');

            break;
          }
          default: {
            this.openModal('Un erreur est survenue');

            break;
          }


        }
      });

  }


  defineObjectByItems(value: number) {
    let fin: number = value * 10;
    let debut: number = (value * 10) - 9;

    this.itemsfront = [];
    let itemscsv: any[] = [];

    let i: number = 0;
    this.demandes.forEach(function (element) {
      i++;

      if ((i >= debut) && (i <= fin)) {
        itemscsv.push(element);
      }
    })

    this.itemsfront = itemscsv;



  }

  async openModal(product) {
    this.modalService.open(NotifDialogComponent,

      {
        size: 'lg',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: product
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  public closeResult: string;



}
