import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NavbarComponent } from "./components/navbar/navbar.component";
import { FooterComponent } from "./components/footer/footer.component";

@Component({
  selector: "app-main-layout",
  standalone: true,
  imports: [CommonModule, NavbarComponent, FooterComponent],
  templateUrl: "./main-layout.component.html",
})
export class MainLayoutComponent {}
