import { Component, Injector } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthResponse, Finalizeclient, Foods, PaymentEspece, PaymentMonetique, User, clientCommandFood, commandFood } from '../models/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ParameterService } from '../parameter.service';
import { environment } from 'src/environments/environment';
import { NotifDialogComponent } from '../notif-dialog/notif-dialog.component';

@Component({
  selector: 'app-succes-dialog',
  templateUrl: './succes-dialog.component.html',
  styleUrls: ['./succes-dialog.component.scss']
})
export class SuccesDialogComponent {

  public f: Finalizeclient = new Finalizeclient();

  public getItemSub: Subscription;

  public getPaySub: Subscription;

  public cartePaySub: Subscription;


  public responseReturn: boolean = false;

  public responseErrorGateway: boolean = false;

  public responseErrorCarte: boolean = false;

  validformGroup: FormGroup;

  responseClient: boolean = false;

  checkMomo: boolean = false;




  constructor(private finalize: Finalizeclient, public router: Router, public formBuilder: FormBuilder,
    private parameterService: ParameterService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.f = this.finalize;
    this.sendRequestToBilling();
  }

  closeNotif() {
    this.modalService.dismissAll();
  }

  sendRequestToBilling() {


    //let finalizes: Finalizeclient = new Finalizeclient();

    let clientfood: clientCommandFood = new clientCommandFood();
    let commanditemDtos: commandFood[] = [];


    let res: any = sessionStorage.getItem('allfood');
    let allfood: Foods[] = JSON.parse(res);

    let paymonetq: PaymentMonetique = new PaymentMonetique();
    let plats: Foods[] = [];


    for (let fn of allfood) {
      let cmd: commandFood = new commandFood();
      cmd.commandFoodId = fn.foodid;
      cmd.commandItemName = fn.foodname;
      cmd.commandItemPrice = fn.foodprice;
      cmd.commandItemQuantity = fn.foodqte;
      cmd.commandprixht = fn.foodprixht;
      cmd.commandprixtva = fn.foodprixtva;
      cmd.pointventeid = fn.pointvente.pointventeid;

      commanditemDtos.push(cmd);
      plats.push(fn);
    }
    paymonetq.plats = plats;

    let v = new User();
    let r: any;

    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      r = localStorage.getItem('YolinFrontCurentuser');

    } else {
      r = sessionStorage.getItem('YolinFrontCurentuser');

    }

    v = JSON.parse(r);

    clientfood.clientId = v.client.clientid;
    clientfood.commandTotalPrice = this.GetTotal();
    clientfood.commanditemDtos = commanditemDtos;
    clientfood.paymentmodename = this.f.clientpayment;
    clientfood.typeLivraisonname = this.f.clientdelivrery;
    clientfood.commandcommentaire = this.f.clientcommentaire;
    clientfood.commandlieulivraisonzone = this.f.clientlieulivraisonzone;
    clientfood.commandlieulivraisonquartier = this.f.clientlieulivraisonquartier;
    clientfood.commandheure = this.f.clientcommandheure;
    clientfood.codesecretCarte = this.f.codesecretCarte;
    clientfood.commandprovenance = 'Web-frontOffice';


    // Verifier si le moyen de paiement est carte abonnement
    if (clientfood.paymentmodename == 'carte abonnement') {

      this.paiementParCarte(clientfood);

    } else { // Paiement different de carte abonnement

      console.log("Paiement different de carte abonnement");

      this.getItemSub = this.parameterService.postWithKey('order', clientfood).subscribe(data => {

        switch (data.status) {
          default: {

            let resfoodclient: any = data.body;
            let cmdfoodclient: clientCommandFood = resfoodclient;

            let commandref: string = cmdfoodclient.commandreference;
            paymonetq.commandreference = commandref;
            paymonetq.clientreference = v.client.clientid;
            // paymonetq.totalachat = clientfood.commandTotalPrice;
            paymonetq.totalachat = data.body.commandtotalpricepayer;

            this.f.clientrefcmd = commandref;

            // Verifier si operation est Mobile Money
            if (clientfood.paymentmodename == 'mobile money') { // Execution de la gateway

              this.checkMomo = true;
              // Send Pay monetique

              this.getPaySub = this.parameterService.postWithKey('gatewaymonetique/token', paymonetq).subscribe(data => {

                switch (data.status) {

                  default: {


                    let cl: AuthResponse = new AuthResponse();

                    cl = data.body;

                    console.log(cl);

                    this.validformGroup = this.formBuilder.group({
                      'currency': ['xof'],
                      'operation_token': [environment.operationToken],
                      'order': [commandref],
                      'transaction_amount': [paymonetq.totalachat],
                      'jwt': [cl.auth_token],
                    });

                    this.post(this.validformGroup.value, environment.urlGateway);
                    this.responseClient = true;

                    /*
                    this.validformGroup = this.formBuilder.group({
                      'currency': ['xof'],
                      'operation_token': ['48f981ff-a77d-4039-a336-16ba2c822fd3'],
                      'order': [commandref],
                      'transaction_amount': [paymonetq.totalachat],
                      'jwt': [cl.auth_token],
                    });
    
    
                    
    
                    this.logger.trace("validformGroup");
                    this.logger.trace(this.validformGroup);
                    this.logger.trace(this.validformGroup.value);
    
    
                    this.http.post("http://crossroadtest.net:6968/order", this.validformGroup , {
                      observe: 'response',
                      headers: new HttpHeaders({ 'Access-Control-Allow-Origin': '*' })
                        .set("content-Type", "application/json")
                    }).subscribe(res => {
                      this.logger.trace("##################################################");
                      this.logger.trace(res);
                      //you can do asomething, like
                    })
    
                    */







                    /*
                    this.responseReturn=true ;
                    let alf: Foods[]=[];
                    sessionStorage.setItem('allfood',JSON.stringify(alf));
                    */

                  }
                }
              },
                error => {

                  switch (error.status) {
                    case 406: {
                      console.log("ERREUR 406");
                      this.responseErrorGateway = true;
                      break;
                    }
                    default: {
                      console.log("ERREUR DEFAULT");
                      this.responseErrorGateway = true;
                      this.responseReturn = false;
                      break;
                    }
                  }
                });

              // Fin Send Pay monetique

            } else if (clientfood.paymentmodename == 'Espèces') { // Execution du paiement mobile

              console.log("paiement espece");
              let requestPayEspece: PaymentEspece = new PaymentEspece();
              requestPayEspece.commandRef = commandref;
              requestPayEspece.commandprovenance = "Web-frontOffice";
              this.paiementParEspece(requestPayEspece);

            }


            {

              let allfood: Foods[] = [];
              sessionStorage.setItem('allfood', JSON.stringify(allfood));
              this.responseReturn = true;
              //this.dialogRef.close();
            }



          }
        }
      },
        error => {
          switch (error.status) {

            case 406: {
              //  this.openNotifModal('');
              this.openNotifModal('Une quantité demandé n\'est plus disponible');

              break;
            }
            default: {
              console.log(error);

              this.openNotifModal('Une erreur est survenue,reessayer SVP');
              break;
            }
          }
        });

    }
  }

  GetTotal() {
    let res: any = sessionStorage.getItem("allfood");
    let allfood: Foods[] = JSON.parse(res);
    let t: number = 0;
    for (let f of allfood) {
      t = t + (f.foodprice * f.foodqte);
    }
    if (this.f.clientdelivrery && this.f.clientdelivrery.toLowerCase() == 'a livrer') {
      t = t + this.f.delivery;
    }

    return t;
  }


  paiementParCarte(clientfood: clientCommandFood) {
    this.cartePaySub = this.parameterService.postWithKey('carte/pay', clientfood).subscribe(data => {
      let response = data.body;

      switch (data.status) {

        case 200: {


          this.openNotifModal('Mot de passe incorrect');
          break;
        }

        case 201: {
          // this.openNotification('Notification', 'Commande effectuée avec success');
          /* this.snackBar.open('Commande effectuée avec success', '×',
           { panelClass: 'success', verticalPosition: 'top', duration: 3000 }); */
          let allfood: Foods[] = [];
          sessionStorage.setItem('allfood', JSON.stringify(allfood));

          this.f.clientrefcmd = response.commandreference;

          this.responseReturn = true;
          this.modalService.dismissAll(true);

          break;
        }
        case 204: {


          this.openNotifModal('Le solde de votre carte est insuffisant. Veuillez recharger votre carte ou utiliser un autre moyen de paiement');


          break;
        }
        default: {


        }
      }
    },
      error => {

        // this.responseErrorCarte=true;


        switch (error.status) {
          case 401: {

            this.openNotifModal('Carte bloqué, Commande impossible');

            /* this.snackBar.open('Carte bloqué, Commande impossible', '×',
          { panelClass: 'success', verticalPosition: 'top', duration: 5000 }); */

            break;
          }
          case 406: {

            this.openNotifModal('Quantité non disponible');

            /* this.snackBar.open('Quantité non disponible', '×',
          { panelClass: 'success', verticalPosition: 'top', duration: 5000 }); */

            break;
          }
          default: {

            this.openNotifModal('Une erreur est survenue');

            /* this.snackBar.open('Une erreur est survenue', '×',
          { panelClass: 'success', verticalPosition: 'top', duration: 5000 }); */

            break;
          }
        }
      });
  }



  paiementParEspece(payment: PaymentEspece) {
    this.cartePaySub = this.parameterService.postWithKey('order/payespece', payment).subscribe(data => {
      let response = data.body;

      switch (data.status) {

        /*
        case 200: {
          this.logger.trace('Mot de passe carte incorrect');
          this.snackBar.open('Mot de passe incorrect', '×',
           { panelClass: 'success', verticalPosition: 'top', duration: 10000 });
           this.dialogRef.close(true);
          break;
        }
        */

        case 201: {
          // this.openNotification('Notification', 'Commande effectuée avec success');
          /* this.snackBar.open('Commande effectuée avec success', '×',
           { panelClass: 'success', verticalPosition: 'top', duration: 3000 }); */
          let allfood: Foods[] = [];
          sessionStorage.setItem('allfood', JSON.stringify(allfood));

          this.f.clientrefcmd = response.commandreference;

          this.responseReturn = true;
          this.modalService.dismissAll(true);

          break;
        }

        default: {


        }
      }
    },
      error => {

        // this.responseErrorCarte=true;


        switch (error.status) {
          case 401: {

            this.openNotifModal('Erreur survenue, Commande impossible');

            /* this.snackBar.open('Carte bloqué, Commande impossible', '×',
          { panelClass: 'success', verticalPosition: 'top', duration: 5000 }); */

            break;
          }
          case 406: {

            this.openNotifModal('Quantité non disponible');


            /* this.snackBar.open('Quantité non disponible', '×',
          { panelClass: 'success', verticalPosition: 'top', duration: 5000 }); */

            break;
          }
          default: {

            /* this.snackBar.open('Une erreur est survenue', '×',
          { panelClass: 'success', verticalPosition: 'top', duration: 5000 }); */


            this.openNotifModal('Une erreur est survenue');

            break;
          }
        }
      });
  }




  post(obj, url) {
    var mapForm = document.createElement("form");
    mapForm.target = "_self";
    mapForm.method = "POST"; // or "post" if appropriate
    mapForm.action = url;
    Object.keys(obj).forEach(function (param) {
      var mapInput = document.createElement("input");
      mapInput.type = "hidden";
      mapInput.name = param;
      mapInput.setAttribute("value", obj[param]);
      mapForm.appendChild(mapInput);
    });
    document.body.appendChild(mapForm);
    mapForm.submit();
  }




  async openNotifModal(message) {
    this.modalService.open(NotifDialogComponent,
      {
        size: 'xs',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: message
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

}
