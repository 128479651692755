import { Component, Injector } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from '../parameter.service';
import { ClientAuth, Client } from '../models/models';
import { NotifDialogComponent } from '../notif-dialog/notif-dialog.component';

@Component({
  selector: 'app-card-secret',
  templateUrl: './card-secret.component.html',
  styleUrls: ['./card-secret.component.scss']
})
export class CardSecretComponent {
  itemSelected: any = 0;
  solde: number;
  currentUser: ClientAuth;
  client: Client;
  soldeAvantMars: number;


  items: any;

  subscribeStep : any = -1 ;

  isLocked: boolean = false;

  menu: boolean = false;
  typeAbn: boolean = false;
  sousAbn: boolean = false;
  monAbn: boolean = false;
  recharger: boolean = false;
  menuAbn: boolean = false;
  typeCorpo: boolean = false;

  constructor(public formBuilder: FormBuilder, private parameterService: ParameterService,
    private modalService: NgbModal, private route: Router
  ) { }

  ngOnInit() {

    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      this.currentUser = JSON.parse(localStorage.getItem('YolinFrontCurentuser'));

    } else {
      this.currentUser = JSON.parse(sessionStorage.getItem('YolinFrontCurentuser'));

    }
    // Verifier un client est en ligne
    if (!this.currentUser) {
      this.route.navigate(['/']);  //Rediriger l'utilisateur à l'accueil
    }

    this.client = this.currentUser.client;
    this.soldeCarte(this.client.clientid);
    this.getallhistoriqueabnbyclient();
  }

  selectedItems(value) {
    this.itemSelected = value;
  }


  getallhistoriqueabnbyclient() {
    this.parameterService.getAllWithKey('abonnement/findbyrefclient/' + this.client.clientid).subscribe(data => {
      switch (data.status) {
        case 200: {
          this.items = data.body;
          
          this.soldeCarte(this.client.clientid, this.items);
          this.isLocked = false; //Activer certains boutons
          this.subscribeStep = 1 ;
          this.selectedItems(5);
          break;
        }
        case 204: {
          this.openModal('Désolé, aucun abonnement trouvé');
          this.typeAbn = true;
          this.sousAbn = false;
          this.monAbn = false;
          this.recharger = false;
          this.menu = false;
          this.isLocked = true; //Desactiver certains boutons
          this.subscribeStep = -1 ;
          this.selectedItems(1);

          break;
        }
        default: {
          break;
        }

      }
    },
      error => {

        this.typeAbn = true;
        this.sousAbn = false;
        this.monAbn = false;
        this.recharger = false;
        this.menu = false;
        this.isLocked = true; //Desactiver certains boutons
        this.subscribeStep = -1 ;

        switch (error.status) {

          default: {
            this.openModal('Une erreur est survenue');

          }
        }
      });
  }

  soldeCarte(value: string, items?) {
    // Obtention du solde de la carte

    // this.loader.open('Recherche du solde en cours...');
    this.parameterService.getAllWithKey('abonnement/solde/' + this.client.clientid).subscribe(data => {

      switch (data.status) {
        case 200:
          this.solde = data.body;

          if (items) {
            if (this.solde <= 10000 && this.client.clienttype.clienttypename == 'default') {
              this.openModal('Le solde de votre abonnement est de ' + this.solde + ' fcfa. Pour continuer à bénéficier de vos avantages, vous devez recharger votre compte en espèces au restaurant ou via mobile money d\'un montant minimum de 35.000 fcfa.');

            } else if (this.solde <= 10000 && this.client.clienttype.clienttypename != 'default') {
              this.openModal('Le solde de votre abonnement est de ' + this.solde + ' fcfa valable jusqu\'au ' + this.parameterService.convertDateToString(items[0].abonnementdatefin) + '. Pour continuer à bénéficier de vos avantages , vous pouvez recharger votre compte en espèces au restaurant , via mobile money ou en souscrivant à un decouvert .');

            } else {

            }

            this.getSoldeAvantMars(this.solde, this.parameterService.convertDateToString(items[0].abonnementdatefin));

          }


          break;
        case 204:
          // this.openNotification('Notification', 'Aucun abonnement trouvé');

          break;
        default:

          break;
      }



    },
      error => {


      });
  }



  getSoldeAvantMars(solde: number, datefin) {  // Obtention du solde de la carte

    // this.loader.open('Recherche du solde en cours...');
    this.parameterService.getAllWithKey('historiquecarte/findsoldebybate/' + this.client.clientid).subscribe(data => {

      switch (data.status) {
        case 200:
          this.soldeAvantMars = data.body;

          // Rappel 31 Mars
          if (solde > 0 && this.soldeAvantMars > 0 && this.client.clienttype.clienttypename == 'default') {
            this.openModal('Cher(e) abonné(e) ' +
              'votre solde abonnement est crediteur  d\'un montant de ' + this.soldeAvantMars + ' fcfa . Au 31 mars 2021, tout credit non consommé sera perdu .</br>' +
              'Profitez dès à présent des avantages liés à votre abonnement  en vous connectant sur www.restautresor.ci , sur l\'application ou au restaurant !</br>' +
              'RestauTresor, c\'est l\'heure de bien manger !');


          } else if (solde > 0 && this.soldeAvantMars > 0 && this.client.clienttype.clienttypename != 'default') {
            this.openModal('Cher(e) abonné(e)' +
              'Votre solde abonnement  est crediteur  d\'un montant de  ' + this.soldeAvantMars + ' fcfa , valide  jusqu\'au ' + datefin + ' . Au 31 mars 2021, tout credit non consommé sera perdu .</br>' +
              'Profitez dès à présent des avantages liés à votre abonnement  en vous connectant sur www.restautresor.ci , sur l\'application ou au restaurant ! </br>' +
              'RestauTresor, c\'est l\'heure de bien manger !');
          }

          break;

        default:

          break;
      }

    },
      error => {


      });
  }

  async openModal(product) {
    this.modalService.open(NotifDialogComponent,

      {
        size: 'lg',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: product
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  public closeResult: string;


}
