<div class="form-container">
  <div class="illustration">
    <img
      src="https://img.freepik.com/free-vector/forgot-password-concept-illustration_114360-4652.jpg?w=2000"
      alt="forgot password illustration"
    />
  </div>

  <form>
    <h3>Mot de passe oublié</h3>

    <div class="form-group">
      <input
        class="form-control"
        placeholder="Email/Téléphone"
        [formControl]="emailOrPassword"
      />
    </div>

    <button
      type="button"
      class="btn cmn-btn-insc"
      (click)="handleSubmit()"
      [disabled]="emailOrPassword.invalid || isSubmitting"
    >
      {{ isSubmitting ? "Veuillez patienter..." : "Valider" }}
    </button>

    <div *ngIf="apiError" class="alert alert-danger" style="margin-top: 50px">
      {{ apiError }}
    </div>

    <a class="close-btn" (click)="closeModale()"> FERMER </a>
  </form>
</div>
