import { Component, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from '../../parameter.service';
import { AbonnementDto, AuthResponse, Client, ClientAuth, CompteRestauLoginDto, DemandeabnDto, Foods, Formule, PaycarteDto, PaymentMonetique } from '../../models/models';
import { NotifDialogComponent } from '../../notif-dialog/notif-dialog.component';
import { CustomValidators } from 'ng2-validation';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sub-abn',
  templateUrl: './sub-abn.component.html',
  styleUrls: ['./sub-abn.component.scss']
})
export class SubAbnComponent {

  stepLevel: any = 0;
  client: Client;
  currentUser: ClientAuth;
  categoriecorpos: any[];
  categorieSelected: any;

  displayFormule: boolean = false;

  formules: Formule[];
  formulesprecompte: Formule[] = [];
  formulesnonprecompte: Formule[] = [];
  clientPrecompte: Formule[] = [];
  typeAbnForm: FormGroup;

  typeprecompte: string;
  offreForm: FormGroup;
  abnForm: FormGroup;

  displayPass: boolean = false;

  minFormulePrecompte = 75000;

  modepaiementForm: FormGroup;
  passcarteForm: FormGroup;
  cguForm: FormGroup;
  tokenFormGroup: FormGroup;


  constructor(public formBuilder: FormBuilder, private parameterService: ParameterService,
    private modalService: NgbModal, private route: Router, private fb: FormBuilder
  ) {
    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      this.currentUser = JSON.parse(localStorage.getItem('YolinFrontCurentuser'));
    } else {
      this.currentUser = JSON.parse(sessionStorage.getItem('YolinFrontCurentuser'));
    }
    // Verifier un client est en ligne
    if (!this.currentUser) {
      this.route.navigate(['/']);  //Rediriger l'utilisateur à l'accueil
    }
    this.client = this.currentUser.client;
  }


  ngOnInit() {
    this.getCategorieCorpo();
    this.buildForm();
    this.modepaiementForm.get('modepaiement').valueChanges.subscribe(data => {
      let event: any = this.modepaiementForm.get('modepaiement').value;
      console.log(event);
      if (event == 'carteabonnement') {
        this.displayPass = true;
      } else {
        this.displayPass = false;
      }
    })
  }




  buildForm() {
    this.typeAbnForm = this.formBuilder.group({
      typeAbnForm: ['precompte', Validators.required]
    });

    this.offreForm = this.formBuilder.group({
      offreForm: ['', Validators.required]
    });

    this.abnForm = this.fb.group({
      dateactivation: ['', [Validators.required, CustomValidators.date]],
      autremontant: [0, [CustomValidators.number, Validators.min(35000)]],
    });

    this.modepaiementForm = this.fb.group({
      modepaiement: ['mobilemoney', Validators.required],
      //codesecretcarte: ['', [CustomValidators.number, Validators.required, CustomValidators.rangeLength([4, 4])]]
    });

    this.passcarteForm = this.fb.group({
      passcarte: ['', [CustomValidators.number, Validators.required, CustomValidators.rangeLength([4, 4])]]
    });

    this.cguForm = this.fb.group({
      cgu: ['', [Validators.required]],
    });




  }

  goToBack() {
    this.stepLevel = this.stepLevel - 1;
  }


  getCategorieCorpo() {

    this.parameterService.getAllWithKey("categoriecorporate/findcategoriecorpo/" + this.client.clienttype.clienttypeid).subscribe(data => {

      switch (data.status) {
        case 200:
          this.categoriecorpos = data.body;
          break;
        case 204:
          this.openModal('Aucune categorie corpo disponible');

          break;
        default:

          break;
      }
    },
      error => {


      });
  }


  radioTypeAbnChange(value) {
    switch (value) {
      case 'precompte':
        this.clientPrecompte = this.formulesprecompte;
        //this.decouvert = true;
        break;
      case 'nonprecompte':
        this.clientPrecompte = this.formulesnonprecompte;
        //this.decouvert = false;
        break;
      default:
        break;
    }
  }


  checkPrecompteAndNext() {
    this.typeprecompte = this.typeAbnForm.get('typeAbnForm').value;
    if (this.typeprecompte == 'precompte') {
      this.clientPrecompte = this.formulesprecompte;
    } else {
      this.clientPrecompte = this.formulesnonprecompte;
    }
    this.stepLevel = this.stepLevel + 1;
  }


  getFormuleByCategoriecorpo(categoriecorpoid: string) {
    this.parameterService.getAllWithKey("formule/getlistformule/" + categoriecorpoid).subscribe(data => {

      switch (data.status) {
        case 200:
          console.log('DATA', data);
          this.formules = data.body;
          console.log(this.formules);
          this.formulesprecompte = this.formules.filter(function (person) {
            return person.formuleisprecompte;
          });
          this.formulesnonprecompte = this.formules.filter(function (person) {
            return !person.formuleisprecompte;
          });

          console.log(this.formulesprecompte);

          console.log(this.formulesnonprecompte);

          // Initialiser l'objet clientPrecompte
          this.clientPrecompte = this.formulesprecompte;

          break;
        case 204:
          this.openModal('Aucune categorie corpo disponible');

          break;
        default:

          break;
      }
    },
      error => {
      });
  }


  checkParametrageAndNext() {

    //if(this.typeAbnForm.get('typeAbnForm').value == 'precompte'){ //Si precompte

    if (this.abnForm.get('dateactivation').value == null || this.abnForm.get('dateactivation').value == ''
      || this.abnForm.get('dateactivation').value == undefined) {
      this.openModal('Choisir une date d\'activation SVP')
    } else {

      if (this.typeAbnForm.get('typeAbnForm').value == 'precompte') {
        // Verifier si le montant (autre montant est valide)
        if (Number(this.abnForm.get('autremontant').value) != 0 && this.abnForm.get('autremontant').value < this.minFormulePrecompte) {
          this.openModal('Choisir un montant superieur ou egal à 75.000f')

        } else {
          this.submit();
        }
      } else {
        this.stepLevel = this.stepLevel + 1;
      }
    }

    /*}else{

      event.next();

    }*/




  }


  submit() {


    let abonnementDto: AbonnementDto = new AbonnementDto();
    abonnementDto.abonnementdateactivation = this.parameterService.convertDateToString(this.abnForm.get('dateactivation').value);
    let typeprecompte = this.typeAbnForm.get('typeAbnForm').value;
    if (typeprecompte == 'precompte') {
      abonnementDto.abonnementprecompte = true;
      //abonnementDto.abonnementdecouvert = this.decouvertForm.get('decouvert').value;

    } else {
      //abonnementDto.abonnementdecouvert = false;
      abonnementDto.abonnementprecompte = false;

    }
    abonnementDto.abonnementdecouvert = false;
    abonnementDto.abonnementformuleid = this.offreForm.get('offreForm').value.formuleid;
    abonnementDto.abonnementmodecreation = 'frontoffice';
    abonnementDto.abonnementmodepaiement = this.modepaiementForm.get('modepaiement').value;
    abonnementDto.abonnementmontantsouhaite = this.abnForm.get('autremontant').value;
    abonnementDto.abonnementprovenance = 'frontoffice';
    abonnementDto.codesecretcarte = this.passcarteForm.get('passcarte').value;
    abonnementDto.clientid = this.client.clientid; // numero de carte ou ID client


    if (abonnementDto.abonnementprecompte) {

      let demandeabnDto: DemandeabnDto = new DemandeabnDto();
      demandeabnDto.demandeabndcvrt = abonnementDto.abonnementdecouvert;
      demandeabnDto.demandeabnetat = 1;
      demandeabnDto.demandeabnformule = this.offreForm.get('offreForm').value.formulenom;
      demandeabnDto.demandeabnidclient = this.client.clientid;
      demandeabnDto.demandeabnmontant = this.abnForm.get('autremontant').value == '' ? '0' : this.abnForm.get('autremontant').value;
      demandeabnDto.demandeabnprecpte = true;
      demandeabnDto.demandeabndateactivation = abonnementDto.abonnementdateactivation;

      this.demandeDRHprecompte(demandeabnDto);
      /*
      this.poupupconfirmationService.confirm({ title: 'Confirmation', message: 'Vous etes sur le point de faire une demande d\'abonnement precompte pour la formule ' + this.offreForm.get('offreForm').value.formulenom + ' , Vous etes sûr?' })
        .subscribe(res => {
          if (res) {
            
            this.demandeDRHprecompte(demandeabnDto);

          }
        });
        */


    } else {

      this.abonnementnonprecompte(abonnementDto);

      /*
      this.poupupconfirmationService.confirm({ title: 'Confirmation', message: 'Vous etes sur le point de souscrire à la formule ' + this.offreForm.get('offreForm').value.formulenom + ' , Vous etes sûr?' })
        .subscribe(res => {
          if (res) {

            this.abonnementnonprecompte(abonnementDto);

          }
        });
        */

    }


  }


  abonnementnonprecompte(abonnementDto: AbonnementDto) {
    this.parameterService.postWithKey("abonnement/nonprecompte", abonnementDto)
      .subscribe(data => {


        switch (data.status) {

          case 200: {
            this.openModal('Date invalide');
            break;
          }
          case 201: {

            if (abonnementDto.abonnementmodepaiement == 'mobilemoney') {
              let referencetransaction = data.body.abonnementtransid;
              // Redirection sur la gateway
              this.redirectGateway(referencetransaction, data.body.histomontant);

            } else {
              this.openModal('Abonnement enregistré');
              // this.isLocked = false; //Activer certains boutons
              this.route.navigate(['/']);
            }

            break;
          }

          case 204: {
            this.openModal('Client inexistant ou precompté');
            break;
          }

          default: {

            break;
          }


        }
      },
        error => {

          switch (error.status) {

            case 400: {
              this.openModal('Credit insuffisant');

              break;
            }
            case 401: {
              this.openModal('Paiement echoué');

              break;
            }
            case 406: {
              this.openModal('Formule introuvable');

              break;
            }
            case 409: {
              this.openModal('Erreur de mode de paiement');

              break;
            }
            case 423: {
              this.openModal('Erreur client precompté');

              break;
            }
            default: {
              this.openModal('Un erreur est survenue');

              break;
            }
          }
        });
  }

  /**
   * Demande DRH precompte
   */
  demandeDRHprecompte(demandeabnDto: DemandeabnDto) {
    this.parameterService.postWithKey("parameter/drh/add", demandeabnDto)
      .subscribe(data => {

        switch (data.status) {

          case 200: {
            this.openModal('Demande DRH envoyé avec succès');
            this.route.navigate(['/']);
            break;
          }

          default: {

            break;
          }


        }
      },
        error => {

          switch (error.status) {

            case 423: {
              this.openModal('Client non trouvé');

              break;
            }
            default: {
              this.openModal('Un erreur est survenue');

              break;
            }
          }
        });
  }



  selectedCategorie(value) {
    this.displayFormule = true;
    this.categorieSelected = value;
    this.getFormuleByCategoriecorpo(value.categoriecorpoid);
  }


  checkCategoriecorpoAndNext() {
    if (this.categorieSelected) {
      this.stepLevel = this.stepLevel + 1;
    } else {
      this.openModal('Choisir une catégorie SVP');
    }
  }

  checkFormuleAndNext() {
    if (this.offreForm.get('offreForm').value == null || this.offreForm.get('offreForm').value == ''
      || this.offreForm.get('offreForm').value == undefined) {
      this.openModal('Choisir une formule SVP');

    } else {
      let typeprecompte = this.offreForm.get('offreForm').value;
      this.stepLevel = this.stepLevel + 1;
    }
  }

  checkMoyenpaiementAndNext() {

    if (!this.cguForm.get('cgu').value) {
      this.openModal('Cocher les CGU SVP');
    } else {

      // Verifier si le paiement est par carte abonnement
      if (this.modepaiementForm.get('modepaiement').value !== 'carteabonnement') {
        this.submit();
        console.log("ORANGE MONEY");

        //event.next();
      } else {
        if (this.passcarteForm.get('passcarte').value == null || this.passcarteForm.get('passcarte').value == ''
          || this.passcarteForm.get('passcarte').value == undefined) {
          this.openModal('Saisir le code secret');
        } else {
          let compteRestauLoginDto: CompteRestauLoginDto = new CompteRestauLoginDto();
          compteRestauLoginDto.clientid = this.client.clientid; //ID client
          compteRestauLoginDto.carteabonnementcodesecret = this.passcarteForm.get('passcarte').value;
          this.loginCompteRestauAndFinalise(compteRestauLoginDto);
        }
      }
    }

  }


  loginCompteRestauAndFinalise(compteRestauLoginDto: CompteRestauLoginDto) {

    this.parameterService.postWithKey('carte/logincompterestau', compteRestauLoginDto).subscribe(data => {
      switch (data.status) {

        case 200: {
          this.submit();

          //event.next();

          break;
        }
        case 204: {
          this.openModal('Carte n\'existe pas ou la carte est bloqué');

          break;
        }
        default: {

          break;
        }
      }

    }, error => {
      switch (error.status) {

        case 401: {
          this.openModal('Mot de passe carte incorrect');

          break;
        }
        case 500: {
          this.openModal('Internal server error');

          break;
        }
        default: {
          this.openModal('Une erreur est survenue');

          break;
        }
      }
    });

  }



  redirectGateway(refTrans: string, solde: number) {
    // Creation de l'objet de paiement
    let paymonetq: PaymentMonetique = new PaymentMonetique();
    paymonetq.commandreference = refTrans;
    paymonetq.clientreference = this.client.clientid;
    paymonetq.totalachat = solde;
    let plats: Foods[] = [];
    paymonetq.plats = plats;
    // Get token 
    this.parameterService.postWithKey('gatewaymonetique/token', paymonetq).subscribe(data => {

      switch (data.status) {

        default: {


          let cl: AuthResponse = new AuthResponse();

          cl = data.body;


          this.tokenFormGroup = this.formBuilder.group({
            'currency': ['xof'],
            'operation_token': [environment.operationToken],
            'order': [paymonetq.commandreference],
            'transaction_amount': [paymonetq.totalachat],
            'jwt': [cl.auth_token],
          });


          this.post(this.tokenFormGroup.value, environment.urlGateway);

          break;

        }
      }
    },
      error => {




        switch (error.status) {
          case 406: {

            this.openModal('Erreur de token gateway');

            /* this.snackbar.open('Erreur de token gateway', '×',
            { panelClass: 'success', verticalPosition: 'top', duration: 5000 }); */
            break;
          }
          default: {

            this.openModal('Une erreur est survenue, reessayer SVP ou contacter l\'administrateur');

            /* this.snackbar.open('Erreur de token gateway', '×',
            { panelClass: 'success', verticalPosition: 'top', duration: 5000 }); */
            break;
          }
        }
      });
  }

  post(obj, url) {
    var mapForm = document.createElement("form");
    mapForm.target = "_self";
    mapForm.method = "POST"; // or "post" if appropriate
    mapForm.action = url;
    Object.keys(obj).forEach(function (param) {
      var mapInput = document.createElement("input");
      mapInput.type = "hidden";
      mapInput.name = param;
      mapInput.setAttribute("value", obj[param]);
      mapForm.appendChild(mapInput);
    });
    document.body.appendChild(mapForm);
    mapForm.submit();
  }

  generationpdf(type: string) {
    this.parameterService.downloadpdf(type)
      .subscribe(data => {
        switch (data.status) {

          case 200: {
            this.downloadFile(data.body);

            break;
          }
          case 204: {
            this.openModal('Impossible d\'obtenir le CGU, cela n\'empêche pas de continuer le processus');

            break;
          }

          default: {


            break;
          }


        }
      },
        error => {

          switch (error.status) {

            case 404: {
              this.openModal('Impossible d\'obtenir le CGU, cela n\'empêche pas de continuer le processus d\'abonnement');

              break;
            }
            default: {
              this.openModal('Impossible d\'obtenir le CGU, cela n\'empêche pas de continuer le processus d\'abonnement');

              break;
            }
          }
        });
  }

  downloadFile(data) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }


  async openModal(product) {
    this.modalService.open(NotifDialogComponent,

      {
        size: 'lg',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: product
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  public closeResult: string;


}
