import { Component, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { clientCommandFood, Client, ClientAuth, Historiq, Parameter } from '../models/models';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterService } from '../parameter.service';
import { NotifDialogComponent } from '../notif-dialog/notif-dialog.component';
import { FoodDialogComponent } from '../food-dialog/food-dialog.component';
import { FoodShowComponent } from '../food-show/food-show.component';

@Component({
  selector: 'app-commandes',
  templateUrl: './commandes.component.html',
  styleUrls: ['./commandes.component.scss']
})
export class CommandesComponent {

  searchTerm: string;


  page = 1;
  pageSize = 10;
  collectionSize: number;
  currentRate = 8;

  filterForm: FormGroup;
  filterDateForm: FormGroup;
  public commands: Array<clientCommandFood> = [];
  public getItemSub: Subscription;
  public items: Historiq[] = [];
  public itemsize: number = 0;
  temp = [];
  public itemsfront: Historiq[] = [];
  public itemsizefront: number = 0;
  itemsrange: number[] = [];
  indexPage: number = 1;

  todayDate;

  client: Client;

  currentUser: ClientAuth;

  parameter: Parameter;

  public closeResult: string;

  scrollBarHorizontal = (window.innerWidth < 1200);


  constructor(
    private route: Router, private parameterService: ParameterService, private modalService: NgbModal) { }



  ngOnInit() {

    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      this.currentUser = JSON.parse(localStorage.getItem('YolinFrontCurentuser'));

    } else {
      this.currentUser = JSON.parse(sessionStorage.getItem('YolinFrontCurentuser'));

    }
    // Verifier un client est en ligne
    if (!this.currentUser) {
      this.route.navigate(['/']);  //Rediriger l'utilisateur à l'accueil
    }

    this.client = this.currentUser.client;

    this.getDelaiAnnulation();

  }



  getDelaiAnnulation() {

    this.parameterService.getAllWithKey('parameter/utils/getdelaiannulation')
      .subscribe(data => {

        this.parameter = data.body;

        this.getAllCommand();

      },
        error => {


          switch (error.status) {
            default:
              console.log(error);

              this.openNotifModal('Echec !! Une erreur est survenue');
              break;
          }

        }
      );


  }



  getAllCommand() {
    let v = new ClientAuth();
    //Check RememberMe
    if (localStorage.getItem('YolinRememberMe')
      && JSON.parse(localStorage.getItem('YolinRememberMe'))) {
      v = JSON.parse(localStorage.getItem("YolinFrontCurentuser"));

    } else {
      v = JSON.parse(sessionStorage.getItem("YolinFrontCurentuser"));

    }
    if (v == null) {
      this.route.navigate(['/']);
    }

    let u: Client = new Client();
    u = v.client;
    this.commands = [];


    const payStatus = 'false';
    // this.getItemSub = this.historiqservice.getHistoriqUserById(u.clientid)

    // this.parameterService.getAllWithKey('/parameter/historiqueVente/findbyclientid/'+id)
    this.getItemSub = this.parameterService.getAllWithKey('parameter/historiqueVente/findbyclientid/' + u.clientid)
      .subscribe(data => {


        let responseany: any = data.body;


        if (responseany != null) {
          this.items = this.temp = responseany;



          this.itemsize = this.items.length;
          if (this.itemsize > 10) {
            let remainder: number = this.itemsize % 10;
            let res: number = (this.itemsize - remainder) / 10;

            let i: number = 0;

            if (remainder > 0) {
              i = 1;
            }

            this.itemsizefront = i + res;
            this.createRange();
            this.defineObjectByItems(1);

          } else {
            this.itemsfront = this.items;
          }
        }

        console.log(this.items);

      },
        error => {

          switch (error.status) {
            default: {
              break;
            }
          }
        })
  }

  createRange() {
    this.itemsrange = [];
    for (var i = this.indexPage; i <= this.indexPage + 4; i++) {
      if (i <= this.itemsizefront) {
        this.itemsrange.push(i);
      }
    }
  }


  defineObjectByItems(value: number) {
    let fin: number = value * 10;
    let debut: number = (value * 10) - 9;

    this.itemsfront = [];
    let itemscsv: Historiq[] = [];

    let i: number = 0;
    this.items.forEach(function (element) {
      i++;

      /*this.logger.trace(" debut : " + debut + " ; fin : " + fin);

      this.logger.trace(" verif : " + ((i >= debut) && (i <= fin)));

      this.logger.trace("verif 1 " + (i >= debut));
      this.logger.trace("verif 2 " + (i <= fin));*/

      if ((i >= debut) && (i <= fin)) {
        itemscsv.push(element);
      }
    })

    this.itemsfront = itemscsv;



  }


  async openOrderModal(message) {
    this.modalService.open(FoodShowComponent,
      {
        size: 'lg',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: Historiq, useValue: message
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  async openNotifModal(message) {
    this.modalService.open(NotifDialogComponent,
      {
        size: 'xs',
        ariaLabelledBy: 'Cart-Modal',
        centered: true,
        windowClass: 'theme-modal cart-modal CartModal',
        injector: Injector.create([{
          provide: String, useValue: message
        }])
      }).result.then((result) => {

        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
